<template>
  <div>
    <be-tabs
      v-model="activeTab"
      :options="tabsData"
      translation-scope="views.users.organizations.tabs"
    >
      <template #content-right>
        <div
          class="d-flex align-items-center flex-column-reverse flex-lg-row gap-2"
        >
          <save-status
            class="small mr-4"
            :loading="savingPreferences || savingMembershipsIds.length > 0"
            :failed-to-save="failedToSave"
            :last-saved-at="lastUpdateAt"
          />

          <be-button
            v-if="platformEnabled('create_new_company')"
            href="/companies/new"
            variant="outline-primary"
          >
            {{ $t("views.users.organizations.new_organization") }}
          </be-button>

          <be-button
            v-be-modal.my-organizations-info
            variant="outline-secondary"
            icon="fa-info-circle"
          />
        </div>
      </template>
    </be-tabs>

    <div class="card">
      <div class="card-body">
        <div
          v-for="tabType in ['company_memberships', 'admin_panel_memberships']"
          :key="tabType"
        >
          <div v-if="activeTab === tabType">
            <template v-if="hasLoadedOrganizations">
              <be-table
                v-if="memberships[tabType].length > 0"
                :items="memberships[tabType]"
                :fields="fields[tabType]"
              >
                <template #filters>
                  <div
                    class="d-flex align-items-end justify-content-end flex-column flex-md-row h-100 mt-2 mt-md-0"
                  >
                    <be-button
                      variant="outline-secondary"
                      @click="batchSave(tabType, false)"
                    >
                      {{
                        $t("views.users.organizations.mark_all_as_invisible")
                      }}
                    </be-button>

                    <be-button
                      variant="outline-secondary"
                      @click="batchSave(tabType, true)"
                    >
                      {{ $t("views.users.organizations.mark_all_as_visible") }}
                    </be-button>
                  </div>
                </template>

                <template #company_title="{ item }">
                  <div class="d-flex align-items-center">
                    <user-avatar
                      :avatar="{
                        name: item.company_title,
                        image_url: item.company_logo,
                      }"
                      class="mr-2"
                    />

                    <div>
                      <be-link
                        :href="url('/', { nanoid: item.company_nanoid })"
                      >
                        {{ item.company_title }}
                      </be-link>

                      <span
                        v-if="item.admin_panel_title"
                        class="d-block small text-muted"
                      >
                        {{ item.admin_panel_title }}
                      </span>
                    </div>
                  </div>
                </template>

                <template #role="{ item }">
                  <template v-if="item.company_type !== 'AdminCompany'">
                    {{
                      item.role
                        ? $t(`models.membership.roles.${item.role}`)
                        : ""
                    }}
                  </template>
                </template>

                <template #function="{ item }">
                  <template v-if="item.company_type !== 'AdminCompany'">
                    {{ $t(`models.membership.functions.${item.function}`) }}
                  </template>
                </template>

                <template #active_subscription="{ item }">
                  {{
                    item.active_subscription
                      ? $t("views.users.organizations.yes")
                      : $t("views.users.organizations.no")
                  }}
                </template>

                <template #favorite="{ item }">
                  <div
                    v-if="savingMembershipsIds.includes(item.id)"
                    class="d-flex align-items-center justify-content-center py-2"
                  >
                    <be-spinner />
                  </div>

                  <be-form-checkbox
                    v-else
                    v-model="item.favorite"
                    size="lg"
                    @change="save(item, tabType)"
                  />
                </template>

                <template #visible="{ item }">
                  <div
                    v-if="savingMembershipsIds.includes(item.id)"
                    class="d-flex align-items-center justify-content-center py-2"
                  >
                    <be-spinner />
                  </div>

                  <be-form-checkbox
                    v-else
                    v-model="item.visible"
                    v-be-tooltip="{
                      title: $t(
                        'views.users.organizations.visible_disabled_tooltip'
                      ),
                      disabled: !item.favorite,
                    }"
                    size="lg"
                    :disabled="item.favorite"
                    @change="save(item, tabType)"
                  />
                </template>
              </be-table>

              <be-alert v-else variant="warning">
                {{ $t(`views.users.organizations.no_${tabType}`) }}
              </be-alert>
            </template>

            <template v-else>
              <be-alert variant="info">
                {{
                  $t(
                    "views.users.organizations.load_organizations_timeout_notice"
                  )
                }}
              </be-alert>

              <be-skeleton-table
                :rows="5"
                :columns="6"
                :table-props="{ hover: true, striped: true }"
              />
            </template>
          </div>
        </div>
      </div>

      <div
        class="card-footer d-flex align-items-center justify-content-between"
      >
        <be-form-checkbox
          v-if="!savingPreferences"
          v-model="localHideInactiveCompanies"
          @change="savePreferences"
        >
          <span class="font-weight-normal">
            {{ $t("views.users.organizations.hide_inactive_companies") }}
          </span>
        </be-form-checkbox>

        <div v-if="savingPreferences">
          <be-spinner />

          <span class="ml-1">
            {{ $t("views.users.organizations.hide_inactive_companies") }}
          </span>
        </div>
      </div>
    </div>

    <be-modal
      id="my-organizations-info"
      size="lg"
      :title="$t('views.users.organizations.info_modal.title')"
      :ok-only="true"
      ok-variant="light"
      :ok-title="$t('buttons.titles.close')"
    >
      <div class="row no-gutters">
        <div class="col-12 col-lg-8 p-0 px-lg-3 py-lg-4">
          <h5
            v-dompurify-html="
              $t('views.users.organizations.info_modal.description_html')
            "
            class="mb-0 pt-1 font-weight-normal"
          />

          <ul class="mx-0 my-4 pl-3">
            <li>
              <h5 class="m-0">
                {{
                  $t(
                    "views.users.organizations.info_modal.description.bullet_1"
                  )
                }}
              </h5>
            </li>

            <li class="mt-3">
              <h5 class="m-0">
                {{
                  $t(
                    "views.users.organizations.info_modal.description.bullet_2"
                  )
                }}
              </h5>
            </li>

            <li class="mt-3">
              <h5 class="m-0">
                {{
                  $t(
                    "views.users.organizations.info_modal.description.bullet_3"
                  )
                }}
              </h5>
            </li>
          </ul>
        </div>

        <div class="col-lg-4">
          <be-img
            :src="imageSrc('info/my_organizations_menu.png')"
            class="d-none d-lg-block"
          />
        </div>
      </div>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SaveStatus from "@/components/shared/SaveStatus.vue";
import TextUtilities from "@/mixins/textUtilities";
import { EventBus } from "@/event-bus";

export default {
  components: {
    SaveStatus,
  },

  mixins: [TextUtilities],

  props: {
    hideInactiveCompanies: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "company_memberships",

      localHideInactiveCompanies: this.hideInactiveCompanies,

      savingBatch: false,
      savingMembershipsIds: [],
      savingPreferences: false,
      failedToSave: false,
      lastUpdateAt: null,
    };
  },

  computed: {
    ...mapGetters("current_user", {
      organizations: "getOrganizations",
      hasLoadedOrganizations: "hasLoadedOrganizations",
      lastOrganizationsLoad: "lastOrganizationsLoad",
    }),

    tabsData() {
      return {
        companyMemberships: {
          value: "company_memberships",
          label: this.$t(this.$theme.board_room_translation),
        },

        adminPanelMemberships: {
          value: "admin_panel_memberships",

          label: this.$t("activerecord.models.admin_panel.one"),
        },
      };
    },

    fields() {
      return {
        admin_panel_memberships: this.adminPanelMembershipFields,
        company_memberships: this.companyMembershipFields,
      };
    },

    adminPanelMembershipFields() {
      return [
        {
          key: "admin_panel.title",
          label: this.translateAttribute("company", "title"),
          sortable: true,
        },
        {
          key: "favorite",
          label: this.translateAttribute("membership", "favorite"),
          class: "col-shrink text-center",
        },
        {
          key: "visible",
          label: this.translateAttribute("membership", "visible"),
          class: "col-shrink text-center",
        },
      ];
    },

    companyMembershipFields() {
      const fields = [
        {
          key: "company_title",
          label: this.translateAttribute("company", "title"),
          sortable: true,
        },
      ];
      if (this.$theme.show_membership_roles) {
        fields.push({
          key: "role",
          label: this.translateAttribute("membership", "role"),
          sortable: true,
        });
      }

      if (this.$theme.show_membership_functions) {
        fields.push({
          key: "function",
          label: this.translateAttribute("membership", "function"),
          sortable: true,
        });
      }

      fields.push({
        key: "active_subscription",
        label: this.$t("views.users.organizations.active_subscription"),
      });
      fields.push({
        key: "favorite",
        label: this.translateAttribute("membership", "favorite"),
        class: "col-shrink text-center",
      });
      fields.push({
        key: "visible",
        label: this.translateAttribute("membership", "visible"),
        class: "col-shrink text-center",
      });

      return fields;
    },

    adminPanelMemberships() {
      return this.organizations.admin_panel_memberships
        .filter((membership) => membership.admin_panel) // filter out memberships without admin_panel
        .sort((a, b) => this.compareText(a, b, "admin_panel_title"));
    },

    companyMemberships() {
      return this.organizations.company_memberships
        .filter((membership) => membership.company_title) // filter out memberships without company_title
        .sort((a, b) => this.compareText(a, b, "company_title"));
    },

    memberships() {
      return {
        admin_panel_memberships: this.adminPanelMemberships,
        company_memberships: this.companyMemberships,
      };
    },
  },

  beforeMount() {
    // Load organizations if they are not loaded yet or if they are older than 5 minutes
    const fiveMinutes = 5 * 60 * 1000;
    if (
      new Date() - new Date(this.lastOrganizationsLoad) > fiveMinutes ||
      !this.hasLoadedOrganizations
    ) {
      this.loadOrganizations();
    }
  },

  methods: {
    ...mapActions("current_user", ["loadOrganizations"]),

    async save(membership, tabType) {
      this.savingMembershipsIds.push(membership.id);

      const controller =
        tabType === "admin_panel_memberships"
          ? "admin_panel_memberships"
          : "memberships";
      const param =
        tabType === "admin_panel_memberships"
          ? "admin_panel_membership"
          : "membership";

      if (membership.favorite) membership.visible = true;

      try {
        await axios.patch(
          this.url(`/users/organizations/${controller}/${membership.id}`),
          {
            [param]: membership,
          }
        );

        EventBus.emit("context_switcher:reload");
        this.lastUpdateAt = new Date();
      } catch (error) {
        this.handleError(error);
        this.failedToSave = true;
      } finally {
        this.savingMembershipsIds = this.savingMembershipsIds.filter(
          (id) => id !== membership.id
        );
      }
    },

    async savePreferences() {
      this.savingPreferences = true;

      try {
        await axios.patch(this.url("/users/preferences"), {
          user: {
            hide_inactive_companies_in_switcher:
              this.localHideInactiveCompanies,
          },
        });
        this.lastUpdateAt = new Date();
      } catch (error) {
        this.handleError(error);
        this.failedToSave = true;
      } finally {
        this.savingPreferences = false;
      }
    },

    async batchSave(tabType, visible) {
      this.savingBatch = true;

      const memberships = this.organizations[tabType].filter(
        (membership) => membership.visible !== visible
      );

      this.savingMembershipsIds = memberships.map(
        (membership) => membership.id
      );

      const batchResponses = await Promise.allSettled(
        memberships.map((membership) => {
          membership.visible = visible;
          return this.save(membership, tabType);
        })
      );

      batchResponses.forEach((response) => {
        if (response.status === "rejected") {
          console.log(response.reason);
        }
      });

      this.savingBatch = false;
      this.savingMembershipsIds = [];
    },
  },
};
</script>
