<template>
  <div class="card-body">
    <be-spinner v-if="loading">
      {{ $t("buttons.states.loading") }}
    </be-spinner>

    <table v-else class="table">
      <thead>
        <tr>
          <th></th>

          <th
            v-for="company in companies"
            :key="`company-${company.id}`"
            class="text-right"
          >
            {{ company.title }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th>{{ $t("views.home.dashboard.economy.last_updated") }}</th>

          <td
            v-for="company in companies"
            :key="`company-${company.id}`"
            class="text-right"
          >
            {{ $d(new Date(company.file.createTime), "date") }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("views.home.dashboard.economy.period") }}</th>

          <td
            v-for="company in companies"
            :key="`company-${company.id}`"
            class="text-right"
          >
            {{ company.period }}
          </td>
        </tr>

        <tr v-for="(kpi, index) in kpis" :key="index">
          <th>{{ kpi.label }}</th>

          <td
            v-for="company in companies"
            :key="`company-${company.id}`"
            class="text-right"
            :class="
              company.kpis[kpi.formula].current >
              company.kpis[kpi.formula].previous
                ? 'bg-success-light'
                : 'bg-danger-light'
            "
          >
            <i
              class="fal mr-1"
              :class="
                company.kpis[kpi.formula].current >
                company.kpis[kpi.formula].previous
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              "
            />
            {{ company.kpis[kpi.formula].current }}
            {{ kpi.suffix }}
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th></th>

          <td
            v-for="company in companies"
            :key="`company-${company.id}`"
            class="text-right"
          >
            <be-button
              :href="url(`/companies/${company.nanoid}/economy`)"
              variant="outline-primary"
              size="sm"
              inline
            >
              {{ $t("buttons.titles.show") }}
            </be-button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      companies: [],

      kpis: [
        // TODO: KBR
        {
          label: "Soliditet", // TODO: Load dynamically with formula
          startDate: null,
          endDate: null,
          periodization: "MONTH",
          type: "RESULT_SUM",
          formula: "Soliditet * 100",
          origin: "SELF",
          suffix: "%",
        },
        // TODO: Cashflow
        {
          label: "Vinst ack (KSEK)", // TODO: Load dynamically with formula
          startDate: null,
          endDate: null,
          periodization: "MONTH",
          type: "RESULT_SUM",
          formula: "Ack(R1) / 1000", // TODO: Find correct formula
          origin: "SELF",
        },
        {
          label: "Omsättning ack (KSEK)", // TODO: Load dynamically with formula
          startDate: null,
          endDate: null,
          periodization: "MONTH",
          type: "RESULT_SUM",
          formula: "Ack(R1) / 1000", // TODO: Find correct formula
          origin: "SELF",
        },
        {
          label: "Vinst mån (KSEK)", // TODO: Load dynamically with formula
          startDate: null,
          endDate: null,
          periodization: "MONTH",
          type: "RESULT_SUM",
          formula: "R1 / 1000", // TODO: Find correct formula
          origin: "SELF",
        },
        {
          label: "Omsättning mån (KSEK)", // TODO: Load dynamically with formula
          startDate: null,
          endDate: null,
          periodization: "MONTH",
          type: "RESULT_SUM",
          formula: "R1 / 1000", // TODO: Find correct formula
          origin: "SELF",
        },
      ],
    };
  },

  mounted() {
    this.initializeFinancials();
  },

  methods: {
    async initializeFinancials() {
      this.loading = true;
      try {
        const response = await axios.get(this.url("/home/economy"));

        let companies = response.data;

        companies.forEach((company) => {
          this.loadStatistics(company);
        });

        this.companies = companies;
      } catch (error) {
        this.handleError(error);
      }

      this.loading = false;
    },

    setupCompanyStatistics(company) {
      company.kpis = this.kpis.reduce((map, obj) => {
        map[obj.formula] = {
          current: "-",
          previous: "-",
        };
        return map;
      }, {});

      company.period = "";
    },

    loadStatistics(company) {
      this.setupCompanyStatistics(company);

      let kpis = this.kpis;

      kpis.forEach((kpi) => {
        kpi.startDate = company.file.coveredDates.start;
        kpi.endDate = company.file.coveredDates.endDate;
      });

      window.wzGetController().$api.getDynamicReport(
        window.wzGetController().token,
        company.id, // External ID
        `u${company.id}`, // Organization ID (Same as Boardeasers External ID)
        kpis,
        (response) => {
          const dataset = response.data.result.dataSet;

          this.kpis.forEach((kpi, index) => {
            company.kpis[kpi.formula].previous = this.formatNumber(
              dataset[index][dataset[index].length - 2]?.value
            );

            company.kpis[kpi.formula].current = this.formatNumber(
              dataset[index][dataset[index].length - 1]?.value
            );
          });

          company.period = response.data.result.dateRange[1];
        },
        (error) => {
          console.log(error);
        }
      );
    },

    formatNumber(number) {
      if (!number) {
        return "-";
      }

      return new BigNumber(number).toFormat(2);
    },
  },
};
</script>
