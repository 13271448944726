<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("components.financials.preparations.title") }}
      </h5>
    </div>

    <div class="card-body">
      <be-alert v-if="done" variant="success" loading>
        {{ $t("components.financials.preparations.page_reloading") }}
      </be-alert>

      <be-alert v-else loading>
        {{ $t("components.financials.preparations.loading") }}
      </be-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    jobCallbacks: {
      type: Array,
      required: true,
    },

    returnTo: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      timeoutId: null,
      done: false,
    };
  },

  watch: {
    done(value) {
      if (value) {
        window.location = this.returnTo;
      }
    },
  },

  created() {
    this.poll();
  },

  methods: {
    async poll() {
      const callbacks = await Promise.all(
        this.jobCallbacks.map(async ({ id }) => {
          const url = this.url(`/job_callbacks/${id}`);
          const { data } = await axios.get(url);
          return data;
        })
      );

      if (
        callbacks.filter(({ status }) =>
          ["initial", "in_progress"].includes(status)
        ).length > 0
      ) {
        this.timeoutId = setTimeout(this.poll, 1000);
      } else {
        this.done = true;
      }
    },
  },
};
</script>
