<template>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4 mb-xl-0">
        <h3>
          {{
            $t(
              "components.annual_reports.manual_delivery_tab.instructions_header"
            )
          }}
        </h3>

        <ol class="pl-3">
          <li class="mb-3">
            <p>
              {{
                $t(
                  "components.annual_reports.manual_delivery_tab.steps.upload_certification"
                )
              }}
            </p>

            <template v-if="showUploadCertificationPdf">
              <annual-report-documents document-key="certification" />
            </template>

            <template v-else-if="showUploadCertificationIxbrl">
              <annual-report-documents
                document-key="annual_report_w_certification"
              />
            </template>

            <template v-else-if="showFetchCertificationIxbrl">
              <be-alert variant="info">
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.certification_description_visualby"
                  )
                }}
                <be-link :href="url('/financials/consolidated_annual_reports')">
                  {{
                    $t(
                      "components.annual_reports.digital_delivery_tab.certification_button_visualby"
                    )
                  }}</be-link
                >
              </be-alert>

              <visualby-annual-report-fetcher
                :annual-report="annualReport"
                document-key="annual_report_w_certification"
              />

              <be-alert v-if="waitingForChecksum" loading class="mb-2">
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.waiting_for_checksum"
                  )
                }}
              </be-alert>

              <be-alert
                v-else-if="checksumsMismatch"
                variant="danger"
                class="mb-2"
              >
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.checksum_mismatch"
                  )
                }}
              </be-alert>
            </template>

            <be-alert
              v-else-if="waitingForCertificationGeneration"
              loading
              class="mb-2"
            >
              {{
                $t(
                  "components.annual_reports.manual_delivery_tab.waiting_for_certification_generation"
                )
              }}
            </be-alert>

            <be-alert
              v-if="showNoAnnualReportChecksumWarning"
              variant="warning"
            >
              {{
                $t("components.annual_reports.ixbrl_annual_report_no_checksum")
              }}
            </be-alert>

            <table v-if="showUploadedCertification" class="table table-hover">
              <tbody>
                <tr>
                  <td colspan="2">
                    <document-link
                      :document-id="certificationDocument.id"
                      :filename="filenameWithSize(certificationDocument)"
                    />
                  </td>

                  <td
                    v-if="canRemoveDocument(certificationDocument)"
                    class="text-right"
                  >
                    <be-button
                      v-be-tooltip="$t('buttons.titles.remove')"
                      variant="danger"
                      size="sm"
                      inline
                      icon="fa-times"
                      @click="removeDocument(certificationDocument)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </li>

          <li class="mb-3">
            <p>
              {{
                $t("components.annual_reports.manual_delivery_tab.steps.print")
              }}
            </p>

            <be-button
              v-if="forAuthorities"
              variant="outline-secondary"
              :disabled="!documentForAuthoritiesButton"
              class="mr-2 mb-2"
              :href="documentUrl(forAuthoritiesDocument)"
            >
              {{ $t("models.annual_report.documents.for_authorities") }}
            </be-button>

            <be-spinner v-if="loading">
              {{ $t("buttons.states.loading") }}
            </be-spinner>
          </li>

          <li class="mb-3">
            <p>
              {{
                $t("components.annual_reports.manual_delivery_tab.steps.sign")
              }}
            </p>

            <be-alert v-if="!showSentToAuthoritiesStatus" variant="warning">
              {{
                $t(
                  "components.annual_reports.manual_delivery_tab.steps.sign_warning"
                )
              }}
            </be-alert>
          </li>

          <li class="mb-3">
            <p>
              {{
                $t("components.annual_reports.manual_delivery_tab.steps.attest")
              }}
            </p>

            <be-alert v-if="!showSentToAuthoritiesStatus" variant="info">
              {{
                $t(
                  "components.annual_reports.manual_delivery_tab.steps.attest_warning"
                )
              }}
            </be-alert>
          </li>

          <li class="mb-3">
            <p>
              {{
                $t("components.annual_reports.manual_delivery_tab.steps.post")
              }}
            </p>

            <blockquote>
              <div>
                <strong>Bolagsverket</strong>
              </div>

              <div>Årsredovisningar</div>

              <div>851 98 Sundsvall</div>
            </blockquote>
          </li>

          <li>
            {{
              $t(
                "components.annual_reports.manual_delivery_tab.steps.mark_as_sent"
              )
            }}

            <be-button
              v-if="canSendToAuthorities"
              variant="primary"
              class="mt-md-2 mt-2 my-2"
              @click="sendToAuthorities"
            >
              {{
                $t(
                  "components.annual_reports.manual_delivery_tab.mark_as_sent_to_authorities"
                )
              }}
            </be-button>

            <be-alert
              v-if="showSentToAuthoritiesStatus"
              variant="success"
              class="my-2"
            >
              {{
                $t(
                  "components.annual_reports.manual_delivery_tab.sent_to_authorities_at",
                  {
                    date: sentToAuthoritiesDate,
                  }
                )
              }}
            </be-alert>
          </li>

          <li>
            {{
              $t(
                "components.annual_reports.manual_delivery_tab.steps.mark_as_received"
              )
            }}
          </li>
        </ol>
      </div>

      <div class="col-12 col-xl-6">
        <h3>{{ $t("components.annual_reports.documents.title") }}</h3>

        <table v-if="ixbrlDocuments" class="table table-hover mb-1">
          <thead>
            <tr>
              <th colspan="2">
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.ixbrl_files"
                  )
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="ixbrlDocument in ixbrlDocuments" :key="ixbrlDocument.id">
              <td colspan="2">
                <document-link
                  :document-id="ixbrlDocument.id"
                  :filename="filenameWithSize(ixbrlDocument)"
                />
              </td>

              <td class="text-right col-shrink">
                <be-button
                  v-if="canRemoveDocument(ixbrlDocument)"
                  v-be-tooltip="$t('buttons.titles.remove')"
                  variant="danger"
                  size="sm"
                  icon="fa-times"
                  inline
                  @click="confirmRemoveDocument(ixbrlDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table v-if="!!forAuthoritiesDocument" class="table table-hover mb-1">
          <thead>
            <tr>
              <th colspan="2">
                {{ $t("models.annual_report.documents.for_authorities") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="2">
                <document-link
                  :document-id="forAuthoritiesDocument.id"
                  :filename="filenameWithSize(forAuthoritiesDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <be-spinner v-if="loading">
          {{ $t("buttons.states.loading") }}
        </be-spinner>

        <table
          v-for="uploadedDocument in uploadedDocuments"
          :key="`uploaded-document-${uploadedDocument.id}`"
          class="table table-hover"
        >
          <thead>
            <tr>
              <th colspan="3">
                {{
                  $t(`models.annual_report.documents.${uploadedDocument.key}`)
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="2">
                <document-link
                  :document-id="uploadedDocument.id"
                  :filename="filenameWithSize(uploadedDocument)"
                />
              </td>

              <td class="text-right col-shrink">
                <be-button
                  v-if="canRemoveDocument(uploadedDocument)"
                  v-be-tooltip="$t('buttons.titles.remove')"
                  variant="danger"
                  size="sm"
                  icon="fa-times"
                  inline
                  @click="confirmRemoveDocument(uploadedDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <template v-if="canUploadSignedCertification">
          <be-alert v-if="!signedCertificationDocument" variant="info">
            {{
              $t(
                "components.annual_reports.manual_delivery_tab.upload_certification_info"
              )
            }}
          </be-alert>

          <template v-if="!signedCertificationDocument">
            <label :for="`signed_certification-${annualReport.id}`">
              {{ $t("models.annual_report.documents.signed_certification") }}
            </label>

            <annual-report-documents
              :id="`signed_certification-${annualReport.id}`"
              document-key="signed_certification"
              @document-updated="signedCertificationUploaded"
            />
          </template>
        </template>

        <div class="d-flex justify-content-end mt-3">
          <be-button
            v-if="canMarkAsReceivedByAuthorities"
            variant="primary"
            @click="
              confirmTriggerEvent(
                'mark_as_received_by_authorities',
                'manual_delivery'
              )
            "
          >
            {{
              $t(
                "components.annual_reports.manual_delivery_tab.mark_as_received_by_authorities_manual_delivery"
              )
            }}
          </be-button>
        </div>

        <be-alert v-if="showReceivedByAuthoritiesStatus" variant="success">
          {{
            $t(
              "components.annual_reports.manual_delivery_tab.received_by_authorities_at",
              {
                date: receivedByAuthoritiesDate,
              }
            )
          }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import VisualbyAnnualReportFetcher from "./VisualbyAnnualReportFetcher.vue";

export default {
  components: { VisualbyAnnualReportFetcher },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      documentForAuthoritiesButton: false,
      loading: false,
    };
  },

  computed: {
    waitingForCertificationGeneration() {
      return (
        !!this.annualReportWCertificationDocument && !this.certificationDocument
      );
    },

    canMarkAsReceivedByAuthorities() {
      return this.can(
        "mark_as_received_by_authorities",
        "manual_delivery",
        "update"
      );
    },

    canSendToAuthorities() {
      return this.can("send_to_authorities", "manual_delivery", "update");
    },

    canUploadSignedCertification() {
      return this.can(
        "upload_signed_certification",
        "manual_delivery",
        "upload"
      );
    },

    certificationDocument() {
      return this.annualReport.certification_document;
    },

    forAuthorities() {
      return (
        this.can("send_to_authorities", "manual_delivery") ||
        this.can("mark_as_received_by_authorities", "manual_delivery") ||
        this.annualReport.archived
      );
    },

    forAuthoritiesDocument() {
      return this.annualReport.for_authorities_document;
    },

    receivedByAuthoritiesDate() {
      const transitionDates = this.datesForTransitions([{ to: "archived" }]);

      if (transitionDates && transitionDates.length > 0) {
        return transitionDates[0];
      }

      return null;
    },

    sentToAuthoritiesDate() {
      const transitionDates = this.datesForTransitions([
        { to: "awaiting_authorities", namespace: "manual_delivery" },
        { to: "sent_to_authorities", namespace: null },
      ]);

      if (transitionDates && transitionDates.length > 0) {
        return transitionDates[0];
      }

      return null;
    },

    showReceivedByAuthoritiesStatus() {
      return this.annualReport.archived;
    },

    showSentToAuthoritiesStatus() {
      return (
        this.sentToAuthoritiesDate && this.sentToAuthoritiesDate.length > 0
      );
    },

    showUploadCertificationPdf() {
      return (
        this.can("upload_certification", "manual_delivery", "upload") &&
        !this.annualReportIxbrlDocument &&
        !this.certificationDocument
      );
    },

    showFetchCertificationIxbrl() {
      return (
        this.can("upload_certification", "manual_delivery", "upload") &&
        this.annualReport.annual_report_ixbrl_source_type == "visualby" &&
        !this.annualReportWCertificationDocument
      );
    },

    showUploadCertificationIxbrl() {
      return (
        this.can("upload_certification", "manual_delivery", "upload") &&
        this.annualReport.annual_report_ixbrl_source_type == "upload" &&
        !this.annualReportWCertificationDocument
      );
    },

    showUploadedCertification() {
      return !!this.certificationDocument;
    },

    signedCertificationDocument() {
      return this.annualReport.signed_certification_document;
    },

    // Get all non-nil documents for authorities
    documentsForAuthorities() {
      return [
        this.annualReport.certification_document,
        this.annualReport.annual_report_document,
        this.annualReport.audit_report_document,
      ].filter((doc) => doc);
    },
  },

  watch: {
    annualReport(value) {
      this.annualReport = this.cloneDeep(value);
      if (this.forAuthoritiesDocument) {
        this.documentForAuthoritiesButton = true;
      }
    },

    documentsForAuthorities: {
      handler(value) {
        const certificationIndex = value.findIndex(
          (doc) => doc.key === "certification"
        );
        if (certificationIndex !== -1 && !this.forAuthoritiesDocument) {
          this.startPolling("for_authorities");
        }
      },

      deep: true,
    },
  },

  mounted() {
    if (this.forAuthoritiesDocument) {
      this.documentForAuthoritiesButton = true;
    }
  },

  methods: {
    getDocumentKeyIndex(documentKey) {
      return this.annualReport.document_keys.indexOf(documentKey);
    },

    getFileIconClass(filename) {
      return getClassNameForFilename(filename);
    },

    async pollForExport(key) {
      try {
        const response = await axios.get(
          this.annualReport.paths.document_for_authorities_poll,
          {
            id: key,
          }
        );

        this.loading = false;
        this.annualReport.for_authorities_document = response.data;
        this.reloadAnnualReport(this.annualReport.id);

        // Clear polling timer
        clearInterval(this.timer);
      } catch (error) {
        this.handleError(error, { sentryLog: false });
      }
    },

    async sendToAuthorities() {
      this.fireAnnualReportEvent({
        id: this.annualReport.id,
        event: "send_to_authorities",
        namespace: "manual_delivery",
      });
    },

    signedCertificationUploaded(annualReport) {
      this.loading = false;
      this.setAnnualReport(annualReport);
    },

    startPolling(key) {
      this.loading = true;
      this.pollForExport(key);
      this.timer = setInterval(this.pollForExport, 3000);
    },

    documentUrl(doc) {
      if (doc?.id) {
        return this.url(`documents/${doc.id}`);
      }
      return "";
    },
  },
};
</script>
