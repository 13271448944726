<template>
  <div>
    <be-tabs v-model="activeTab" :options="tabsData">
      <template v-if="canDistributeDocuments" #content-right>
        <be-button
          :href="url('/documents/distributions/new')"
          variant="outline-primary"
        >
          {{ $t("admin_panels.documents.distributions.new.title") }}
        </be-button>
      </template>
    </be-tabs>

    <div v-if="activeTab === 'e-signatures'" class="card">
      <div class="card-body">
        <template v-if="localSignatureDocuments.length > 0">
          <be-table
            :items="localSignatureDocuments"
            :fields="signatureDocumentsFields"
          >
            <template #organization="{ item }">
              <be-link
                :href="url(`/companies/${companyNanoid(item.company_id)}`)"
              >
                {{ companyTitle(item.company_id) }}
              </be-link>
            </template>

            <template #document="{ item }">
              {{ item.title }}
            </template>

            <template #signatures="{ item }">
              {{
                `${signedSignatures(item.signatures)} / ${
                  item.signatures.length
                }`
              }}
            </template>

            <template #options="{ item }">
              <be-button
                :href="url(`/documents/signatures/${item.id}`)"
                variant="outline-secondary"
                size="sm"
                inline
              >
                {{ $t("buttons.titles.show") }}
              </be-button>
            </template>
          </be-table>
        </template>

        <be-alert v-else variant="info">{{ noDocumentsText }}</be-alert>
      </div>
    </div>

    <div v-if="activeTab === 'distributed-documents'" class="card">
      <div class="card-body">
        <template v-if="localDistributedDocuments.length > 0">
          <be-table
            :items="localDistributedDocuments"
            :fields="distributedDocumentsfields"
          >
            <template #title="{ item }">
              <i
                v-if="item.fontawesome_icon"
                :class="`${item.fontawesome_icon} mr-3 text-muted`"
              />

              <be-link :href="url(`/documents/distributions/${item.id}`)">
                {{ item.title }}
              </be-link>
            </template>

            <template #created_at="{ item }">
              {{ $d(new Date(item.created_at), "dateTime") }}
            </template>

            <template #filesize="{ item }">
              {{ item.file_size }}
            </template>

            <template #options="{ item }">
              <be-dropdown v-if="item.policy.destroy" size="sm" ellipsis>
                <be-dropdown-item
                  variant="danger"
                  @click="deleteDocument(item)"
                >
                  {{ $t("buttons.titles.remove") }}
                </be-dropdown-item>
              </be-dropdown>
            </template>
          </be-table>

          <em class="small">
            {{
              $t(
                "admin_panels.documents.distributions.index.showing_document_size",
                {
                  max_document_size: DOCUMENT_SIZE,
                }
              )
            }}
          </em>
        </template>

        <be-alert v-else variant="info">
          {{ noDocumentsText }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
const DOCUMENT_SIZE = 50;

export default {
  props: {
    signatureDocuments: {
      type: Array,
      required: true,
    },

    distributedDocuments: {
      type: Array,
      required: true,
    },

    companyTitlesAndNanoids: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    canDistributeDocuments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      localSignatureDocuments: this.cloneDeep(this.signatureDocuments),
      localDistributedDocuments: this.cloneDeep(this.distributedDocuments),
      activeTab: "e-signatures",
      DOCUMENT_SIZE: DOCUMENT_SIZE,
    };
  },

  computed: {
    tabsData() {
      return {
        eSignatures: {
          value: "e-signatures",
          label: this.$t("admin_panels.companies.documents.index.e-signings"),
          count: this.localSignatureDocuments.length,
        },

        distributedDocuments: {
          value: "distributed-documents",

          label: this.$t(
            "admin_panels.companies.documents.index.distributed_documents"
          ),

          count: this.localDistributedDocuments.length,
        },
      };
    },

    signatureDocumentsFields() {
      return [
        {
          key: "organization",

          label: this.$t(
            "admin_panels.documents.signatures.table.organization"
          ),

          sortable: true,
        },
        {
          key: "document",
          label: this.$t("admin_panels.documents.signatures.table.document"),
          sortable: true,
        },
        {
          key: "signatures",
          label: this.$t("admin_panels.documents.signatures.table.signatures"),
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ];
    },

    distributedDocumentsfields() {
      return [
        {
          key: "title",

          label: this.$t(
            "admin_panels.companies.documents.index.latest_distributed"
          ),

          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("activerecord.attributes.document.created_at"),
          sortable: true,
        },
        {
          key: "filesize",
          label: this.$t("activerecord.attributes.document.filesize"),
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ];
    },

    noDocumentsText() {
      if (this.activeTab === "e-signatures") {
        return this.$t("admin_panels.documents.signatures.table.no_e_signings");
      } else {
        return this.$t(
          "admin_panels.documents.distributions.index.no_documents"
        );
      }
    },
  },

  methods: {
    async deleteDocument(distributedDocument) {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t("nav.confirm_delete_w_title", {
          title: distributedDocument.title,
        }),

        description: this.$t(
          "components.admin_panel.documents.confirm_delete_description"
        ),
      });

      if (!isConfirmed) {
        return;
      }

      try {
        await axios.delete(
          this.url(`/documents/distributions/${distributedDocument.id}`)
        );

        const index = this.localDistributedDocuments.findIndex(
          (existingDocument) => existingDocument.id === distributedDocument.id
        );

        if (index > -1) {
          this.localDistributedDocuments.splice(index, 1);
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    signedSignatures(signatures) {
      return signatures.filter((signature) => !!signature.signed_at).length;
    },

    companyTitle(companyId) {
      if (this.companyTitlesAndNanoids.length === 0) {
        return null;
      }

      return this.companyTitlesAndNanoids[companyId].title || null;
    },

    companyNanoid(companyId) {
      if (this.companyTitlesAndNanoids.length === 0) {
        return null;
      }

      return this.companyTitlesAndNanoids[companyId].nanoid || null;
    },
  },
};
</script>
