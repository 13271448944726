<template>
  <be-table
    v-if="activeConfigurations.length > 0"
    :items="activeConfigurations"
    :fields="fields"
    :per-page="30"
  >
    <template #name="{ item }">
      <a :href="linkToConfiguration(item.config)">
        {{ item.config.label }}
      </a>
    </template>

    <template #updated="{ item }">
      <time :title="$d(new Date(item.config.createTime), 'dateTime')">
        {{ $d(new Date(item.config.updateTime), "dateTime") }}
      </time>
    </template>

    <template #visibility="{ item }">
      <be-badge
        :variant="item.config.visibility == 'MANAGER' ? 'success' : 'secondary'"
      >
        {{
          $t(
            `components.financials.shared.visibility.${item.config.visibility.toLowerCase()}`
          )
        }}
      </be-badge>
    </template>

    <template #options="{ item }">
      <be-dropdown size="sm" ellipsis>
        <be-dropdown-item
          variant="danger"
          @click="deleteConfiguraton(item.config)"
        >
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </template>
  </be-table>

  <be-alert v-else variant="info">
    {{ $t("components.financials.configurations.empty") }}
  </be-alert>
</template>

<script>
export default {
  props: {
    configurations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeConfigurations: this.configurations,
    };
  },

  computed: {
    fields() {
      return [
        {
          key: "name",
          label: this.$i18n.t("views.admin_panels.financials.show.table.name"),
        },
        {
          key: "updated",

          label: this.$i18n.t(
            "views.admin_panels.financials.show.table.updated"
          ),

          class: "col-shrink",
        },
        {
          key: "visibility",

          label: this.$i18n.t(
            "views.admin_panels.financials.show.table.visibility"
          ),

          class: "col-shrink",
        },
        {
          key: "options",
          label: "",
          class: "col-shrink text-center",
        },
      ];
    },
  },

  methods: {
    linkToConfiguration(configuration) {
      const PREFIXES = {
        graph_plus: "graphs",
        widget: "widgets",
        table_editor: "tables",
      };

      return `financials/${PREFIXES[configuration.configType]}/${
        configuration.uuid
      }/edit`;
    },

    async deleteConfiguraton(configuration) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("views.admin_panels.financials.show.confirm", {
          name: configuration.label,
        })
      );

      if (isConfirmed) {
        try {
          await window
            .wzGetController()
            .$api.deleteReportConfiguration(
              this.$activeFinancialsUuid,
              configuration.uuid
            );

          // Splice from activeConfigurations array
          let idx = this.activeConfigurations.findIndex(
            (activeConfiguration) => {
              return activeConfiguration.config.uuid == configuration.uuid;
            }
          );

          if (idx > -1) {
            this.activeConfigurations.splice(idx, 1);
          } else {
            console.warn("Unable to find configuration");
          }
        } catch (e) {
          console.error("Unable to remove the SIE-file");
        }
      }
    },
  },
};
</script>
