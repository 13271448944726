<template>
  <div class="mb-4">
    <div v-if="lockedDocument" class="d-flex justify-content-end mt-2">
      <be-button variant="primary" class="ml-auto" @click="goToAnnualReport">
        {{
          $t(
            "components.annual_reports.visualby_annual_report_bridge.go_to_annual_report"
          )
        }}
      </be-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { poller: null, reports: [] };
  },

  computed: {
    lockedDocument() {
      return this.reports.find(({ lockedTime }) => lockedTime);
    },
  },

  watch: {
    lockedDocument(value) {
      if (value) {
        this.stopPolling();
      }
    },
  },

  async mounted() {
    this.checkDocuments();
    this.startPolling();
  },

  methods: {
    startPolling() {
      this.poller = setInterval(() => this.checkDocuments(), 20000);
    },

    stopPolling() {
      clearInterval(this.poller);
    },

    formatType(typeString) {
      if (typeString.startsWith("K3_GROUP")) {
        return this.$t(
          "components.annual_reports.visualby_annual_report_fetcher.k3_group"
        );
      }
      return this.$t(
        "components.annual_reports.visualby_annual_report_fetcher.unknown_type"
      );
    },

    formatDate(dateString) {
      if (dateString) {
        return this.$d(this.parseDate(dateString), "dateTime");
      } else {
        return "-";
      }
    },

    async checkDocuments() {
      try {
        const { data } = await axios.get(this.url("/visualby_annual_reports"));

        const lastYear = data.items.reduce(
          (acc, { year }) => Math.max(acc, year),
          0
        );

        this.reports = data.items.filter(({ year }) => year == lastYear);
      } catch (error) {
        this.stopPolling();
      }
    },

    async goToAnnualReport() {
      const document = this.lockedDocument;
      const type = this.formatType(document.type);
      const time = this.formatDate(document.lockedTime);

      try {
        const { data } = await axios.post(
          this.url("/annual_reports/from_visualby_uuid"),
          {
            year: document.year,
            uuid: document.uuid,
            filename: `${type}_${time}.ixbrl.xhtml`,
            pdf_filename: `${type}_${time}.pdf`,
          }
        );

        window.location.href = this.url(
          `/annual_reports/${data.annual_report_id}`
        );
      } catch (error) {
        // There will be a flash message, and we don't need to do anything else
      }
    },
  },
};
</script>
