<template>
  <div class="card rounded">
    <div class="card-header rounded">
      <div class="d-flex flex-row w-100">
        <div class="flex-grow-1 text-truncate">
          <div class="d-flex flex-column">
            <be-badge variant="secondary" class="mr-auto mb-1">
              {{ signature.type }}
            </be-badge>

            <div class="row">
              <div
                v-be-tooltip="signature.title"
                class="col-auto text-truncate"
              >
                <h5 class="text-truncate mb-0">{{ signature.title }}</h5>
              </div>
            </div>

            <h6 class="text-muted">
              {{ company.title }}
            </h6>

            <small>
              {{ $t("components.home.dashboard.signature_row.requested") }}
              {{ formatDate(signature.date) }}
            </small>
          </div>
        </div>

        <div class="ml-2 align-self-center">
          <be-button variant="primary" :href="signature.link">
            {{ $t("buttons.titles.sign") }}
          </be-button>
        </div>
      </div>

      <div class="text-reset text-decoration-none">
        <be-alert
          v-if="signature.remaining_signatories_count == 1"
          variant="warning"
          class="font-weight-normal small text-wrap mt-3"
        >
          {{
            $t(
              "components.home.dashboard.signature_row.you_are_the_only_remaining_signatory"
            )
          }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    signature: {
      type: Object,
      required: true,
    },
  },

  computed: {
    company() {
      return this.$store.getters["company/getById"](this.signature.company_id);
    },
  },

  methods: {
    formatDate(dateString) {
      return this.$d(this.parseDate(dateString), "date");
    },
  },
};
</script>
