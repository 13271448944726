<template>
  <div class="card-body">
    <div class="d-flex justify-content-between mb-2 flex-column flex-md-row">
      <div class="d-flex justify-content-between">
        <label>
          {{ $t("activerecord.models.inquiry.other") }}
        </label>

        <be-form-checkbox v-model="batchEnabled" class="d-md-none" size="sm">
          {{ $t("views.companies.inquiries.minutes.batch_management") }}
        </be-form-checkbox>
      </div>

      <div
        v-if="isMinutes"
        class="d-flex align-items-center flex-column flex-md-row gap-2"
      >
        <be-form-checkbox
          v-model="batchEnabled"
          class="mr-3 d-none d-md-inline-block"
          size="sm"
        >
          {{ $t("views.companies.inquiries.minutes.batch_management") }}
        </be-form-checkbox>

        <be-button
          v-if="batchEnabled"
          variant="outline-secondary"
          size="sm"
          @click="toggleSelectAll"
        >
          {{
            selectedInquiries.length < inquiries.length
              ? $t("buttons.toggle_all_selection.select_all")
              : $t("buttons.toggle_all_selection.deselect_all")
          }}
        </be-button>

        <be-button
          variant="outline-secondary"
          size="sm"
          @click="toggleExpandAll"
        >
          {{
            allInquiriesExpanded
              ? $t("buttons.toggle_all_selection.minimize_all")
              : $t("buttons.toggle_all_selection.expand_all")
          }}
        </be-button>
      </div>
    </div>

    <be-skeleton-table v-if="loading" :rows="5" :columns="4" />

    <div v-else>
      <div v-if="inquiries.length > 0">
        <!-- Show Inquiries when you are inside of the minutes -->
        <div v-if="isMinutes">
          <div
            v-if="batchEnabled"
            class="bg-light border border-light rounded p-2 mb-2"
          >
            <div
              class="d-block d-md-flex align-items-center justify-content-between"
            >
              <p class="mb-2 mb-md-0 ml-1 text-muted small">
                {{
                  $t("views.companies.inquiries.minutes.selected_inquiries", {
                    selected: selectedInquiries.length,
                    total: inquiries.length,
                  })
                }}
                <span class="font-weight-bold">
                  {{ selectedInquiriesRequestedValue }}
                </span>
              </p>

              <div class="d-flex flex-wrap align-items-center gap-1">
                <be-button
                  v-be-modal="`choose-batch-value-modal-${itemId}`"
                  variant="outline-secondary"
                  size="sm"
                >
                  {{
                    $t(
                      "views.companies.inquiries.minutes.choose_value_for_selected"
                    )
                  }}
                </be-button>

                <be-modal
                  :id="`choose-batch-value-modal-${itemId}`"
                  :title="
                    $t(
                      'views.companies.inquiries.minutes.choose_value_for_selected'
                    )
                  "
                  :ok-title="$t('buttons.titles.save')"
                  @ok="setBatchValue(batchValue)"
                >
                  <be-alert variant="info">
                    {{
                      $t(
                        "views.companies.inquiries.minutes.choose_value_for_selected_information"
                      )
                    }}
                  </be-alert>

                  <be-form-group
                    :label="$t('views.companies.inquiries.minutes.value')"
                    label-for="batch-value"
                  >
                    <be-form-input
                      id="batch-value"
                      v-model="batchValue"
                      type="number"
                      class="mr-2 w-50"
                    />
                  </be-form-group>
                </be-modal>

                <be-form-select
                  id="batch-action-select"
                  v-model="selectedOption"
                  v-be-tooltip="{
                    title: $t(
                      'views.companies.inquiries.minutes.no_inquiries_selected'
                    ),
                    disabled: selectedInquiries.length > 0,
                  }"
                  :options="[
                    {
                      value: 'approved',
                      text: $t('views.companies.inquiries.inquiry.approve'),
                    },
                    {
                      value: 'postponed',
                      text: $t('views.companies.inquiries.inquiry.postpone'),
                    },
                    {
                      value: 'rejected',
                      text: $t('views.companies.inquiries.inquiry.reject'),
                    },
                  ]"
                  :disabled="selectedInquiries.length === 0"
                  class="d-block d-md-inline-block w-100 w-md-auto"
                  :include-blank-option="$t('buttons.titles.choose_action')"
                  @change="handleBatchUpdate"
                />
              </div>
            </div>
          </div>

          <inquiry-item
            v-for="inquiry in inquiries"
            :key="`inquiry-${inquiry.id}`"
            :ref="`inquiry-${inquiry.id}`"
            :inquiry="inquiry"
            :selected-inquiries-ids="selectedInquiriesIds"
            :saving-batch="savingBatch"
            :item-id="itemId"
            :material-id="material.id"
            :meeting-id="meeting.id"
            :batch-enabled="batchEnabled"
            @select="toggleInquirySelection($event)"
          />

          <!-- Summary of inquiries when statuses has been applied -->
          <div
            v-if="summary.handled > 0"
            variant="light"
            class="bg-light border border-light rounded p-3 text-muted"
          >
            <p class="mb-2">
              {{
                $t("views.companies.inquiries.minutes.handled_inquiries", {
                  handled: summary.handled,
                  total: summary.count,
                })
              }}
            </p>

            <div
              v-for="(statusSummary, status) in summary.statuses"
              :key="status"
              class="['d-flex align-items-center', { 'mt-1': index > 0 }]"
            >
              <p>
                <i :class="['far fa-fw mr-1', inquiryStatusIcon(status)]"></i>

                <span>
                  {{
                    $tc(
                      `views.companies.inquiries.minutes.${status}_inquiries_w_count`,
                      statusSummary.count,
                      { count: statusSummary.count }
                    )
                  }}:
                </span>

                <span
                  v-for="(
                    currencySummary, currency, index
                  ) in statusSummary.currencies"
                  :key="currency"
                >
                  <span v-if="index > 0" class="mx-1">/</span>

                  <money-format
                    v-if="status == 'approved'"
                    class="font-weight-bold small"
                    :currency="currency"
                    :value="currencySummary.total_granted_value"
                  />

                  <money-format
                    v-else-if="status == 'postponed' || status == 'rejected'"
                    class="font-weight-bold small"
                    :currency="currency"
                    :value="currencySummary.total_requested_value"
                  />
                </span>
              </p>
            </div>
          </div>
          <!-- End of Summary of inquiries when statuses has been applied -->
        </div>
        <!-- End of Show Inquiries when you are inside of the minutes -->

        <!-- Show Table of Inquiries when planning the meeting -->
        <table v-else class="table">
          <thead>
            <tr>
              <th>{{ $t("activerecord.attributes.inquiry.external_id") }}</th>

              <th v-if="platformEnabled('inquiries_title')">
                {{ $t("activerecord.attributes.inquiry.title") }}
              </th>

              <th v-if="platformEnabled('inquiries_receiver')">
                {{ $t("activerecord.attributes.inquiry.receiver") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.inquiry.requested_value") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.inquiry.created_at") }}
              </th>

              <th class="col-shrink"></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="inquiry in inquiries"
              :key="`inquiry-${inquiry.id}`"
              :class="`status-${'pending'}`"
            >
              <td>{{ inquiry.external_id }}</td>

              <td v-if="platformEnabled('inquiries_title')">
                {{ inquiry.title }}
              </td>

              <td v-if="platformEnabled('inquiries_receiver')">
                <template v-if="inquiry.inquiry_receiver?.name">
                  {{ inquiry.inquiry_receiver.name }}
                </template>

                <template v-else> - </template>
              </td>

              <td>
                <money-format
                  :currency="inquiry.currency"
                  :value="Number(inquiry.requested_value || null)"
                />
              </td>

              <td>{{ $d(new Date(inquiry.created_at), "date") }}</td>

              <td class="col-shrink">
                <be-button
                  v-be-modal="`inquiry-${inquiry.id}`"
                  variant="outline-secondary"
                  size="sm"
                  inline
                >
                  {{ $t("buttons.titles.show_more") }}
                </be-button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End of Show Table of Inquiries when planning the meeting -->

        <!-- Modal of detailed Inquiry when planning the meeting -->
        <be-modal
          v-for="inquiry in inquiries"
          :id="`inquiry-${inquiry.id}`"
          :key="`inquiry-modal-${inquiry.id}`"
          :title="inquiry.external_id"
          ok-only
        >
          <div>
            <strong>
              {{ $t("activerecord.attributes.inquiry.external_id") }}
            </strong>

            <p>{{ inquiry.external_id }}</p>
          </div>

          <div>
            <strong>
              {{ $t("activerecord.attributes.inquiry.title") }}
            </strong>

            <p>{{ inquiry.title }}</p>
          </div>

          <div v-if="platformEnabled('inquiries_receiver')">
            <strong>
              {{ $t("activerecord.attributes.inquiry.receiver") }}
            </strong>

            <p>
              <template v-if="inquiry.inquiry_receiver?.name">
                {{ inquiry.inquiry_receiver.name }}
              </template>

              <template v-else> - </template>
            </p>
          </div>

          <div v-if="inquiry.application_value">
            <strong>
              {{ $t("activerecord.attributes.inquiry.application_value") }}
            </strong>

            <p>
              <money-format
                :currency="inquiry.currency"
                :value="Number(inquiry.application_value)"
              />
            </p>
          </div>

          <div>
            <strong>
              {{ $t("activerecord.attributes.inquiry.requested_value") }}
            </strong>

            <p>
              <money-format
                :currency="inquiry.currency"
                :value="Number(inquiry.requested_value)"
              />
            </p>
          </div>

          <div v-if="inquiry.description">
            <strong>
              {{ $t("activerecord.attributes.inquiry.description") }}
            </strong>

            <p>
              {{ inquiry.description }}
            </p>
          </div>

          <div v-if="platformEnabled('inquiries_link') && inquiry.link">
            <strong>
              {{ $t("activerecord.attributes.inquiry.link") }}
            </strong>

            <p>
              <be-link :href="inquiry.link">
                {{ $t("views.companies.inquiries.table.go_to_inquiry") }}
              </be-link>
            </p>
          </div>
        </be-modal>
        <!-- End of Modal of detailed Inquiry when planning the meeting -->
      </div>

      <be-alert v-else variant="info" class="mt-2">
        {{ $t("models.inquiry.nothing_to_follow_up") }}
      </be-alert>

      <!-- Confirm Batch Update Modal -->
      <be-modal
        :id="`confirm-batch-modal-${itemId}`"
        :title="confirmModalTitle"
        :ok-title="confirmOkTitle"
        size="xl"
        @ok="batchUpdate"
        @hide="selectedOption = null"
      >
        <h5 class="p-2">
          {{
            $t(
              "views.companies.inquiries.minutes.confirm_batch.body_w_action",
              {
                action: confirmOkTitle.toLowerCase(),
              }
            )
          }}
          {{ selectedInquiriesGrantedValue }}?
        </h5>

        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("activerecord.attributes.inquiry.external_id") }}</th>

              <th v-if="platformEnabled('inquiries_title')">
                {{ $t("activerecord.attributes.inquiry.title") }}
              </th>

              <th v-if="platformEnabled('inquiries_receiver')">
                {{ $t("activerecord.attributes.inquiry.receiver") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.inquiry.requested_value") }}
              </th>

              <th v-if="selectedOption === 'approved'">
                {{ $t("activerecord.attributes.inquiry_item.granted_value") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="inquiry in selectedInquiries"
              :key="`inquiry-${inquiry.id}`"
              :class="`status-${'pending'}`"
            >
              <td>{{ inquiry.external_id }}</td>

              <td v-if="platformEnabled('inquiries_title')">
                {{ inquiry.title }}
              </td>

              <td v-if="platformEnabled('inquiries_receiver')">
                <template v-if="inquiry.inquiry_receiver?.name">
                  {{ inquiry.inquiry_receiver.name }}
                </template>

                <template v-else> - </template>
              </td>

              <td>
                <money-format
                  :currency="inquiry.currency"
                  :value="Number(inquiry.requested_value || null)"
                />
              </td>

              <td v-if="selectedOption === 'approved'">
                <money-format
                  :currency="inquiry.currency"
                  :value="
                    Number(
                      inquiry.inquiry_items.find(
                        (item) => item.item_id === itemId
                      )?.granted_value || inquiry.requested_value
                    )
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </be-modal>
      <!-- End Confirm Batch Update Modal -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import materialType from "../materialState";
import InquiryItem from "./InquiryItem.vue";

export default {
  components: {
    InquiryItem,
  },

  mixins: [materialType],

  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  emits: ["select"],

  data() {
    return {
      batchAction: null,
      batchEnabled: false,
      batchValue: null,
      loading: false,
      savingBatch: [],
      selectedOption: null,
      selectedInquiriesIds: [],
      statuses: ["approved", "rejected", "postponed"],
    };
  },

  computed: {
    ...mapGetters({
      meeting: "material/meeting",
      getInquiries: "inquiries/getInquiriesWithItems",
      getSummary: "inquiries/getSummary",
      getCurrencies: "inquiries/getCurrencies",
      loadedInquiries: "inquiries/hasLoadedInquiries",
      material: "material/material",
    }),

    inquiries() {
      return this.getInquiries({ meeting: this.meeting });
    },

    summary() {
      return this.getSummary({ meeting: this.meeting });
    },

    currencies() {
      return this.getCurrencies({ meeting: this.meeting });
    },

    selectedInquiries() {
      return this.inquiries.filter((inquiry) =>
        this.selectedInquiriesIds.includes(inquiry.id)
      );
    },

    formattedStatuses() {
      let formattedStatuses = [];

      this.$config.INQUIRIES.STATUSES.forEach((status) => {
        formattedStatuses.push({
          text: this.$i18n.t(`models.inquiry_item.statuses.${status}`),
          value: status,
        });
      });

      return formattedStatuses;
    },

    confirmModalTitle() {
      return this.selectedOption
        ? this.$tc(
            `views.companies.inquiries.minutes.confirm_batch.${this.selectedOption}_title_w_count`,
            this.selectedInquiries.length,
            { count: this.selectedInquiries.length }
          )
        : "";
    },

    confirmOkTitle() {
      return this.batchAction
        ? this.$t(`views.companies.inquiries.inquiry.${this.batchAction}`)
        : "";
    },

    selectedInquiriesRequestedValue() {
      return this.selectedInquiries.reduce(
        (sum, inquiry) => sum + Number(inquiry.requested_value),
        0
      );
    },

    selectedInquiriesGrantedValue() {
      return this.selectedInquiries.reduce((sum, inquiry) => {
        const inquiryItem = inquiry.inquiry_items.find(
          (item) => item.item_id === this.itemId
        );

        return (
          sum +
          (Number(inquiryItem?.granted_value) ||
            Number(inquiry.requested_value))
        );
      }, 0);
    },

    allInquiriesExpanded() {
      return this.inquiries.every((inquiry) => {
        if (this.$refs[`inquiry-${inquiry.id}`]) {
          return this.$refs[`inquiry-${inquiry.id}`][0].expanded;
        }
      });
    },
  },

  async created() {
    if (this.platformEnabled("inquiries")) {
      this.loading = true;

      await this.loadInquiries({
        itemId: this.itemId,
        meetingId: this.meeting.id,
      });

      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      loadInquiries: "inquiries/loadInquiriesAndGenerateMissingInquiryItems",
      updateInquiryItem: "inquiries/updateInquiryItem",
      removeInquiryItem: "inquiries/removeInquiryItem",
    }),

    ...mapMutations({
      generateMissingInquiryItems: "inquiries/generateMissingInquiryItems",
    }),

    inquiryStatusIcon(status) {
      const icons = {
        approved: "fa-check",
        rejected: "fa-times",
        postponed: "fa-clock",
      };

      return icons[status];
    },

    toggleInquirySelection(inquiryId) {
      const idx = this.selectedInquiriesIds.findIndex(
        (selectedInquiryId) => selectedInquiryId === inquiryId
      );

      if (idx > -1) {
        this.selectedInquiriesIds.splice(idx, 1);
      } else {
        this.selectedInquiriesIds.push(inquiryId);
      }
    },

    toggleSelectAll() {
      if (this.selectedInquiriesIds.length === this.inquiries.length) {
        this.selectedInquiriesIds = [];
      } else {
        this.selectedInquiriesIds = this.inquiries.map((inquiry) => inquiry.id);
      }
    },

    toggleExpandAll() {
      const allExpanded = this.inquiries.every(
        (inquiry) => this.$refs[`inquiry-${inquiry.id}`][0].expanded
      );

      if (allExpanded) {
        this.inquiries.forEach((inquiry) => {
          if (this.$refs[`inquiry-${inquiry.id}`]) {
            this.$refs[`inquiry-${inquiry.id}`][0].expanded = false;
          }
        });
      } else {
        this.inquiries.forEach((inquiry) => {
          if (this.$refs[`inquiry-${inquiry.id}`]) {
            this.$refs[`inquiry-${inquiry.id}`][0].expanded = true;
          }
        });
      }
    },

    isSelected(inquiryId) {
      return this.selectedInquiriesIds.includes(inquiryId);
    },

    async handleBatchUpdate() {
      let actions = {
        approved: "approve",
        postponed: "postpone",
        rejected: "reject",
      };

      this.batchAction = actions[this.selectedOption];
      this.$beModal.show(`confirm-batch-modal-${this.itemId}`);
    },

    async batchUpdate() {
      this.savingBatch = this.cloneDeep(this.selectedInquiries);

      const status = this.selectedOption;

      const batchResponses = await Promise.allSettled(
        this.selectedInquiries.map((inquiry) =>
          this.patchInquiryItem(this.formatInquiryItem(inquiry, status))
        )
      );

      batchResponses.forEach((response) => {
        if (response.status === "rejected") {
          console.log(response.reason);
        } else {
          const inquiryItem = response.value;

          this.savingBatch = this.savingBatch.filter(
            (inquiry) => inquiry.id !== inquiryItem.inquiry_id
          );

          if (this.$refs[`inquiry-${inquiryItem.inquiry_id}`]) {
            this.$refs[`inquiry-${inquiryItem.inquiry_id}`][0].expanded = false;
          }
        }
      });

      this.selectedInquiriesIds = [];
    },

    async patchInquiryItem(inquiryItem) {
      return await this.updateInquiryItem({
        url: `${this.meeting.paths.base}/materials/${this.material.id}/inquiry_items`,

        inquiryItem: inquiryItem,
      });
    },

    formatInquiryItem(inquiry, status) {
      let inquiry_item =
        inquiry.inquiry_items.find(
          (inquiryItem) => inquiryItem.item_id === this.itemId
        ) || {};

      return {
        comment: inquiry_item.comment || "",
        item_id: this.itemId,
        inquiry_id: inquiry.id,
        status: status,
        meeting_id: inquiry_item.meeting_id,
        temp_id: inquiry_item.temp_id,
        currency: inquiry_item.currency,

        granted_value:
          status === "approved" ? Number(inquiry_item.granted_value) : null,
      };
    },

    setBatchValue(value) {
      this.selectedInquiries.forEach((inquiry) => {
        let inquiryItem =
          inquiry.inquiry_items.find(
            (inquiryItem) => inquiryItem.item_id === this.itemId
          ) || {};

        inquiryItem.granted_value = value;
      });
    },
  },
};
</script>
