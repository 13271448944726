<template>
  <div>
    <header class="mb-4">
      <h1 class="mb-0">{{ $t("models.meeting.historic_meeting.other") }}</h1>
    </header>

    <be-tabs v-model="activeTab" :options="tabsData">
      <template #content-right>
        <be-button
          v-be-modal.historic-upload
          :variant="draftMeetings.length === 0 ? 'primary' : 'outline-primary'"
        >
          {{ $t("components.meetings.material.upload_modal.upload_minutes") }}
        </be-button>

        <be-modal
          id="historic-upload"
          size="lg"
          :title="
            $t('components.meetings.material.upload_modal.upload_minutes')
          "
          :ok-disabled="documents.length === 0"
          :ok-title="$t('buttons.titles.upload')"
          @ok="createMeetings"
        >
          <div>
            <be-alert variant="info">
              {{ $t("components.meetings.historic.upload_info") }}
            </be-alert>

            <be-form-group
              :label="$t('activerecord.attributes.material.minutes')"
              label-for="document-uploader"
            >
              <document-uploader
                id="document-uploader"
                :accepted-types="['pdf']"
                :documents="documents"
                @document-added="documentAdded"
                @document-removed="documentRemoved"
              />
            </be-form-group>
          </div>
        </be-modal>
      </template>
    </be-tabs>

    <div
      v-if="activeTab === 'drafts' && draftMeetings.length === 0"
      class="card"
    >
      <div class="card-body">
        <be-alert>
          {{ $t("components.meetings.historic.no_drafts") }}
        </be-alert>
      </div>
    </div>

    <div
      v-if="activeTab === 'meetings' && historicMeetings.length === 0"
      class="card"
    >
      <div class="card-body">
        <be-alert>
          {{ $t("components.meetings.historic.no_meetings") }}
        </be-alert>
      </div>
    </div>

    <div
      v-for="meeting in shownMeetings"
      :key="`meeting-${meeting.id}`"
      class="card mb-3"
    >
      <div class="card-header justify-content-between">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-md">
            <h5 class="card-title mb-1">
              <a
                v-if="meeting.meeting_type === 'historic'"
                :href="meeting.paths.base"
              >
                {{ meeting.title }}
              </a>

              <span v-else-if="meeting.title" class="mr-2">
                {{ meeting.title }}
              </span>

              <span v-else class="text-muted">
                {{ $t("components.meetings.historic.no_title") }}
              </span>
            </h5>

            <div
              v-if="meeting.minutes.external_document"
              class="d-flex flex-wrap"
            >
              <small class="text-muted">
                {{ meeting.minutes.external_document.filename }}
              </small>
            </div>
          </div>

          <div class="col-12 col-md-auto">
            <be-button
              v-be-modal="`historic-meeting-${meeting.id}`"
              variant="outline-primary"
            >
              {{ $t("buttons.titles.edit") }}
            </be-button>

            <be-button variant="danger" @click="removeMeeting(meeting)">
              <i class="fa fa-times" />
            </be-button>

            <historic-meeting-editor
              :modal-id="`historic-meeting-${meeting.id}`"
              :meeting="meeting"
              @saved="updateMeeting"
              @create-meeting="saveAndPublish"
            />
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <div
              v-if="
                flipperFlag('meeting_series_flipper') &&
                $platform.features.meeting_series
              "
              class="media mb-4 mb-sm-3"
            >
              <div
                class="media-icon align-self-center align-self-md-start mt-md-1 mr-3"
              >
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-screen-users fa-fw text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{
                    $t("activerecord.attributes.meeting.meeting_series_type")
                  }}
                </h5>

                <span>
                  {{
                    $t(
                      `models.meeting.meeting_series_type.${meeting.meeting_series_type}.other`
                    )
                  }}
                </span>
              </div>
            </div>

            <div class="media mb-4 mb-sm-3">
              <div
                class="media-icon align-self-center align-self-md-start mt-md-1 mr-3"
              >
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-fw fa-hashtag text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{ $t("activerecord.attributes.meeting.number") }}
                </h5>

                <span v-if="meeting.number">
                  {{ meeting.number }}
                </span>

                <span v-else> - </span>
              </div>
            </div>

            <div class="media mb-4 mb-sm-3">
              <div
                class="media-icon align-self-center align-self-md-start mt-md-1 mr-3"
              >
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-fw fa-t text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{ $t("activerecord.attributes.meeting.title") }}
                </h5>

                <span v-if="meeting.title">
                  {{ meeting.title }}
                </span>

                <span v-else> - </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="media mb-4 mb-sm-3">
              <div
                class="media-icon align-self-center align-self-md-start mt-md-1 mr-3"
              >
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-fw fa-calendar text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{ $t("components.meetings.info.date") }}
                </h5>

                <span v-if="meeting.start_at">
                  {{ $d(new Date(meeting.start_at), "date") }}
                </span>

                <span v-else> - </span>
              </div>
            </div>

            <div class="media mb-4 mb-sm-3">
              <div class="align-self-center align-self-md-start mt-md-1 mr-3">
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-fw fa-map-marker-alt text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{ $t("components.meetings.info.location") }}
                </h5>

                <span v-if="meeting.location">
                  {{ meeting.location }}
                </span>

                <span v-else> - </span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="media mb-4 mb-sm-3">
              <div class="align-self-center align-self-md-start mt-md-1 mr-3">
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-fw fa-pen-nib text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{ $t("activerecord.models.minutes.one") }}
                </h5>

                <document-link
                  v-if="meeting.minutes.external_document"
                  :document-id="meeting.minutes.external_document.id"
                  :filename="meeting.minutes.external_document.filename"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="media mb-4 mb-sm-3">
              <div class="align-self-center align-self-md-start mt-md-1 mr-3">
                <div class="media-icon">
                  <div class="h3">
                    <i class="fal fa-fw fa-gavel text-primary" />
                  </div>
                </div>
              </div>

              <div class="media-body">
                <h5 class="mb-0">
                  {{ $t("activerecord.models.decision.other") }}
                </h5>

                <span>
                  {{
                    $tc(
                      "components.meetings.historic.decisions_count",
                      meeting.minutes.items[0].decisions.length,
                      { count: meeting.minutes.items[0].decisions.length }
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="meeting.meeting_type === 'historic_draft'"
        class="card-footer text-right"
      >
        <be-button
          v-be-tooltip="{
            title: $t('components.meetings.historic.create_meeting_tooltip'),
            disabled: canCreateMeeting(meeting),
          }"
          variant="primary"
          :disabled="!canCreateMeeting(meeting)"
          @click="saveAndPublish(meeting)"
        >
          {{ $t("components.meetings.historic.create_meeting") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import HistoricMeetingEditor from "./HistoricMeetingEditor.vue";

export default {
  components: {
    HistoricMeetingEditor,
  },

  props: {
    meetings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      localMeetings: this.cloneDeep(this.meetings),
      documents: [],
      activeTab: "drafts",
    };
  },

  computed: {
    shownMeetings() {
      if (this.activeTab === "drafts") {
        return this.draftMeetings;
      } else {
        return this.historicMeetings;
      }
    },

    draftMeetings() {
      return this.localMeetings.filter(
        (meeting) => meeting.meeting_type === "historic_draft"
      );
    },

    historicMeetings() {
      return this.localMeetings.filter(
        (meeting) => meeting.meeting_type === "historic"
      );
    },

    tabsData() {
      return {
        drafts: {
          value: "drafts",
          label: this.$t("components.meetings.historic.drafts"),
          count: this.draftMeetings.length,
        },

        meetings: {
          value: "meetings",
          label: this.$t("models.meeting.historic_meeting.other"),
          count: this.historicMeetings.length,
        },
      };
    },
  },

  methods: {
    async createMeetings() {
      let lastMeetingId = null;

      this.documents.forEach(async (document) => {
        try {
          const { data } = await axios.post(this.url("/meetings/historics"), {
            meeting: {},
            document: document,
          });
          this.localMeetings.push(data);
          this.activeTab = "drafts";
          lastMeetingId = data.id;
        } catch (e) {
          this.handleError(e);
        } finally {
          if (this.documents.length === 1) {
            this.$nextTick(() => {
              this.$beModal.show(`historic-meeting-${lastMeetingId}`);
            });
          }
        }
      });

      this.documents = [];
    },

    canCreateMeeting(meeting) {
      return meeting.title && meeting.start_at && meeting.number;
    },

    updateMeeting(data) {
      const index = this.localMeetings.findIndex(
        (meeting) => meeting.id === data.id
      );

      if (index > -1) {
        this.localMeetings[index] = data;
      }
    },

    async removeMeeting(meeting) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: `${
            meeting.title
              ? meeting.title
              : meeting.minutes.external_document.filename
          }`,
        })
      );

      if (isConfirmed) {
        try {
          await axios.delete(this.url(`/meetings/historics/${meeting.id}`));

          const index = this.localMeetings.findIndex(
            (existing) => existing.id === meeting.id
          );

          if (index > -1) {
            this.localMeetings.splice(index, 1);
          }
        } catch (e) {
          this.handleError(e);
        }
      }
    },

    saveAndPublish(meeting) {
      this.saveMeeting({ ...meeting, meeting_type: "historic" });
    },

    async saveMeeting(meeting) {
      const isConfirmed = await this.promptConfirm({
        title: `${this.$t("components.meetings.historic.create_meeting")}?`,

        description: this.$t(
          "components.meetings.historic.create_meeting_confirm"
        ),

        confirmButtonText: this.$t("buttons.titles.create"),
      });

      if (isConfirmed) {
        try {
          const { data } = await axios.patch(
            this.url(`/meetings/historics/${meeting.id}`),
            {
              meeting: meeting,
            }
          );

          const index = this.localMeetings.findIndex(
            (meeting) => meeting.id === data.id
          );

          if (index > -1) {
            this.localMeetings[index] = data;
          }
        } catch (e) {
          if (e.response.status === 422) {
            const index = this.localMeetings.findIndex(
              (meeting) => meeting.id === e.response.data.id
            );

            if (index > -1) {
              this.localMeetings[index] = {
                ...e.response.data,
                meeting_type: "historic_draft",
              };
            }

            this.$beModal.show(`historic-meeting-${e.response.data.id}`);
          }
          this.handleError(e);
        }
      }
    },

    documentAdded(file) {
      this.documents.push(file);
    },

    documentRemoved(file) {
      const index = this.documents.findIndex(
        (existingDocument) => existingDocument === file
      );

      if (index > -1) {
        this.documents.splice(index, 1);
      }
    },
  },
};
</script>
