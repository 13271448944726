<template>
  <div class="accordion">
    <meeting-container
      v-for="meeting in meetings"
      :key="`meeting-${meeting.id}`"
      :meeting="meeting"
      :expanded="currentMeetingId == meeting.id"
      @toggle-expanded="toggleExpanded(meeting.id)"
    />
  </div>
</template>

<script>
import MeetingContainer from "./MeetingContainer.vue";

export default {
  components: {
    MeetingContainer,
  },

  props: {
    meetings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentMeetingId: undefined,
    };
  },

  computed: {
    firstMeeting: {
      get() {
        return this.meetings.length ? this.meetings[0] : null;
      },
    },
  },

  mounted() {
    this.currentMeetingId = this.firstMeeting.id;
  },

  methods: {
    toggleExpanded(meetingId) {
      if (meetingId != this.currentMeetingId) {
        this.currentMeetingId = meetingId;
      } else {
        this.currentMeetingId = undefined;
      }
    },
  },
};
</script>
