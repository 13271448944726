<template>
  <be-modal
    id="signatures-modal"
    :title="title"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    size="lg"
  >
    <div v-if="pendingSignatures.length > 0">
      <div class="accordion">
        <signature-row
          v-for="signature in sortedSignatures"
          :key="`signature-${signature.id}`"
          class="mt-2"
          :signature="signature"
        />
      </div>
    </div>

    <be-alert v-else variant="info" class="mb-0">
      {{ $t("components.metrics.signatures.empty") }}
    </be-alert>
  </be-modal>
</template>

<script>
import SignatureRow from "./SignatureRow.vue";

export default {
  components: {
    SignatureRow,
  },

  props: {
    pendingSignatures: {
      type: Array,
      required: true,
    },
  },

  computed: {
    title() {
      if (this.pendingSignatures.length > 1) {
        return this.$t("components.metrics.signatures.title_w_count", {
          count: this.pendingSignatures.length,
        });
      }

      return this.$t("components.metrics.signatures.title");
    },

    sortedSignatures() {
      return [...this.pendingSignatures].sort((a, b) =>
        a.date > b.date ? 1 : -1
      );
    },
  },
};
</script>
