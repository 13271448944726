<template>
  <div>
    <div class="card mb-2">
      <div
        class="card-header d-md-flex justify-content-between align-items-center"
      >
        <attendance-save-status class="mb-3 mb-md-0" text-class="small" />

        <be-button variant="primary" @click="$emit('update-page')">
          {{ $t("models.material.minutes.edit") }}
        </be-button>
      </div>

      <div class="card-body">
        <be-skeleton-table
          v-show="!hasLoaded"
          :rows="7"
          :columns="8"
          hide-footer
        />

        <div v-show="hasLoaded">
          <be-alert v-if="attendances.length === 0" variant="info">
            {{ $t("components.meetings.material.attendances.no_attendances") }}
          </be-alert>

          <template v-else>
            <be-alert v-if="hasWarnings" variant="warning">
              {{
                $t(
                  "components.meetings.material.attendances.requirements_warning"
                )
              }}
            </be-alert>

            <be-table
              :items="attendances"
              :fields="fields"
              :per-page="30"
              table-class="pt-3"
            >
              <template #avatar="{ item }">
                <user-avatar
                  :key="`attendance-avatar-${item.id}`"
                  :user="item.user_id || item.user_name || item.name"
                />
              </template>

              <template #name="{ item }">
                <div class="d-flex justify-content-between align-items-center">
                  <div v-if="item.user_id" class="d-block">
                    <div v-if="item.user_id">
                      {{ item.name }}
                    </div>

                    <small>
                      {{ getUserEmail(item.user_id) }}
                    </small>
                  </div>

                  <span v-else class="mr-3">
                    <be-form-group
                      :label="$t('activerecord.attributes.attendance.name')"
                      :label-for="inputId(item, 'name')"
                      label-sr-only
                      class="mb-0"
                    >
                      <be-form-input
                        :id="inputId(item, 'name')"
                        v-model="item.name"
                        lazy
                        @change="
                          removeAttendanceIfEmptyName(item) || update(item)
                        "
                      />
                    </be-form-group>
                  </span>

                  <be-link
                    v-if="item.notes"
                    v-be-tooltip="
                      $t('activerecord.attributes.attendance.notes')
                    "
                    @click="editAttendanceNotes(item)"
                  >
                    <i class="fal fa-user-edit fa-fw text-muted" />
                  </be-link>
                </div>
              </template>

              <template #present="{ item }">
                <be-form-group
                  :label="$t('activerecord.attributes.attendance.attending')"
                  :label-for="inputId(item, 'attending')"
                  label-sr-only
                  class="m-0"
                >
                  <be-form-select
                    :id="inputId(item, 'attending')"
                    v-model="item.attending"
                    :options="attendingOptions"
                    @change="update(item)"
                  />
                </be-form-group>
              </template>

              <template #reported_absence="{ item }">
                <be-form-group
                  v-be-tooltip="{
                    title: $t(
                      'components.meetings.material.attendances.cannot_be_absent'
                    ),
                    disabled: !disableAbsence(item),
                  }"
                  :label="
                    $t('activerecord.attributes.attendance.reported_absence')
                  "
                  :label-for="inputId(item, 'reported_absence')"
                  label-sr-only
                  class="m-0"
                >
                  <be-form-checkbox
                    v-model="item.reported_absence"
                    :disabled="!item.id || disableAbsence(item)"
                    size="lg"
                    @change="update(item)"
                  />
                </be-form-group>
              </template>

              <template #function="{ item }">
                <be-form-group
                  :label="$t('activerecord.attributes.attendance.function')"
                  :label-for="inputId(item, 'function')"
                  label-sr-only
                  class="m-0"
                >
                  <be-form-select
                    :id="inputId(item, 'function')"
                    v-model="item.function"
                    :options="attendanceFunctions"
                    :disabled="loading.chairman"
                    @change="updateFunction(item)"
                  />
                </be-form-group>
              </template>

              <template #secretary="{ item }">
                <be-spinner v-if="loading.secretary" />

                <be-form-group
                  v-else
                  :key="`${formState}-${item.id}-secretary`"
                  v-be-tooltip="{
                    title: secretaryTooltip(item),
                    disabled: !disableSecretary(item),
                  }"
                  :label="$t('activerecord.attributes.attendance.secretary')"
                  :label-for="inputId(item, 'secretary')"
                  label-sr-only
                  class="m-0"
                >
                  <be-form-radio
                    :id="inputId(item, 'secretary')"
                    v-model="item.secretary"
                    name="secretary"
                    :disabled="!item.id || disableSecretary(item)"
                    size="lg"
                    @change="updateSecretary(item)"
                  />
                </be-form-group>
              </template>

              <template #reviewer="{ item }">
                <be-form-group
                  v-be-tooltip="{
                    title: reviewerTooltip(item),
                    disabled: !disableReviewer(item),
                  }"
                  :label="$t('activerecord.attributes.attendance.reviewer')"
                  :label-for="inputId(item, 'reviewer')"
                  label-sr-only
                  class="m-0"
                >
                  <be-form-checkbox
                    :id="inputId(item, 'reviewer')"
                    v-model="item.reviewer"
                    size="lg"
                    :disabled="!item.id || disableReviewer(item)"
                    @change="updateReviewer(item)"
                  />
                </be-form-group>
              </template>

              <template #signatory="{ item }">
                <be-form-group
                  v-be-tooltip="{
                    title: disableSignatoryTitle(item),
                    disabled: !disableSignatory(item),
                  }"
                  :label="$t('activerecord.attributes.attendance.signatory')"
                  :label-for="inputId(item, 'signatory')"
                  label-sr-only
                  class="m-0"
                >
                  <be-form-checkbox
                    :id="inputId(item, 'signatory')"
                    v-model="item.signatory"
                    size="lg"
                    :disabled="!item.id || disableSignatory(item)"
                    @change="update(item)"
                  />
                </be-form-group>
              </template>

              <template #action="{ item }">
                <be-spinner v-if="item.id && loading[item.id]" />

                <be-dropdown v-else size="sm" ellipsis>
                  <be-dropdown-item @click="editAttendanceNotes(item)">
                    {{ $t("activerecord.attributes.attendance.notes") }}
                  </be-dropdown-item>

                  <be-dropdown-divider />

                  <be-dropdown-item
                    variant="danger"
                    @click="
                      removeAttendance({
                        attendance: item,
                        meeting: meeting,
                      })
                    "
                  >
                    {{ $t("buttons.titles.remove") }}
                  </be-dropdown-item>
                </be-dropdown>
              </template>
            </be-table>
          </template>

          <be-modal
            :id="inputId(null, 'notes')"
            :title="attendanceToEdit ? attendanceToEdit.name : ''"
            :ok-title="$t('buttons.titles.save')"
            @ok="submitAttendanceNotes"
            @cancel="cancelAttendanceNotes"
          >
            <be-form-textarea
              v-model="attendanceNotes"
              rows="3"
              max-rows="10"
            />
          </be-modal>

          <be-modal
            id="add-new"
            ref="add-new"
            size="sm"
            :title="
              $t('components.meetings.material.attendances.add_new_title')
            "
            :ok-title="$t('buttons.titles.add_more')"
            @ok="handleAddNonUserAttendance"
          >
            <be-form-group
              label-for="name"
              :label="$t('activerecord.attributes.attendance.name')"
              :error="getErrors(localNewAttendance, ['name'])"
            >
              <be-form-input
                id="name"
                v-model="localNewAttendance.name"
                required
                @change="clearErrors(localNewAttendance, ['name'])"
                @keyup.enter="handleAddNonUserAttendance"
              />
            </be-form-group>
          </be-modal>

          <be-dropdown v-if="pendingAttendances.length > 0">
            <template #button-content>
              {{ $t("components.meetings.material.attendances.add_more") }}
            </template>

            <template v-for="attendance in pendingAttendances">
              <be-dropdown-item
                v-if="attendance.name"
                :key="attendance.user_id"
                @click="handleAdd(attendance)"
              >
                {{ attendance.name }}
              </be-dropdown-item>
            </template>

            <be-dropdown-divider v-if="pendingAttendances.length > 0" />

            <be-dropdown-item
              variant="outline-secondary"
              size="sm"
              @click="showAddNewAttendance()"
            >
              {{ $t("components.meetings.material.attendances.add_attendee") }}
            </be-dropdown-item>
          </be-dropdown>

          <be-button
            v-else
            variant="outline-secondary"
            @click="showAddNewAttendance()"
          >
            {{ $t("components.meetings.material.attendances.add_more") }}
          </be-button>
        </div>
      </div>

      <material-editor-footer
        current-page="attendances"
        @update-page="$emit('update-page')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import wrap from "lodash/wrap";
import memoize from "lodash/memoize";

import AttendanceSaveStatus from "./AttendanceSaveStatus.vue";
import MaterialEditorFooter from "./MaterialEditorFooter.vue";

export default {
  components: {
    AttendanceSaveStatus,
    MaterialEditorFooter,
  },

  emits: ["update-page"],

  data() {
    return {
      attendingOptions: [
        {
          value: "attending_irl",
          text: this.$t("models.attendance.attending_options.attending_irl"),
        },
        {
          value: "attending_online",
          text: this.$t("models.attendance.attending_options.attending_online"),
        },
        {
          value: "absent",
          text: this.$t("models.attendance.attending_options.absent"),
        },
      ],

      localNewAttendance: {},
      attendanceNotes: null,
      attendanceToEdit: null,

      debouncedUpdateAttendance: this.debounceByParam(
        this.updateAttendance,
        ({ attendance }) => attendance.id,
        800
      ),

      formState: this.generateUuid(),
    };
  },

  computed: {
    ...mapGetters("attendances", [
      "attendances",
      "newAttendance",
      "loading",
      "pendingAttendances",
      "hasWarnings",
      "hasLoaded",
    ]),

    ...mapGetters({
      meeting: "material/meeting",
    }),

    attendanceFunctions() {
      return this.$config.ATTENDANCE_FUNCTIONS.map((attendanceFunction) => {
        return {
          value: attendanceFunction,

          text: this.$i18n.t(
            `models.attendance.functions.${attendanceFunction}`
          ),
        };
      });
    },

    fields() {
      return [
        { key: "avatar", label: "", class: "col-shrink text-center" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "present",
          label: this.translateAttribute("attendance", "present"),
        },
        {
          key: "reported_absence",
          label: this.translateAttribute("attendance", "reported_absence"),
          class: "col-shrink rotate-45deg",
        },
        {
          key: "function",
          label: this.translateAttribute("membership", "function"),
        },
        {
          key: "secretary",
          label: this.translateAttribute("attendance", "secretary"),
          class: "col-shrink rotate-45deg",
        },
        {
          key: "reviewer",
          label: this.translateAttribute("attendance", "reviewer"),
          class: "col-shrink rotate-45deg",
        },
        {
          key: "signatory",
          label: this.translateAttribute("attendance", "signatory"),
          class: "col-shrink rotate-45deg",
        },
        { key: "action", label: "", class: "col-shrink text-center" },
      ];
    },
  },

  methods: {
    ...mapActions("attendances", [
      "addAttendance",
      "updateAttendance",
      "loadNewAttendance",
      "removeAttendance",
      "setLoading",
      "setNotLoading",
    ]),

    async update(attendance, lock) {
      if (!attendance.id) {
        return;
      }

      if (lock) {
        this.setLoading(lock);
      }

      this.setLoading(attendance.id);
      this.debouncedUpdateAttendance({ attendance, meeting: this.meeting });
    },

    async updateSecretary(attendance) {
      if (
        attendance.secretary &&
        this.$currentMembership.policy_level !== "admin"
      ) {
        const isConfirmed = await this.promptConfirm(
          this.$t(
            "components.meetings.material.attendances.confirm_secretary_change_for_limited"
          )
        );

        if (!isConfirmed) {
          attendance.secretary = false;
          this.formState = this.generateUuid(); //Trigger radio buttons reset
          return;
        }
      }

      attendance.reported_absence = false;
      attendance.signatory = true;
      this.update(attendance, "secretary");
    },

    async updateReviewer(attendance) {
      if (attendance.reviewer) {
        attendance.reported_absence = false;
        attendance.signatory = true;
      }

      this.update(attendance);
    },

    // Handle disabling all function fields if chairman is selected
    updateFunction(attendance) {
      let lock = null;

      if (attendance.function === "chairman") {
        lock = "chairman";
        attendance.signatory = true;
      }

      this.update(attendance, lock);
    },

    async handleAdd(attendance) {
      this.addAttendance({ attendance, meeting: this.meeting });
    },

    async handleAddNonUserAttendance(event) {
      event.preventDefault();
      try {
        await this.addAttendance({
          attendance: this.localNewAttendance,
          meeting: this.meeting,
        });

        this.resetNewAttendance();
        this.$beModal.hide("add-new");
      } catch (error) {
        if (error?.response?.status === 422) {
          this.localNewAttendance = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    inputId(attendance, field) {
      let identifier = "local";

      if (attendance) {
        if (attendance.id) {
          identifier = attendance.id;
        } else if (attendance.user_id) {
          identifier = `user-${attendance.user_id}`;
        }
      }

      return `attendance-${identifier}-${field}`;
    },

    disableAbsence(attendance) {
      // Always allowed to uncheck reported absence
      if (attendance.reported_absence) {
        return false;
      }

      return attendance.secretary || attendance.reviewer;
    },

    disableSecretary(attendance) {
      return !attendance.user_id || attendance.reviewer;
    },

    disableSignatory(attendance) {
      if (!attendance.user_id) return true;

      // Do not disable the checkbox until the attendance is signatory
      // This allows someone already marked as secretary or reviewer to be
      // marked as signatory but then they cannot unmark it.
      return (
        attendance.signatory && (attendance.secretary || attendance.reviewer)
      );
    },

    disableSignatoryTitle(attendance) {
      if (attendance.user_id) {
        if (attendance.secretary) {
          return this.$t(
            "components.meetings.material.attendances.cannot_change_signatory_for_secretary"
          );
        } else if (attendance.reviewer) {
          return this.$t(
            "components.meetings.material.attendances.cannot_change_for_reviewer"
          );
        }
      } else {
        return this.$t(
          "components.meetings.material.attendances.cannot_be_done_without_user"
        );
      }
    },

    disableReviewer(attendance) {
      return !attendance.user_id || attendance.secretary;
    },

    editAttendanceNotes(attendance) {
      this.attendanceToEdit = attendance;
      this.attendanceNotes = attendance.notes;
      this.$beModal.show(this.inputId(null, "notes"));
    },

    async submitAttendanceNotes() {
      this.attendanceToEdit.notes = this.attendanceNotes;
      await this.update(this.attendanceToEdit);

      this.cancelAttendanceNotes();
    },

    cancelAttendanceNotes() {
      this.attendanceNotes = null;
      this.attendanceToEdit = null;
      this.$beModal.hide(this.inputId(null, "notes"));
    },

    async showAddNewAttendance() {
      await this.loadNewAttendance(this.meeting);
      this.localNewAttendance = cloneDeep(this.newAttendance);
      this.$beModal.show("add-new");
    },

    secretaryTooltip(attendance) {
      if (!attendance.user_id) {
        return this.$t(
          "components.meetings.material.attendances.cannot_be_done_without_user"
        );
      } else if (attendance.reviewer) {
        return this.$t(
          "components.meetings.material.attendances.cannot_change_secretary_for_reviewer"
        );
      }
      return null;
    },

    reviewerTooltip(attendance) {
      if (!attendance.user_id) {
        return this.$t(
          "components.meetings.material.attendances.cannot_be_done_without_user"
        );
      } else if (attendance.secretary) {
        return this.$t(
          "components.meetings.material.attendances.cannot_change_for_secretary"
        );
      }
      return null;
    },

    removeAttendanceIfEmptyName(attendance) {
      if (attendance.name.length == 0) {
        this.removeAttendance({ attendance, meeting: this.meeting });
        return true;
      }
      return false;
    },

    resetNewAttendance() {
      this.localNewAttendance = {};
    },

    debounceByParam: (targetFunc, resolver, ...debounceParams) =>
      wrap(
        memoize(() => debounce(targetFunc, ...debounceParams), resolver),
        (getMemoizedFunc, ...params) => getMemoizedFunc(...params)(...params)
      ),
  },
};
</script>
