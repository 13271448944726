<template>
  <div>
    <be-form-checkbox
      v-if="!skipCheckbox"
      id="inquiry-payout"
      v-model="inquiry_payout"
      name="inquiry-payout"
      class="mb-4"
    >
      {{ $t("views.companies.inquiries.create.inquiry_payout") }}
    </be-form-checkbox>

    <transaction-types v-if="inquiry_payout || skipCheckbox" />

    <transaction-instructions v-if="inquiry_payout || skipCheckbox" />

    <be-form-group
      :label="$t('views.companies.inquiries.create.receiver')"
      label-for="receiver"
      :description="$t('views.companies.inquiries.create.receiver_description')"
      :error="getErrors(form.payment_plan[0], 'inquiry_receiver.name')"
    >
      <be-form-input
        id="receiver"
        v-model="form.payment_plan[0].inquiry_receiver.name"
        @input="debouncedValidation"
      />
    </be-form-group>

    <be-form-group
      :label="$t('views.companies.inquiries.create.receiver_identifier')"
      label-for="receiver-identifier"
      :error="
        getErrors(form.payment_plan[0], 'inquiry_receiver.receiver_identifier')
      "
      required
    >
      <be-form-input
        id="receiver-identifier"
        v-model="form.payment_plan[0].inquiry_receiver.receiver_identifier"
        required
        @input="debouncedValidation"
      />
    </be-form-group>

    <payment-planner
      v-if="form.inquiry.inquiry_id && (inquiry_payout || skipCheckbox)"
      single-payment="singlePayment"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import TransactionTypes from "@/components/inquiry_payments/components/transactions/TransactionTypes.vue";
import TransactionInstructions from "@/components/inquiry_payments/components/transactions/TransactionInstructions.vue";
import PaymentPlanner from "@/components/inquiry_payments/components/PaymentPlanner.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    TransactionTypes,
    TransactionInstructions,
    PaymentPlanner,
  },

  mixins: [CreateInquiryMixin],

  props: {
    singlePayment: {
      type: Boolean,
      default: false,
    },

    skipCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inquiry_payout: false,
    };
  },

  methods: {
    ...mapActions("inquiries", ["validateInquiryPayment"]),

    async handleValidateInquiryPayment() {
      this.clearStates();

      const inquiryPayment = {
        inquiry_id: this.form.inquiry.inquiry_id,
        ...this.form.payment_plan[0],
        id: this.form.id,
        skip_validation_field: this.skipValidationField,
      };

      await this.validateInquiryPayment(inquiryPayment);
    },

    clearStates() {
      this.clearErrors(
        this.form.payment_plan[0],
        "inquiry_receiver.receiver_identifier"
      );
    },

    debouncedValidation: debounce(function () {
      this.handleValidateInquiryPayment();
    }, 1000),
  },
};
</script>
