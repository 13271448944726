<template>
  <section>
    <div
      v-if="connectedToSupport && canAdministrateMemberships"
      class="card mb-2"
    >
      <div class="card-body">
        <be-alert variant="info">
          {{
            $t(
              "components.memberships.support_access.support_access_activated_description_w_dates",
              {
                start_date: $d(new Date(connectionCreatedAt), "date"),
                end_date: $d(new Date(connectionExpiresAt), "date"),
              }
            )
          }}

          <be-link @click="disconnectFromSupport">
            {{
              $t("components.memberships.support_access.remove_support_access")
            }}?
          </be-link>
        </be-alert>
      </div>
    </div>

    <div v-if="canAdministrateMemberships" class="card mb-2 d-lg-none">
      <div
        class="card-header border-bottom-0 rounded d-md-flex justify-content-end"
      >
        <membership-invitations
          modal-id="new-membership-mobile"
          @updated="membershipAdd"
        />

        <be-dropdown
          v-if="platformEnabled('support_management')"
          id="header-actions-overflow"
          ellipsis
        >
          <be-dropdown-item
            v-if="!connectedToSupport"
            id="connect-to-support"
            v-be-modal="'confirm-allow-support-to-administrate-organisation'"
          >
            {{
              $t(
                "components.memberships.support_access.allow_support_access.title"
              )
            }}
          </be-dropdown-item>

          <be-dropdown-item
            v-if="connectedToSupport"
            variant="danger"
            @click.prevent="disconnectFromSupport"
          >
            {{
              $t("components.memberships.support_access.remove_support_access")
            }}
          </be-dropdown-item>
        </be-dropdown>
      </div>
    </div>

    <div
      v-if="
        inactiveMembershipsOrSupportManagementConnections ||
        canAdministrateMemberships
      "
      class="mb-2 card"
      :class="{
        'd-none d-lg-block': inactiveMembershipsOrSupportManagementConnections,
      }"
    >
      <div
        class="d-lg-flex align-items-center card-header border-bottom-0"
        :class="
          inactiveMembershipsOrSupportManagementConnections
            ? 'justify-content-between py-lg-0'
            : 'justify-content-end'
        "
      >
        <be-nav
          v-if="inactiveMembershipsOrSupportManagementConnections"
          tabs
          class="mt-auto d-md-flex"
        >
          <be-nav-item
            :active="activeTab == 'active'"
            @click="activeTab = 'active'"
          >
            {{ $t("components.memberships.tabs.users") }}
            <be-badge variant="light" pill>
              {{ memberships.length }}
            </be-badge>
          </be-nav-item>

          <be-nav-item
            :active="activeTab == 'inactive'"
            @click="activeTab = 'inactive'"
          >
            {{ $t("components.memberships.tabs.previous_users") }}
            <be-badge variant="light" pill>
              {{ inactiveMemberships.length }}
            </be-badge>
          </be-nav-item>
        </be-nav>

        <membership-invitations
          v-if="canAdministrateMemberships"
          class="d-none d-lg-block ml-auto"
          @updated="membershipAdd"
        />

        <be-dropdown
          v-if="
            platformEnabled('support_management') && canAdministrateMemberships
          "
          id="header-actions-overflow"
          ellipsis
        >
          <be-dropdown-item
            v-if="!connectedToSupport"
            id="connect-to-support"
            v-be-modal="'confirm-allow-support-to-administrate-organisation'"
          >
            {{
              $t(
                "components.memberships.support_access.allow_support_access.title"
              )
            }}
          </be-dropdown-item>

          <be-dropdown-item
            v-if="connectedToSupport"
            variant="danger"
            @click.prevent="disconnectFromSupport"
          >
            {{
              $t("components.memberships.support_access.remove_support_access")
            }}
          </be-dropdown-item>
        </be-dropdown>
      </div>
    </div>

    <div class="mb-2 card">
      <div class="card-body">
        <be-alert
          v-if="
            platformAdmin &&
            !$platform.features.user_administration &&
            hasOriginOptions
          "
          variant="info"
        >
          {{ $t("components.memberships.handled_by_integration_only_origins") }}
        </be-alert>

        <be-alert
          v-else-if="platformAdmin && !$platform.features.user_administration"
          variant="info"
        >
          {{ $t("components.memberships.handled_by_integration") }}
        </be-alert>

        <p
          v-if="
            activeTab == 'active' &&
            subscriptionPlan &&
            subscriptionPlan.user_limit &&
            $platform.features.user_administration
          "
          class="text-muted"
        >
          {{
            $tc(
              "components.memberships.form.current_usage",
              currentMemberships.length,
              {
                count: currentMemberships.length,
                max_usage: subscriptionPlan.user_limit,
              }
            )
          }}
        </p>

        <membership-table
          v-if="activeTab == 'active'"
          :key="activeTab"
          :memberships="currentMemberships"
          :no-pagination="activeTab == 'active'"
          :fields="fields"
          @destroy="handleMembershipDestroy"
          @edit="membershipEdit"
          @reactivate="membershipReactivate"
          @reinvite="membershipReinvite"
        />

        <historic-memberships-and-support-managements-table
          v-if="activeTab == 'inactive'"
          :memberships="currentMemberships"
          :support-management-connections="supportManagementConnections"
        />
      </div>

      <membership-edit
        v-if="editMembership != null"
        :membership="editMembership"
        modal-id="edit-membership"
        @submit="membershipUpdate"
        @close="editModalClosed"
      />

      <confirm-membership-removal-modal
        v-if="removeMembership != null"
        :membership="removeMembership"
        @confirmed="membershipDestroy"
        @close="removeModalClosed"
      />
    </div>

    <div
      v-if="activeTab == 'active' && adminCompanies.length > 0"
      class="card mb-2"
    >
      <div class="card-header">
        <h5 class="card-title">
          {{ $t("components.memberships.client_managers") }}
        </h5>
      </div>

      <div class="card-body">
        <admin-companies-table
          key="admin-panels-table"
          :admin-companies="adminCompanies"
        />
      </div>
    </div>

    <confirm-modal
      id="confirm-allow-support-to-administrate-organisation"
      :title="
        $t('components.memberships.support_access.allow_support_access.title')
      "
      :ok-title="$t('buttons.titles.activate')"
      :valid-input-text="company.kebab_case_title"
      confirm-with-input
      @confirm="allowSupportToAdministrateOrganisation"
    >
      <p
        v-for="(line, index) in $t(
          'components.memberships.support_access.allow_support_access.description'
        ).split('\n')"
        :key="index"
      >
        {{ line }}
      </p>

      <be-alert variant="warning">
        {{
          $t(
            "components.memberships.support_access.allow_support_access.warning_w_title",
            {
              title: company.title,
            }
          )
        }}
      </be-alert>
    </confirm-modal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import MembershipEdit from "./MembershipEdit.vue";
import MembershipInvitations from "./MembershipInvitations.vue";
import MembershipTable from "./MembershipTable.vue";
import AdminCompaniesTable from "./AdminCompaniesTable.vue";
import ConfirmMembershipRemovalModal from "./ConfirmMembershipRemovalModal.vue";
import HistoricMembershipsAndSupportManagementsTable from "./HistoricMembershipsAndSupportManagementsTable.vue";

export default {
  components: {
    AdminCompaniesTable,
    ConfirmMembershipRemovalModal,
    MembershipEdit,
    MembershipInvitations,
    MembershipTable,
    HistoricMembershipsAndSupportManagementsTable,
  },

  provide() {
    return {
      originOptions: this.originOptions,
    };
  },

  props: {
    initialMemberships: {
      type: Array,
      required: true,
    },

    adminCompanies: {
      type: Array,
      required: true,
    },

    connectedToSupport: {
      type: Boolean,
      required: false,
      default: false,
    },

    connectionCreatedAt: {
      type: String,
      required: false,
      default: null,
    },

    connectionExpiresAt: {
      type: String,
      required: false,
      default: null,
    },

    supportManagementConnections: {
      type: Array,
      required: false,
      default: () => [],
    },

    originOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: "active",

      activeFields: [
        "action",
        "avatar",
        "name",
        "email",
        "function",
        "role",
        "title",
        "policy_level",
        "options",
      ],

      inactiveFields: [
        "avatar",
        "name",
        "email",
        "function",
        "title",
        "policy_level",
        "created_at",
        "deleted_at",
      ],

      editMembership: null,
      removeMembership: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "current_user/getUser",
      company: "company/getCompany",
      memberships: "memberships/getMemberships",
      inactiveMemberships: "memberships/getInactiveMemberships",
    }),

    hasOriginOptions() {
      return (
        this.flipperFlag("origins_in_membership_form") &&
        this.originOptions.length > 0
      );
    },

    inactiveMembershipsOrSupportManagementConnections() {
      return (
        this.supportManagementConnections.length > 0 ||
        this.inactiveMemberships.length > 0
      );
    },

    currentMemberships() {
      if (this.activeTab === "active") {
        return this.memberships;
      } else {
        return this.inactiveMemberships;
      }
    },

    fields() {
      if (this.activeTab === "active") {
        return this.activeFields;
      } else {
        return this.inactiveFields;
      }
    },

    subscriptionPlan() {
      return this.company.subscriptions.find((subscription) => {
        return (
          subscription.subscribed_type == "Plan" && subscription.current == true
        );
      });
    },

    canAdministrateMemberships() {
      return this.$platform.features.user_administration && this.platformAdmin;
    },
  },

  watch: {
    inactiveMemberships: {
      handler(value) {
        if (value.length === 0) {
          this.activeTab = "active";
        }
      },

      deep: true,
    },
  },

  mounted() {
    this.$store.commit("memberships/setMemberships", this.initialMemberships);
  },

  methods: {
    async allowSupportToAdministrateOrganisation() {
      try {
        await axios.post(
          this.url(`/support_administration`, {
            company: this.company,
          })
        );
        window.location.reload();
      } catch (e) {
        this.handleError(e);
      }
    },

    async disconnectFromSupport() {
      const isConfirmed = await this.promptRemovalConfirm({
        title: `${this.$t(
          "components.memberships.support_access.remove_support_access"
        )}?`,

        description: this.$t(
          "components.memberships.support_access.remove_support_access_description"
        ),
      });

      if (isConfirmed) {
        try {
          await axios.delete(
            this.url(`/support_administration`, {
              company: this.company,
            })
          );
          window.location.reload();
        } catch (e) {
          this.handleError(e);
        }
      }
    },

    async handleMembershipDestroy(membership) {
      this.removeMembership = membership;

      this.$nextTick(() => {
        this.$beModal.show("confirm-membership-removal-modal");
      });
    },

    async membershipDestroy(membership) {
      try {
        await this.$store.dispatch("memberships/destroyMembership", membership);

        if (membership.user.id === this.$currentUser.id) {
          window.location = "/home/dashboard";
        } else {
          await this.loadAllMemberships();
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    async membershipReinvite(membership) {
      try {
        await this.$store.dispatch(
          "memberships/reinviteMembership",
          membership
        );
      } catch (error) {
        this.handleError(error);
      }
    },

    async membershipReactivate(membership) {
      try {
        await this.$store.dispatch(
          "memberships/reactivateMembership",
          membership
        );
      } catch (error) {
        this.handleError(error);
      }
    },

    membershipEdit(membership) {
      this.editMembership = membership;
      this.$nextTick(() => {
        this.$beModal.show("edit-membership");
      });
    },

    async membershipAdd() {
      await this.loadAllMemberships();
    },

    async membershipUpdate(membership) {
      try {
        await this.$store.dispatch("memberships/updateMembership", membership);

        await this.loadAllMemberships();

        this.editModalClosed();
      } catch (error) {
        if (error.response.status === 422) {
          this.editMembership = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    editModalClosed() {
      this.editMembership = null;
    },

    removeModalClosed() {
      this.removeMembership = null;
    },

    async loadAllMemberships() {
      await this.$store.dispatch("memberships/fetchMemberships");
    },
  },
};
</script>
