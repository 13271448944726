<template>
  <div>
    <unlock-status
      v-if="
        meeting.process.minutes_unlocking_in_progress &&
        minutesStatus !== 'loading'
      "
      :meeting="meeting"
      :minutes="minutes"
      class="mb-4"
      @unlocked="onMinutesUnlock"
    />

    <be-skeleton-table
      v-else-if="
        meeting.process.minutes_unlocking_in_progress &&
        minutesStatus === 'loading'
      "
      :rows="3"
      :columns="2"
    />

    <div class="row">
      <div class="col-12 col-xl-6 mb-4">
        <h3 class="mb-3">{{ $t("components.meetings.tabs.sign.title") }}</h3>

        <meeting-status
          v-if="meeting.process.active_state === 'sign'"
          :meeting="meeting"
        />

        <tab-activity
          :owner="minutes"
          :accepted-keys="[
            'material.signed',
            'material.uploaded_signed_minutes',
          ]"
        />

        <div v-if="canUploadSigned" class="my-4">
          <p>
            {{ $t("components.meetings.tabs.sign.description") }}
          </p>

          <be-form-group
            :label="
              $t('components.meetings.tabs.sign.e_signature.upload.document')
            "
            :label-for="`document-uploader-${meeting.id}`"
          >
            <document-uploader
              :id="`document-uploader-${meeting.id}`"
              :documents="uploadedDocuments"
              :max-files="1"
              :accepted-types="['pdf']"
              :manual-save="false"
              support-select-from-archive
              @document-added="documentAdded"
              @document-removed="documentRemoved"
              @uploading="(value) => (loading = loading || value)"
            />
          </be-form-group>
        </div>

        <be-button
          v-if="canShow"
          :href="`${meeting.paths.base}/minutes`"
          variant="outline-primary"
          :disabled="loading"
        >
          {{ $t("components.meetings.tabs.sign.minutes") }}
        </be-button>

        <be-button
          v-if="canUploadSigned"
          :variant="
            uploadedMinutes[0] && uploadedMinutes[0].uploaded_by_id
              ? 'primary'
              : 'outline-secondary'
          "
          :disabled="loading"
          icon="fa-envelope"
          @click="$beModal.show(`confirm-manually-signed-${meeting.id}`)"
        >
          {{ $t("components.meetings.tabs.sign.mark_as_signed") }}
        </be-button>
      </div>

      <div class="col-12 col-xl-6">
        <template v-if="!meeting.is_archived">
          <div v-if="hasActiveFeature('e-signature')">
            <div class="d-flex justify-content-between align-items-center">
              <h3>
                {{ $t("components.meetings.tabs.sign.e_signature.title") }}
              </h3>

              <be-dropdown
                v-if="
                  meeting.policy.update &&
                  minutes.external_uploaded_at &&
                  minutes.signature_requested_at
                "
                size="sm"
                ellipsis
              >
                <be-dropdown-item variant="danger" @click="cancelESignature">
                  {{ $t("components.meetings.tabs.sign.cancel_signing") }}
                </be-dropdown-item>
              </be-dropdown>
            </div>

            <div class="my-4">
              <p>
                {{
                  $t("components.meetings.tabs.sign.e_signature.description")
                }}
              </p>
            </div>
          </div>

          <div v-else-if="platformEnabled('shop')">
            <h3>
              {{ $t("components.meetings.tabs.sign.e_signature.title") }}
            </h3>

            <div class="mb-3">
              {{
                $t(
                  "components.meetings.tabs.sign.e_signature.activate_e_signature"
                )
              }}
            </div>

            <be-button variant="outline-primary" :href="url('/shop')">
              {{ $t("application.shop") }}
            </be-button>
          </div>

          <be-alert v-if="hasRejectedSignatures" variant="warning">
            {{
              $t(
                "components.meetings.tabs.sign.e_signature.rejected_description"
              )
            }}
          </be-alert>

          <meeting-signatures-table
            v-if="hasActiveFeature('e-signature')"
            :initial-loading="initialLoading"
            :attendances="signatoryAttendances"
            :meeting="{ ...meeting, minutes }"
            compact
          />
        </template>
      </div>
    </div>

    <confirm-modal
      :id="`confirm-manually-signed-${meeting.id}`"
      :title="
        $t('components.meetings.manually_sign_minutes_confirm_modal.title')
      "
      :ok-title="
        $t(
          'components.meetings.manually_sign_minutes_confirm_modal.mark_as_signed'
        )
      "
      :valid-input-text="manualSignConfirmInputText"
      confirm-with-input
      @confirm="markAsSigned"
    >
      <template v-if="notSignedAttendances.length > 0">
        <p>
          {{
            $t(
              "components.meetings.manually_sign_minutes_confirm_modal.description_unsigned"
            )
          }}
        </p>

        <table class="table table-hover">
          <thead>
            <tr>
              <th class="col-shrink"></th>

              <th>
                {{ $t("components.meetings.tabs.sign.e_signature.table.name") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.attendance.function") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="attendance in notSignedAttendances"
              :key="`attendance-${attendance.id}`"
            >
              <td>
                <user-avatar :user="attendance.user_id" />
              </td>

              <td>{{ attendance.name }}</td>

              <td>
                {{ $t(`models.attendance.functions.${attendance.function}`) }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <p v-else>
        {{
          $t(
            "components.meetings.manually_sign_minutes_confirm_modal.description_signed"
          )
        }}
      </p>

      <be-alert variant="warning">
        {{
          $t("components.meetings.manually_sign_minutes_confirm_modal.warning")
        }}
      </be-alert>
    </confirm-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import RequestHandler from "@/mixins/RequestHandler";
import MaterialMixin from "@/mixins/meetings/material";

import TabActivity from "./TabActivity.vue";
import MeetingSignaturesTable from "../MeetingSignaturesTable.vue";

export default {
  components: {
    MeetingSignaturesTable,
    TabActivity,
  },

  mixins: [MaterialMixin, RequestHandler],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  emits: ["change-tab"],

  data() {
    return {
      loading: false,
      initialLoading: true,
      attendances: [],
      attendancesReminded: [],
      minutesGotUnlocked: false,
    };
  },

  computed: {
    ...mapGetters({
      hasActiveFeature: "company/hasActiveFeature",
    }),

    signatoryAttendances() {
      return this.attendances.filter((attendance) => attendance.signatory);
    },

    notSignedAttendances() {
      return this.signatoryAttendances.filter(
        (attendance) => !attendance.signed_at && !attendance.rejected_at
      );
    },

    hasRejectedSignatures() {
      return this.signatoryAttendances.some(
        (signatory) => signatory.rejected_at
      );
    },

    uploadedDocuments() {
      // temp fix for multiple files
      const files = this.uploadedMinutes.filter(
        (file) =>
          (file.default_file && file.uploaded_by_id) ||
          file.default_file?.upload_state == "added"
      );

      return [files.length > 0 ? files[files.length - 1] : {}];
    },

    minutes() {
      return this.getMinutes(this.meeting);
    },

    minutesStatus() {
      return this.getMinutesStatus(this.meeting);
    },

    canShow() {
      return this.minutes?.policy?.show;
    },

    canUpdate() {
      return this.minutes?.policy?.update;
    },

    canUploadSigned() {
      return (
        this.canShow &&
        !this.meeting.is_archived &&
        !this.minutes.external_uploaded_at
      );
    },

    uploadedMinutes() {
      return this.minutes?.archived_documents || [];
    },

    manualSignConfirmInputText() {
      return `${this.$t("activerecord.models.meeting.one").toLowerCase()}-${
        this.meeting.number
      }`;
    },
  },

  async mounted() {
    this.fetchMinutesForMeeting(this.meeting);
    const response = await axios.get(`${this.meeting.paths.base}/attendances`);
    if (response.status === 200) {
      this.attendances = response.data;
      this.initialLoading = false;
    }
  },

  methods: {
    ...mapMutations({
      updateMeeting: "meetings/updateMeeting",
    }),

    async markAsSigned() {
      try {
        this.loading = true;

        const { data } = await axios.post(
          `${this.meeting.paths.base}/minutes/manual_signature`
        );

        this.updateMeeting(data);
        this.updateMeetingsCount();
        this.$emit("change-tab", "archived");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async documentAdded(doc) {
      try {
        this.$store.commit("meetings/updateMinutesDocument", {
          minutes: this.minutes,
          document: doc,
        });
        this.loading = true;
        const response = await this.makeRequest(
          {
            method: "POST",
            url: `${this.meeting.paths.base}/minutes/signed_upload`,

            data: {
              document: doc,
            },
          },
          { confirm: false }
        );

        this.$store.commit("meetings/updateMinutes", response.data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async documentRemoved(doc) {
      this.loading = true;

      try {
        // Update state that it will be removed
        this.$store.commit("meetings/updateMinutesDocument", {
          minutes: this.minutes,
          document: doc,
        });

        const response = await this.makeRequest(
          {
            method: "DELETE",
            url: `${this.meeting.paths.base}/minutes/signed_upload`,
          },
          { confirm: false }
        );
        this.$store.commit("meetings/updateMinutes", response.data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    onMinutesUnlock() {
      // this is called when a unlock response from the user completes the minutes unlock request
      this.$store.dispatch("meetings/reloadMeeting", this.meeting.id);
    },

    updateMeetingsCount() {
      // the badge counters are outside vue
      const activeEl = document.getElementById("active-meetings-count");
      const archiveEl = document.getElementById("archived-meetings-count");

      if (activeEl) {
        activeEl.innerText = `${Number.parseInt(activeEl.innerText) - 1}`;
      }

      if (archiveEl) {
        archiveEl.innerText = `${Number.parseInt(archiveEl.innerText) + 1}`;
      }
    },

    async cancelESignature() {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t(
          "components.meetings.tabs.sign.cancel_signing"
        ),

        cancelButtonText: this.$t("buttons.titles.keep"),
        title: this.$t("components.meetings.tabs.sign.confirm_cancel_signing"),

        text: this.$t(
          "components.meetings.tabs.sign.confirm_cancel_signing_text"
        ),
      });

      if (isConfirmed) {
        try {
          this.loading = true;
          await axios.delete(`${this.meeting.paths.base}/minutes/approval`);
          window.location.assign(`${this.meeting.paths.base}/minutes`);
        } catch (error) {
          this.handleError(error);
          this.loading = false;
        }
      }
    },
  },
};
</script>
