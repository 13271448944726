<template>
  <be-skeleton-table
    v-if="loading"
    :rows="3"
    :columns="1"
    :table-props="{ hover: true }"
  />

  <template v-else>
    <be-table :items="releaseNotes" :fields="fields">
      <template #published_at="{ item }">
        <div class="d-flex align-items-center">
          <a
            :href="link(item)"
            target="_blank"
            rel="noopener noreferrer"
            @click="onClick(item.id)"
          >
            {{ $d(new Date(item.published_at), "date") }}

            <i class="fal fa-external-link small ml-1" />
          </a>

          <small v-if="!item.read" class="text-muted font-italic ml-auto">
            {{ $t("components.release_notes.unread") }}
          </small>
        </div>
      </template>

      <template #categories="{ item }">
        <div class="d-flex gap-1">
          <be-badge
            v-for="category in item.categories"
            :key="category.id"
            variant="primary"
            class="p-1"
          >
            {{
              $t(
                `activerecord.attributes.release_note_category.names.${category}`
              )
            }}
          </be-badge>
        </div>
      </template>
    </be-table>

    <div v-if="unreadReleaseNotes.length > 0" class="mt-2">
      <be-button
        variant="outline-secondary"
        size="sm"
        @click="markUnreadReleaseNotesAsRead"
      >
        {{ $t("components.release_notes.mark_all_as_read") }}
      </be-button>
    </div>
  </template>
</template>

<script>
import { EventBus } from "@/event-bus";
import { createNamespacedHelpers } from "vuex";
const sessionStore = createNamespacedHelpers("session");

export default {
  data() {
    return {
      loading: false,

      fields: [
        {
          key: "published_at",
          label: this.$t("components.release_notes.published_at"),
          sortable: true,
        },
        {
          key: "categories",
          label: this.$t("components.release_notes.categories"),
          class: "col-shrink",
        },
      ],
    };
  },

  computed: {
    ...sessionStore.mapGetters({
      releaseNotes: "getReleaseNotes",
    }),

    unreadReleaseNotes() {
      return (this.releaseNotes || []).filter((releaseNote) => {
        return (
          !releaseNote.read &&
          releaseNote.categories.some((category) => {
            return this.$currentUser.release_note_categories.includes(category);
          })
        );
      });
    },
  },

  created() {
    this.loadReleaseNotes();
  },

  methods: {
    ...sessionStore.mapActions(["fetchReleaseNotes", "markReleaseNotesAsRead"]),

    onClick(releaseNoteId) {
      this.markReleaseNotesAsRead(releaseNoteId);
    },

    link(releaseNote) {
      // TODO: We should handle this more gracefully since we might want to
      //       introduce more languages in the future.
      if (this.$currentUser.locale == "en" && releaseNote.link_en) {
        return releaseNote.link_en;
      }

      if (!releaseNote.link_sv) return releaseNote.link_en;

      return releaseNote.link_sv;
    },

    async loadReleaseNotes() {
      this.loading = true;

      try {
        await this.fetchReleaseNotes();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    markUnreadReleaseNotesAsRead() {
      this.unreadReleaseNotes.forEach((releaseNote) => {
        this.markReleaseNotesAsRead(releaseNote.id);
      });

      EventBus.emit("new-notification", {
        message: this.$t("components.release_notes.marked_all_unread_as_read"),
        variant: "success",
      });
    },
  },
};
</script>
