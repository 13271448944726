import { app } from "@/bootstrap";

/*
 |--------------------------------------------------------------------------
 | App Components
 |--------------------------------------------------------------------------
 |
 | Here we will load the app components which makes up the core client
 | application. This is also a convenient spot for you to load all of
 | your components that you write while building your applications.
 */

/**
 * Layout
 */
import ApplicationHeader from "./navbar/ApplicationHeader.vue";
import ReleaseNotes from "./release_notes/ReleaseNotes.vue";
import SidebarDocumentsBadge from "./shared/SidebarDocumentsBadge.vue";
import TabBadge from "./shared/TabBadge.vue";
import ToastsContainer from "./toasts/ToastsContainer.vue";
import UnreadMessagesBadge from "./shared/UnreadMessagesBadge.vue";
import UserbackInitialization from "./shared/UserbackInitialization.vue";
import FinancialTableExport from "./financials/FinancialTableExport.vue";
app.component("ApplicationHeader", ApplicationHeader);
app.component("ReleaseNotes", ReleaseNotes);
app.component("SidebarDocumentsBadge", SidebarDocumentsBadge);
app.component("TabBadge", TabBadge);
app.component("ToastsContainer", ToastsContainer);
app.component("UnreadMessagesBadge", UnreadMessagesBadge);
app.component("UserbackInitialization", UserbackInitialization);
app.component("FinancialTableExport", FinancialTableExport);

/**
 * Nav
 */
import SidebarMenu from "./nav/SidebarMenu.vue";
import UpcomingMeetingsWrapper from "./nav/UpcomingMeetingsWrapper.vue";
app.component("SidebarMenu", SidebarMenu);
app.component("UpcomingMeetingsWrapper", UpcomingMeetingsWrapper);

/**
 * Home Dashboard
 */
import HomeDashboard from "./home/HomeDashboard.vue";
import UpcomingActivities from "./home/UpcomingActivities.vue";
import GroupInvitations from "./home/invitations/GroupInvitations.vue";
import HomeMeetings from "./home/HomeMeetings.vue";
import HomeReports from "./home/ReportsTable.vue";
import HomeFinancialsDashboard from "./home/FinancialsDashboard.vue";
app.component("HomeDashboard", HomeDashboard);
app.component("UpcomingActivities", UpcomingActivities);
app.component("GroupInvitations", GroupInvitations);
app.component("HomeMeetings", HomeMeetings);
app.component("HomeReports", HomeReports);
app.component("HomeFinancialsDashboard", HomeFinancialsDashboard);

/**
 * User
 */
import NotificationSettingsModal from "./user/NotificationSettingsModal.vue";
import OrganizationsTable from "./user/OrganizationsTable.vue";
import RiksbyggenStatus from "./user/RiksbyggenStatus.vue";
import UserAvatar from "./user/UserAvatar.vue";
import UserEmailPasswordForm from "./user/UserEmailPasswordForm.vue";
import UserRegistrationForm from "./user/RegistrationForm.vue";
import EmailMfaActivation from "./user/EmailMfaActivation.vue";
import RecoveryCodesTable from "./user/RecoveryCodesTable.vue";
import MergeRequestUser from "./user/merge_requests/MergeRequestUser.vue";
import ConfirmPerformUserMerge from "./user/merge_requests/ConfirmPerformUserMerge.vue";
import NewMergeRequestForm from "./user/merge_requests/NewMergeRequestForm.vue";
app.component("OrganizationsTable", OrganizationsTable);
app.component("RiksbyggenStatus", RiksbyggenStatus);
app.component("UserAvatar", UserAvatar);
app.component("UserEmailPasswordForm", UserEmailPasswordForm);
app.component("UserRegistrationForm", UserRegistrationForm);
app.component("NotificationSettingsModal", NotificationSettingsModal);
app.component("EmailMfaActivation", EmailMfaActivation);
app.component("RecoveryCodesTable", RecoveryCodesTable);
app.component("MergeRequestUser", MergeRequestUser);
app.component("ConfirmPerformUserMerge", ConfirmPerformUserMerge);
app.component("NewMergeRequestForm", NewMergeRequestForm);

/**
 * Shared
 * - Bootstrap Vue Extensions made into Boardeaser Components
 * - General components
 */
import BeAspectRatio from "./shared/BeAspectRatio.vue";
import BeAvatar from "./shared/BeAvatar.vue";
import BeBadge from "./shared/BeBadge.vue";
import BeBreadcrumbs from "./shared/BeBreadcrumbs.vue";
import BeButton from "./shared/BeButton.vue";
import BeCodeInput from "./shared/BeCodeInput.vue";
import BeCollapse from "./shared/BeCollapse.vue";
import BeConfirmModal from "./shared/BeConfirmModal.vue";
import BeCopyToClipboardButton from "./shared/BeCopyToClipboardButton.vue";
import BeDropdown from "./shared/BeDropdown.vue";
import BeDropdownForm from "./shared/BeDropdownForm.vue";
import BeDropdownDivider from "./shared/BeDropdownDivider.vue";
import BeDropdownGroup from "./shared/BeDropdownGroup.vue";
import BeDropdownHeader from "./shared/BeDropdownHeader.vue";
import BeDropdownItem from "./shared/BeDropdownItem.vue";
import BeDropdownText from "./shared/BeDropdownText.vue";
import BeFileViewer from "./shared/BeFileViewer.vue";
import BeFormCheckbox from "./shared/BeFormCheckbox.vue";
import BeFormCheckboxGroup from "./shared/BeFormCheckboxGroup.vue";
import BeFormDatepicker from "./shared/BeFormDatepicker.vue";
import BeFormGroup from "./shared/BeFormGroup.vue";
import BeFormInput from "./shared/BeFormInput.vue";
import BeFormInvalidFeedback from "./shared/BeFormInvalidFeedback.vue";
import BeFormRadio from "./shared/BeFormRadio.vue";
import BeFormRadioGroup from "./shared/BeFormRadioGroup.vue";
import BeFormSelect from "./shared/BeFormSelect.vue";
import BeFormTextarea from "./shared/BeFormTextarea.vue";
import BeFormValidFeedback from "./shared/BeFormValidFeedback.vue";
import BeImg from "./shared/BeImg.vue";
import BeInputGroup from "./shared/BeInputGroup.vue";
import BeInputGroupAppend from "./shared/BeInputGroupAppend.vue";
import BeInputGroupPrepend from "./shared/BeInputGroupPrepend.vue";
import BeInputGroupSeparator from "./shared/BeInputGroupSeparator.vue";
import BeInputGroupText from "./shared/BeInputGroupText.vue";
import BeLink from "./shared/BeLink.vue";
import BeListGroup from "./shared/BeListGroup.vue";
import BeListGroupItem from "./shared/BeListGroupItem.vue";
import BeModal from "./shared/BeModal.vue";
import BeNav from "./shared/BeNav.vue";
import BeNavItem from "./shared/BeNavItem.vue";
import BeOverlay from "./shared/BeOverlay.vue";
import BePagination from "./shared/BePagination.vue";
import BePopover from "./shared/BePopover.vue";
import BeProgress from "./shared/BeProgress.vue";
import BeProgressBar from "./shared/BeProgressBar.vue";
import BeScrollArea from "./shared/BeScrollArea.vue";
import BeSkeleton from "./shared/BeSkeleton.vue";
import BeSkeletonGraph from "./shared/BeSkeletonGraph.vue";
import BeSkeletonImg from "./shared/BeSkeletonImg.vue";
import BeSkeletonTable from "./shared/BeSkeletonTable.vue";
import BeSkeletonWrapper from "./shared/BeSkeletonWrapper.vue";
import BeSpinner from "./shared/BeSpinner.vue";
import BeStatus from "./shared/BeStatus.vue";
import BeTable from "./shared/BeTable.vue";
import BeTableSimple from "./shared/BeTableSimple.vue";
import BeTabs from "./shared/BeTabs.vue";
import BeTeleport from "./shared/BeTeleport.vue";
import BeRelativeTime from "./shared/BeRelativeTime.vue";
import BeTooltip from "./shared/BeTooltip.vue";
import BeWizard from "./shared/BeWizard.vue";
import BtnToggleFullscreen from "./shared/BtnToggleFullscreen.vue";
import CommentsList from "./shared/comments/CommentsList.vue";
import CommentsAndActivities from "./shared/comments/CommentsAndActivities.vue";
import JobCallback from "./shared/JobCallback.vue";
import DateCountdown from "./shared/DateCountdown.vue";
import ExportModal from "./shared/export_modal/ExportModal.vue";
import ExportModalWithFilters from "./shared/export_modal/ExportModalWithFilters.vue";
import ConfirmModal from "./shared/ConfirmModal.vue";
app.component("BeAspectRatio", BeAspectRatio);
app.component("BeAvatar", BeAvatar);
app.component("BeBadge", BeBadge);
app.component("BeBreadcrumbs", BeBreadcrumbs);
app.component("BeButton", BeButton);
app.component("BeCodeInput", BeCodeInput);
app.component("BeCollapse", BeCollapse);
app.component("BeConfirmModal", BeConfirmModal);
app.component("BeCopyToClipboardButton", BeCopyToClipboardButton);
app.component("BeDropdown", BeDropdown);
app.component("BeDropdownForm", BeDropdownForm);
app.component("BeDropdownDivider", BeDropdownDivider);
app.component("BeDropdownGroup", BeDropdownGroup);
app.component("BeDropdownHeader", BeDropdownHeader);
app.component("BeDropdownItem", BeDropdownItem);
app.component("BeDropdownText", BeDropdownText);
app.component("BeFileViewer", BeFileViewer);
app.component("BeFormCheckbox", BeFormCheckbox);
app.component("BeFormCheckboxGroup", BeFormCheckboxGroup);
app.component("BeFormDatepicker", BeFormDatepicker);
app.component("BeFormGroup", BeFormGroup);
app.component("BeFormInput", BeFormInput);
app.component("BeFormInvalidFeedback", BeFormInvalidFeedback);
app.component("BeFormRadio", BeFormRadio);
app.component("BeFormRadioGroup", BeFormRadioGroup);
app.component("BeFormSelect", BeFormSelect);
app.component("BeFormTextarea", BeFormTextarea);
app.component("BeFormValidFeedback", BeFormValidFeedback);
app.component("BeImg", BeImg);
app.component("BeInputGroup", BeInputGroup);
app.component("BeInputGroupAppend", BeInputGroupAppend);
app.component("BeInputGroupPrepend", BeInputGroupPrepend);
app.component("BeInputGroupSeparator", BeInputGroupSeparator);
app.component("BeInputGroupText", BeInputGroupText);
app.component("BeLink", BeLink);
app.component("BeListGroup", BeListGroup);
app.component("BeListGroupItem", BeListGroupItem);
app.component("BeModal", BeModal);
app.component("BeNav", BeNav);
app.component("BeNavItem", BeNavItem);
app.component("BeOverlay", BeOverlay);
app.component("BePagination", BePagination);
app.component("BePopover", BePopover);
app.component("BeProgress", BeProgress);
app.component("BeProgressBar", BeProgressBar);
app.component("BeScrollArea", BeScrollArea);
app.component("BeSkeleton", BeSkeleton);
app.component("BeSkeletonGraph", BeSkeletonGraph);
app.component("BeSkeletonImg", BeSkeletonImg);
app.component("BeSkeletonTable", BeSkeletonTable);
app.component("BeSkeletonWrapper", BeSkeletonWrapper);
app.component("BeSpinner", BeSpinner);
app.component("BeStatus", BeStatus);
app.component("BeTable", BeTable);
app.component("BeTableSimple", BeTableSimple);
app.component("BeTabs", BeTabs);
app.component("BeTeleport", BeTeleport);
app.component("BeRelativeTime", BeRelativeTime);
app.component("BeTooltip", BeTooltip);
app.component("BeWizard", BeWizard);
app.component("BtnToggleFullscreen", BtnToggleFullscreen);
app.component("CommentsList", CommentsList);
app.component("CommentsAndActivities", CommentsAndActivities);
app.component("JobCallback", JobCallback);
app.component("DateCountdown", DateCountdown);
app.component("ExportModal", ExportModal);
app.component("ExportModalWithFilters", ExportModalWithFilters);
app.component("ConfirmModal", ConfirmModal);

/**
 * Evaluation
 */
import EvaluationFormViewer from "./evaluation_forms/EvaluationFormViewer.vue";
import EvaluationFormEditor from "./evaluation_forms/editor/FormEditor.vue";
import EvaluationParticipants from "./evaluation_forms/EvaluationParticipants.vue";
import EvaluationReportEditor from "./evaluation_forms/report/EvaluationReportEditor.vue";
import EvaluationReportResults from "./evaluation_forms/report/EvaluationReportResults.vue";
import EvaluationTemplatesTable from "./evaluation_forms/TemplatesTable.vue";
app.component("EvaluationFormViewer", EvaluationFormViewer);
app.component("EvaluationFormEditor", EvaluationFormEditor);
app.component("EvaluationParticipants", EvaluationParticipants);
app.component("EvaluationReportEditor", EvaluationReportEditor);
app.component("EvaluationReportResults", EvaluationReportResults);
app.component("EvaluationTemplatesTable", EvaluationTemplatesTable);

/**
 * Shared
 */
import BeAlert from "./shared/BeAlert.vue";
import CustomFields from "./shared/custom_fields/CustomFields.vue";
import FolderList from "./shared/FolderList.vue";
import MoneyFormat from "./shared/MoneyFormat.vue";
import ReadingsTable from "./shared/ReadingsTable.vue";
app.component("BeAlert", BeAlert);
app.component("CustomFields", CustomFields);
app.component("FolderList", FolderList);
app.component("MoneyFormat", MoneyFormat);
app.component("ReadingsTable", ReadingsTable);

/**
 * Form
 */
import BeFormInputCopy from "./form/BeFormInputCopy.vue";
import BeTelInput from "./form/BeTelInput.vue";
import DatepickerFutureButtons from "./form/DatepickerFutureButtons.vue";
import DateTimeRangePicker from "./form/DateTimeRangePicker.vue";
import MultiSelect from "./form/multi_select/MultiSelect.vue";
import PolicyLevel from "./form/PolicyLevel.vue";
import TextEditor from "./form/TextEditor.vue";
app.component("BeFormInputCopy", BeFormInputCopy);
app.component("BeTelInput", BeTelInput);
app.component("DatepickerFutureButtons", DatepickerFutureButtons);
app.component("DateTimeRangePicker", DateTimeRangePicker);
app.component("MultiSelect", MultiSelect);
app.component("PolicyLevel", PolicyLevel);
app.component("TextEditor", TextEditor);

/**
 * Activities & My Tasks sidebar components
 */
import ActivityLog from "./activity_log/ActivityLog.vue";
import ActivityLogItem from "./activity_log/ActivityLogItem.vue";
import RecentActivities from "./activity_log/RecentActivities.vue";
import MyTasks from "./activity_log/MyTasks.vue";
app.component("ActivityLog", ActivityLog);
app.component("ActivityLogItem", ActivityLogItem);
app.component("RecentActivities", RecentActivities);
app.component("MyTasks", MyTasks);

/**
 * Authentication
 */
import AuthenticationLoginForm from "./authentication/LoginForm.vue";
import BankIdAuthentication from "./authentication/BankIdAuthentication.vue";
import BankidActivation from "./authentication/BankidActivation.vue";
import BankidElevation from "./authentication/BankidElevation.vue";
import BankIdCollect from "./authentication/BankIdCollect.vue";
import PasswordInput from "./authentication/PasswordInput.vue";
import U2fAuthentication from "./authentication/yubikey/YubikeyAuthentication.vue";
import YubikeyRegistration from "./authentication/yubikey/YubikeyRegistration.vue";
import EmailMfaLoginForm from "./authentication/EmailMfaLoginForm.vue";
app.component("AuthenticationLoginForm", AuthenticationLoginForm);
app.component("BankIdAuthentication", BankIdAuthentication);
app.component("BankidActivation", BankidActivation);
app.component("BankidElevation", BankidElevation);
app.component("BankIdCollect", BankIdCollect);
app.component("PasswordInput", PasswordInput);
app.component("U2fAuthentication", U2fAuthentication);
app.component("YubikeyRegistration", YubikeyRegistration);
app.component("EmailMfaLoginForm", EmailMfaLoginForm);

/**
 *  Admin Panel
 */
import AdminPanelAnnualReportsTable from "./admin_panel/dashboard/AnnualReportsTable.vue";
import AdminPanelDocumentForm from "./admin_panel/companies/documents/DocumentForm.vue";
import AdminPanelDocumentTabs from "./admin_panel/documents/DocumentTabs.vue";
import AdminPanelFinancialsDashboardsTable from "./admin_panel/financials/AdminPanelFinancialsDashboardsTable.vue";
import AdminPanelImportCompanies from "./admin_panel/companies/ImportCompanies.vue";
import AdminPanelImportGroupOwnerships from "./admin_panel/companies/ImportGroupOwnerships.vue";
import AdminPanelInvitationsTable from "./admin_panel/companies/InvitationsTable.vue";
import AdminPanelMembershipForm from "./admin_panel/administrators/MembershipForm.vue";
import AdminPanelUserSettingsForm from "./admin_panel/users/UserSettingsForm.vue";
import AdminPanelSettingsForm from "./admin_panel/admin/SettingsForm.vue";
import DistributeDocuments from "./admin_panel/documents/DistributeDocuments.vue";
import InviteToCompanies from "./admin_panel/users/InviteToCompanies.vue";
import SetupCompanies from "./admin_panel/companies/SetupCompanies.vue";
app.component("AdminPanelAnnualReportsTable", AdminPanelAnnualReportsTable);
app.component("AdminPanelDocumentForm", AdminPanelDocumentForm);
app.component("AdminPanelDocumentTabs", AdminPanelDocumentTabs);
app.component("AdminPanelInviteUserToCompanies", InviteToCompanies);
app.component("AdminPanelSetupCompanies", SetupCompanies);
app.component("AdminPanelImportCompanies", AdminPanelImportCompanies);
app.component(
  "AdminPanelImportGroupOwnerships",
  AdminPanelImportGroupOwnerships
);
app.component("AdminPanelMembershipForm", AdminPanelMembershipForm);
app.component("AdminPanelUserSettingsForm", AdminPanelUserSettingsForm);
app.component("AdminPanelSettingsForm", AdminPanelSettingsForm);
app.component("DistributeDocuments", DistributeDocuments);
app.component(
  "AdminPanelFinancialsDashboardsTable",
  AdminPanelFinancialsDashboardsTable
);
app.component("AdminPanelInvitationsTable", AdminPanelInvitationsTable);

import AdminCompanyForm from "./admin_panel/companies/AdminCompanyForm.vue";
app.component("AdminCompanyForm", AdminCompanyForm);

import AdminPanelFetchCompaniesFromSyna from "./admin_panel/companies/FetchFromSyna.vue";
app.component(
  "AdminPanelFetchCompaniesFromSyna",
  AdminPanelFetchCompaniesFromSyna
);

/**
 * File Uploader
 */
import FileUploader from "./file_uploader/FileUploader.vue";
import DocumentPickerModal from "./file_uploader/DocumentPickerModal.vue";
import DocumentUploader from "./file_uploader/DocumentUploader.vue";
import SingleFileUploader from "./file_uploader/SingleFileUploader.vue";
app.component("FileUploader", FileUploader);
app.component("DocumentPickerModal", DocumentPickerModal);
app.component("DocumentUploader", DocumentUploader);
app.component("SingleFileUploader", SingleFileUploader);

/**
 * Tasks
 */
import SingleTask from "./tasks/SingleTask.vue";
import TaskViewer from "./tasks/TaskViewer.vue";
app.component("SingleTask", SingleTask);
app.component("TaskViewer", TaskViewer);

/**
 * Annual reports & annual cycles
 */
import YearPlan from "./annual_cycles/YearPlan.vue";
import AnnualCycleViewer from "./annual_cycles/AnnualCycleViewer.vue";
app.component("YearPlan", YearPlan);
app.component("AnnualCycleViewer", AnnualCycleViewer);

import AnnualReportDocuments from "./annual_reports/AnnualReportDocuments.vue";
import AnnualReportSignatureForm from "./annual_reports/SignatureForm.vue";
import AnnualReportsDashboard from "./annual_reports/AnnualReportsDashboard.vue";
app.component("AnnualReportDocuments", AnnualReportDocuments);
app.component("AnnualReportSignatureForm", AnnualReportSignatureForm);
app.component("AnnualReportsDashboard", AnnualReportsDashboard);

import SingleAnnualReport from "./annual_reports/SingleAnnualReport.vue";
app.component("SingleAnnualReport", SingleAnnualReport);

import NewAnnualReportForm from "./annual_reports/NewAnnualReportForm.vue";
app.component("NewAnnualReportForm", NewAnnualReportForm);

/**
 * Compliance
 */
import ComplianceConfiguration from "./compliance/Configuration.vue";
import ComplianceEditor from "./compliance/ComplianceEditor.vue";
import CompliancePermissionsTable from "./compliance/CompliancePermissionsTable.vue";
app.component("ComplianceConfiguration", ComplianceConfiguration);
app.component("ComplianceEditor", ComplianceEditor);
app.component("CompliancePermissionsTable", CompliancePermissionsTable);

/**
 * Contracts
 */
import ContractEditor from "./contracts/ContractEditor.vue";
import ContractTabs from "./contracts/ContractTabs.vue";
import ContractNotifications from "./contracts/ContractNotifications.vue";
import ContractCategories from "./contracts/categories/ContractCategories.vue";
app.component("ContractEditor", ContractEditor);
app.component("ContractTabs", ContractTabs);
app.component("ContractNotifications", ContractNotifications);
app.component("ContractCategories", ContractCategories);

/**
 * Decisions
 */
import DecisionsTable from "./decisions/DecisionsTable.vue";
import SingleDecision from "./decisions/SingleDecision.vue";
import DecisionsWrapper from "./decisions/DecisionsWrapper.vue";
app.component("DecisionsTable", DecisionsTable);
app.component("SingleDecision", SingleDecision);
app.component("DecisionsWrapper", DecisionsWrapper);

/**
 * Documents
 */

import ComplianceDocumentsReadingTable from "./documents/readings/ComplianceDocumentsReadingTable.vue";
import DocumentLink from "./documents/DocumentLink.vue";
import DocumentsHistoryTable from "./documents/DocumentsHistoryTable.vue";
import DocumentSignatureCase from "./documents/DocumentSignatureCase.vue";
import DocumentsReadingTable from "./documents/readings/DocumentsReadingTable.vue";
import SendESignaturesButton from "./documents/SendESignaturesButton.vue";
import SignatureForm from "./documents/SignatureForm.vue";
import SignatureIndex from "./documents/SignatureIndex.vue";
import SingleDocument from "./documents/SingleDocument.vue";
import StorageUsage from "./documents/StorageUsage.vue";

app.component(
  "ComplianceDocumentsReadingTable",
  ComplianceDocumentsReadingTable
);
app.component("DocumentLink", DocumentLink);
app.component("DocumentsHistoryTable", DocumentsHistoryTable);
app.component("DocumentSignatureCase", DocumentSignatureCase);
app.component("DocumentsReadingTable", DocumentsReadingTable);
app.component("SendESignaturesButton", SendESignaturesButton);
app.component("SignatureForm", SignatureForm);
app.component("SignatureIndex", SignatureIndex);
app.component("SingleDocument", SingleDocument);
app.component("StorageUsage", StorageUsage);

/**
 * Education
 */
import EducationFriendInvitationTable from "./education/FriendInvitationTable.vue";
import EducationGroupForm from "./education/GroupForm.vue";
import EducationGroupsTable from "./education/GroupsTable.vue";
import EducationQuestionForm from "./education/QuestionForm.vue";
import EducationQuestionsTable from "./education/QuestionsTable.vue";
app.component("EducationFriendInvitationTable", EducationFriendInvitationTable);
app.component("EducationGroupForm", EducationGroupForm);
app.component("EducationGroupsTable", EducationGroupsTable);
app.component("EducationQuestionForm", EducationQuestionForm);
app.component("EducationQuestionsTable", EducationQuestionsTable);

/**
 * Financials
 */
import FinancialsPreparations from "./financials/FinancialsPreparations.vue";
app.component("FinancialsPreparations", FinancialsPreparations);

import DashboardConfigurations from "./financials/dashboard/DashboardConfigurations.vue";
import FinancialsVisual from "./financials/FinancialsVisual.vue";
app.component("DashboardConfigurations", DashboardConfigurations);
app.component("FinancialsVisual", FinancialsVisual);

// Financial Data Management
import FinancialsConfigurationsTable from "./financials/data_management/ConfigurationsTable.vue";
import FinancialsDataVariableTable from "./financials/data_management/FinancialsDataVariableTable.vue";
import FinancialsGraphTable from "./financials/data_management/FinancialsGraphTable.vue";
import FinancialsGroupTreeTable from "./financials/data_management/FinancialsGroupTreeTable.vue";
import FinancialsTableTable from "./financials/data_management/FinancialsTableTable.vue";
import FinancialsWidgetTable from "./financials/data_management/FinancialsWidgetTable.vue";
import FinancialsVariablesTable from "./financials/data_management/FinancialsVariablesTable.vue";
import SieFilesTable from "./financials/data_management/SieFilesTable.vue";
app.component("FinancialsConfigurationsTable", FinancialsConfigurationsTable);
app.component("FinancialsDataVariableTable", FinancialsDataVariableTable);
app.component("FinancialsVariablesTable", FinancialsVariablesTable);
app.component("SieFilesTable", SieFilesTable);
app.component("FinancialsGraphTable", FinancialsGraphTable);
app.component("FinancialsGroupTreeTable", FinancialsGroupTreeTable);
app.component("FinancialsTableTable", FinancialsTableTable);
app.component("FinancialsWidgetTable", FinancialsWidgetTable);

// Editors
import FinancialsBudgetEditor from "./financials/editors/BudgetEditor.vue";
import FinancialsConsolidatedAnnualReportEditor from "./financials/editors/ConsolidatedAnnualReportEditor.vue";
import FinancialsDataExport from "./financials/editors/DataExport.vue";
import FinancialsExportButton from "./financials/editors/FinancialsExportButton.vue";
import FinancialsOriginLabelEditor from "./financials/editors/OriginLabelEditor.vue";
import FinancialsRowTemplateEditor from "./financials/editors/RowTemplateEditor.vue";

app.component("FinancialsBudgetEditor", FinancialsBudgetEditor);
app.component(
  "FinancialsConsolidatedAnnualReportEditor",
  FinancialsConsolidatedAnnualReportEditor
);
app.component("FinancialsDataExport", FinancialsDataExport);
app.component("FinancialsExportButton", FinancialsExportButton);
app.component("FinancialsOriginLabelEditor", FinancialsOriginLabelEditor);
app.component("FinancialsRowTemplateEditor", FinancialsRowTemplateEditor);

import FinancialsCorporateGroupConsolidationEditor from "./financials/corporate_groups/ConsolidationEditor.vue";
app.component(
  "FinancialsCorporateGroupConsolidationEditor",
  FinancialsCorporateGroupConsolidationEditor
);

/**
 * Corporate Groups
 */
import CorporateGroup from "./companies/corporate_group/CorporateGroup.vue";
import CorporateGroupInvitationForm from "./companies/corporate_group/CorporateGroupInvitationForm.vue";
import CorporateGroupUpgradeButton from "./financials/corporate_groups/UpgradeButton.vue";

app.component("CorporateGroup", CorporateGroup);
app.component("CorporateGroupInvitationForm", CorporateGroupInvitationForm);
app.component("CorporateGroupUpgradeButton", CorporateGroupUpgradeButton);

/**
 * Feature shop
 */
import CompaniesShopFeature from "./shop/ShopFeature.vue";
app.component("CompaniesShopFeature", CompaniesShopFeature);

import FeatureList from "./shop/FeatureList.vue";
app.component("FeatureList", FeatureList);

import FeatureShop from "./shop/FeatureShop.vue";
app.component("FeatureShop", FeatureShop);

import FeaturePriceVariationsModal from "./shared/FeaturePriceVariationsModal.vue";
app.component("FeaturePriceVariationsModal", FeaturePriceVariationsModal);

/**
 * Memberships
 */
import MembershipViewer from "./companies/memberships/MembershipViewer.vue";
app.component("MembershipViewer", MembershipViewer);

/*
 * Companies
 */
import CompanySettingsForm from "./companies/settings/CompanySettingsForm.vue";
import CompanySettingsESigning from "./companies/settings/ESigning.vue";
import DefaultTemplateImporter from "./companies/settings/DefaultTemplateImporter.vue";
app.component("CompanySettingsForm", CompanySettingsForm);
app.component("CompanySettingsESigning", CompanySettingsESigning);
app.component("DefaultTemplateImporter", DefaultTemplateImporter);

import NewCompanyForm from "./companies/NewCompanyForm.vue";
app.component("NewCompanyForm", NewCompanyForm);

/**
 * Integrations
 */
import PaymentIntegrationsShow from "./companies/payment_integrations/pages/PaymentIntegrationsShow.vue";
app.component("PaymentIntegrationsShow", PaymentIntegrationsShow);

/**
 * Meetings
 */
import AgendaTemplateDropdownItem from "./meetings/material/AgendaTemplateDropdownItem.vue";
import AvailabilityChoices from "./meetings/AvailabilityChoices.vue";
import MaterialPdfModal from "./meetings/material/MaterialPdfModal.vue";
import MeetingDisplay from "./meetings/MeetingDisplay.vue";
import MeetingEditor from "./meetings/MeetingEditor.vue";
import MeetingInfo from "./meetings/components/MeetingInfo.vue";
import MeetingInvitations from "./meetings/MeetingInvitations.vue";
import MeetingStatus from "./meetings/material/MeetingStatus.vue";
import MeetingsDashboard from "./meetings/MeetingsDashboard.vue";
import PhraseViewer from "./meetings/phrases/PhraseViewer.vue";
import UpcomingMeetings from "./meetings/UpcomingMeetings.vue";
import HistoricMeetings from "./meetings/HistoricMeetings.vue";
import MeetingsIndex from "./meetings/MeetingsIndex.vue";
app.component("AgendaTemplateDropdownItem", AgendaTemplateDropdownItem);
app.component("AvailabilityChoices", AvailabilityChoices);
app.component("MaterialPdfModal", MaterialPdfModal);
app.component("MeetingDisplay", MeetingDisplay);
app.component("MeetingEditor", MeetingEditor);
app.component("MeetingInfo", MeetingInfo);
app.component("MeetingInvitations", MeetingInvitations);
app.component("MeetingsDashboard", MeetingsDashboard);
app.component("MeetingStatus", MeetingStatus);
app.component("UpcomingMeetings", UpcomingMeetings);
app.component("HistoricMeetings", HistoricMeetings);
app.component("PhraseViewer", PhraseViewer);
app.component("MeetingsIndex", MeetingsIndex);

/**
 * Materials
 */
import ContentVersionsDropdown from "./meetings/material/ContentVersionsDropdown.vue";
import MaterialEditor from "./meetings/material/MaterialEditor.vue";
import MaterialShow from "./meetings/material/MaterialShow.vue";
import MinutesReview from "./meetings/material/MinutesReview.vue";
import MinutesShow from "./meetings/material/MinutesShow.vue";
import UnlockMinutesModal from "./meetings/material/UnlockMinutesModal.vue";
import UnlockStatus from "./meetings/material/UnlockStatus.vue";
app.component("ContentVersionsDropdown", ContentVersionsDropdown);
app.component("MaterialEditor", MaterialEditor);
app.component("MaterialShow", MaterialShow);
app.component("MinutesReview", MinutesReview);
app.component("MinutesShow", MinutesShow);
app.component("UnlockMinutesModal", UnlockMinutesModal);
app.component("UnlockStatus", UnlockStatus);

/**
 * Inquiry Payments
 */
import InquiryPaymentsIndex from "./inquiry_payments/pages/InquiryPaymentsIndex.vue";
app.component("InquiryPaymentsIndex", InquiryPaymentsIndex);

/**
 * Agenda Templates
 */

import AgendaTemplateEditor from "./meetings/agenda_templates/AgendaTemplateEditor.vue";
import AgendaTemplateDisplay from "./meetings/agenda_templates/AgendaTemplateDisplay.vue";
app.component("AgendaTemplateEditor", AgendaTemplateEditor);
app.component("AgendaTemplateDisplay", AgendaTemplateDisplay);

/**
 * Onboarding
 */
import CompanyOnboardingWizard from "./onboarding/company/CompanyOnboardingWizard.vue";
import UserOnboardingWizard from "./onboarding/user/UserOnboardingWizard.vue";
app.component("CompanyOnboardingWizard", CompanyOnboardingWizard);
app.component("UserOnboardingWizard", UserOnboardingWizard);

/**
 * Projects
 */
import ProjectTasksBadge from "./projects/ProjectTasksBadge.vue";
app.component("ProjectTasksBadge", ProjectTasksBadge);

/**
 * Reports
 */

import ReportView from "./reports/ReportView.vue";
import ReportTemplates from "./reports/ReportTemplates.vue";
import ReportsDashboard from "./home/ReportsTable.vue";
import ReportsOverview from "./reports/ReportsOverview.vue";
app.component("ReportView", ReportView);
app.component("ReportTemplates", ReportTemplates);
app.component("ReportsDashboard", ReportsDashboard);
app.component("ReportsOverview", ReportsOverview);

/**
 * Template Packages
 */
import PublishPackageTable from "./template_packages/PublishPackageTable.vue";
import TemplateInvitations from "./template_packages/TemplateInvitations.vue";
import TemplatePackageCreatorSelector from "./template_packages/TemplatePackageCreatorSelector.vue";
import TemplateShop from "./template_packages/TemplateShop.vue";
app.component("PublishPackageTable", PublishPackageTable);
app.component("TemplateInvitations", TemplateInvitations);
app.component("TemplatePackageCreatorSelector", TemplatePackageCreatorSelector);
app.component("TemplateShop", TemplateShop);

/**
 * Contacts
 */
import ContactsContainer from "./contacts/ContactsContainer.vue";
import ContactForm from "./contacts/ContactForm.vue";
app.component("ContactsContainer", ContactsContainer);
app.component("ContactForm", ContactForm);

/**
 * Message Threads
 */
import MessageThread from "./companies/message_threads/MessageThread.vue";
import MessageThreadList from "./companies/message_threads/MessageThreadList.vue";
app.component("MessageThread", MessageThread);
app.component("MessageThreadList", MessageThreadList);

/**
 * Shareholder Register
 */
import ShareholderRegisterContainer from "./shareholder_registers/ShareholderRegisterContainer.vue";
import ShareholderRegisterTransactionForm from "./shareholder_registers/transactions/TransactionForm.vue";
import ShareholderRegisterActionForm from "./shareholder_registers/actions/ActionForm.vue";
import ShareholderForm from "./shareholder_registers/register/ShareholderForm.vue";
import ShareholderEditForm from "./shareholder_registers/ShareholderEditForm.vue";
app.component("ShareholderRegisterContainer", ShareholderRegisterContainer);
app.component(
  "ShareholderRegisterTransactionForm",
  ShareholderRegisterTransactionForm
);
app.component("ShareholderRegisterActionForm", ShareholderRegisterActionForm);
app.component("ShareholderForm", ShareholderForm);
app.component("ShareholderEditForm", ShareholderEditForm);

/*
 * Tokens
 */
import TokensIndex from "./tokens/TokensIndex.vue";
app.component("TokensIndex", TokensIndex);

/**
 * Dev Docs Components
 */
import CodeExample from "./dev_docs/CodeExample.vue";
app.component("CodeExample", CodeExample);

export { app };
