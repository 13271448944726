<template>
  <div class="row">
    <div v-if="showMfaBanner" class="col-12">
      <div class="card mb-3">
        <div class="card-body">
          <be-alert v-if="$currentUser.has_mfa" variant="success">
            {{ $t("models.user.mfa_activated") }}
          </be-alert>

          <be-alert v-else variant="warning">
            <i18n-t keypath="models.user.mfa_not_activated_w_link">
              <template #link>
                <a :href="url('/users/mfa')">
                  {{ $t("components.user.settings.activate") }}
                </a>
              </template>
            </i18n-t>
          </be-alert>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-6">
      <form @submit.prevent="changeEmail">
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">
              {{ $t("components.user.email_password.change_email") }}
            </h5>
          </div>

          <div class="card-body">
            <be-alert v-if="emailUser.unconfirmed_email" variant="info">
              <span
                v-dompurify-html="
                  $t(
                    'components.user.email_password.pending_confirmation_w_email_html',
                    {
                      email: emailUser.unconfirmed_email,
                    }
                  )
                "
              />
            </be-alert>

            <be-form-group
              label-for="email"
              :label="$t('activerecord.attributes.user.email')"
              :error="getErrors(emailUser, ['email'])"
            >
              <be-form-input
                id="email"
                v-model="emailUser.email"
                type="email"
                autocomplete="username"
                required
                @change="clearErrors(emailUser, ['email'])"
              />
            </be-form-group>

            <be-form-group
              v-if="
                $platform.idp_login !== 'riksb' &&
                platformEnabled('email_authentication')
              "
              label-for="email-current-password"
              :label="$t('activerecord.attributes.user.current_password')"
              :error="getErrors(emailUser, ['current_password'])"
            >
              <be-form-input
                id="email-current-password"
                v-model="emailUser.current_password"
                type="password"
                required
                autocomplete="current-password"
                @change="clearErrors(emailUser, ['current_password'])"
              />
            </be-form-group>
          </div>

          <div class="card-footer d-flex justify-content-end">
            <be-button
              type="submit"
              variant="primary"
              :loading="loadingEmail"
              @click="changeEmail"
            >
              {{ $t("buttons.titles.save") }}
            </be-button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="platformEnabled('email_authentication')" class="col-12 col-xl-6">
      <form @submit.prevent="changePassword">
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">
              {{ $t("components.user.email_password.change_password") }}
            </h5>
          </div>

          <div class="card-body">
            <be-form-input
              id="password-email"
              v-model="passwordUser.email"
              autocomplete="username"
              hidden
            />

            <!-- PasswordInput includes a BeFormGroup -->
            <password-input
              id="user-password"
              v-model="passwordUser.password"
              name="user-password"
              :label="$t('activerecord.attributes.user.new_password')"
              required
              :error="getErrors(passwordUser, ['password'])"
              :state="validationState(passwordUser, ['password'])"
              @change="clearErrors(passwordUser, ['password'])"
            />

            <be-form-group
              label-for="password-current-password"
              :label="$t('activerecord.attributes.user.current_password')"
              :error="getErrors(passwordUser, ['current_password'])"
            >
              <be-form-input
                id="password-current-password"
                v-model="passwordUser.current_password"
                type="password"
                required
                autocomplete="current-password"
                @change="clearErrors(passwordUser, ['current_password'])"
              />
            </be-form-group>
          </div>

          <div class="card-footer d-flex justify-content-end">
            <be-button
              type="submit"
              :loading="loadingPassword"
              variant="primary"
              @click="changePassword"
            >
              {{ $t("buttons.titles.save") }}
            </be-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Config from "@/config.js";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingEmail: false,
      loadingPassword: false,
      emailUser: { password: "" },
      passwordUser: { password: "" },
      minPasswordLength: Config.PASSWORD_MIN_LENGTH,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "current_user/getUser",
    }),

    showMfaBanner() {
      return (
        this.platformEnabled("email_authentication") &&
        (this.$currentUser.has_mfa || this.$platform.idp_login !== "riksb")
      );
    },
  },

  watch: {
    currentUser(value) {
      this.emailUser = this.cloneDeep(value);
      this.passwordUser = this.cloneDeep(value);
    },
  },

  async created() {
    await this.setUser(this.user);
    this.emailUser = this.cloneDeep(this.currentUser);
    this.passwordUser = this.cloneDeep(this.currentUser);
  },

  methods: {
    ...mapActions({
      setUser: "current_user/setUser",
    }),

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async changePassword() {
      try {
        this.loadingPassword = true;

        await axios.patch(`/users/change-password`, {
          user: {
            password: this.passwordUser.password,
            current_password: this.passwordUser.current_password,
          },
        });
        this.passwordUser = {
          ...this.passwordUser,
          password: "",
          current_password: "",
        };
      } catch (error) {
        if (error.response?.status === 422) {
          this.passwordUser = { ...error.response.data };
        } else {
          this.handleError(error);
        }
      } finally {
        this.currentPasswordPassword = "";
        this.loadingPassword = false;
      }
    },

    async changeEmail() {
      try {
        this.loadingEmail = true;

        const response = await axios.patch(`/users/email`, {
          user: {
            email: this.emailUser.email,
            current_password: this.emailUser.current_password,
          },
        });

        await this.setUser({ ...response.data });
      } catch (error) {
        if (error.response?.status === 422) {
          this.emailUser = { ...error.response.data };
        } else if (error.response?.status === 401) {
          this.emailUser.current_password = "";
          this.emailUser.errors = {
            current_password: [
              this.$t(
                "activerecord.errors.models.user.attributes.current_password.invalid"
              ),
            ],
          };
        } else {
          this.handleError(error);
        }
      } finally {
        this.loadingEmail = false;
      }
    },
  },
};
</script>
