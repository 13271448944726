<template>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4 mb-xl-0">
        <div class="d-flex flex-wrap justify-content-between gap-1">
          <h3>
            {{ $t("components.annual_reports.audit_tab.signatures_title") }}
          </h3>

          <template v-if="annualReportDocument">
            <be-button
              v-be-modal="
                `annualReportDocumentModal-${annualReportDocument.id}`
              "
              size="sm"
              class="mb-1"
            >
              {{ $t("buttons.titles.show") }}
            </be-button>

            <document-modal
              :modal-id="`annualReportDocumentModal-${annualReportDocument.id}`"
              :document-id="annualReportDocument.id"
              :company-id="annualReportDocument.company_id"
            />
          </template>
        </div>

        <be-alert
          v-if="showAnnualReportSignatoryUnavailableText"
          variant="warning"
        >
          {{ $t("components.annual_reports.signatory_unavailable") }}
        </be-alert>

        <template v-if="!auditorNeeded">
          <blockquote>
            <div v-if="isFoundation">
              {{
                $t(
                  "components.annual_reports.audit_tab.auditor_selection_foundation"
                )
              }}
            </div>

            <template v-else>
              <div class="mb-2">
                {{
                  $t("components.annual_reports.audit_tab.auditor_selection")
                }}
              </div>

              <be-link
                href="https://bolagsverket.se/ff/foretagsformer/aktiebolag/driva/revisor"
              >
                {{
                  $t(
                    "components.annual_reports.audit_tab.auditor_selection_link_text"
                  )
                }}
              </be-link>
            </template>
          </blockquote>
        </template>

        <signatures-table
          v-if="showSignatureTable"
          :signatures="annualReportSortedSignatures"
          :document="annualReportDocument"
        />

        <transition-notice
          v-if="markedAsManuallySignedTransition"
          :transition="markedAsManuallySignedTransition"
          translation-key="mark_annual_report_as_manually_signed"
        />

        <template v-if="showInformAuditorOfAnnualReport">
          <p>
            {{ $t("components.annual_reports.audit_tab.send_to_auditor_text") }}
          </p>

          <div class="d-flex justify-content-end">
            <be-button
              variant="outline-secondary"
              :disabled="loading"
              @click="informUserDocument = annualReportDocument"
            >
              {{ $t("components.annual_reports.audit_tab.send_to_auditor") }}
            </be-button>
          </div>

          <inform-user-modal
            :document="informUserDocument"
            :primary-memberships="auditorMemberships"
            :form-url="informAuditorUrl"
            :disable-user-selection="true"
            show-automatically
            @reset="informUserDocument = {}"
            @users-informed="setAnnualReport"
          />
        </template>

        <template v-if="isFoundation">
          <div class="d-flex flex-wrap justify-content-between gap-1 mt-3">
            <h3>
              {{
                $t(
                  "components.annual_reports.audit_tab.board_statement_signatures_title"
                )
              }}
            </h3>

            <template v-if="boardStatementDocument">
              <be-button
                v-be-modal="
                  `boardStatementDocumentModal-${boardStatementDocument.id}`
                "
                size="sm"
                class="mb-1"
              >
                {{ $t("buttons.titles.show") }}
              </be-button>

              <document-modal
                :modal-id="`boardStatementDocumentModal-${boardStatementDocument.id}`"
                :document-id="boardStatementDocument.id"
                :company-id="boardStatementDocument.company_id"
              />
            </template>
          </div>

          <be-alert
            v-if="showAnnualReportNotSignedByOrganisationText"
            variant="info"
            class="mt-2"
          >
            {{
              $t(
                "components.annual_reports.audit_tab.annual_report_not_signed_by_organization"
              )
            }}
          </be-alert>

          <transition-notice
            v-if="boardStatementMarkedAsManuallySignedTransition"
            :transition="boardStatementMarkedAsManuallySignedTransition"
            translation-key="manually_sign_board_statement"
          />

          <signatures-table
            v-else-if="showBoardStatementSignatures"
            :document="boardStatementDocument"
            :signatures="boardStatementDocument.signatures"
          />

          <board-statement-upload v-else />
        </template>

        <transition-notice
          v-if="lastStateTransition"
          :transition="lastStateTransition"
          variant="info"
          translation-key="send_to_auditor_annual_report"
        />
      </div>

      <div v-if="auditorNeeded" class="col-12 col-xl-6">
        <h3>{{ $t("models.annual_report.documents.audit_report") }}</h3>

        <be-alert
          v-if="showAuditReportSignatoryUnavailableText"
          variant="warning"
        >
          {{ $t("components.annual_reports.audit_tab.no_auditor_signatory") }}
        </be-alert>

        <be-alert v-if="showAnnualReportNotSignedText" variant="info">
          {{
            $t("components.annual_reports.audit_tab.annual_report_not_signed")
          }}
        </be-alert>

        <p v-if="showAuditReportUploadText">
          {{
            $t("components.annual_reports.audit_tab.audit_report_upload_text")
          }}
        </p>

        <annual-report-documents
          v-if="showUploadAuditReport"
          document-key="audit_report"
          @uploading="(value) => (loading = value)"
        />

        <be-alert v-if="showNoAuditReport" variant="info">
          {{
            $t("components.annual_reports.audit_tab.no_audit_report_uploaded")
          }}
        </be-alert>

        <template v-if="showAuditReportSignaturesTable">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th colspan="3">
                    {{ $t("models.annual_report.documents.audit_report") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-if="showPdfGenerating">
                  <td colspan="3">
                    <be-spinner>
                      {{ $t("components.annual_reports.generating_pdf") }}
                    </be-spinner>
                  </td>
                </tr>

                <tr v-else-if="auditReportDocument">
                  <td colspan="2">
                    <document-link
                      :document-id="auditReportDocument.id"
                      :filename="filenameWithSize(auditReportDocument)"
                    />
                  </td>

                  <td class="text-right">
                    <be-button
                      v-if="
                        !auditReportIxbrlDocument &&
                        canRemoveDocument(auditReportDocument)
                      "
                      v-be-tooltip="$t('buttons.titles.remove')"
                      variant="danger"
                      size="sm"
                      icon="fa-times"
                      @click="confirmRemoveDocument(auditReportDocument)"
                    />
                  </td>
                </tr>

                <tr v-if="auditReportIxbrlDocument">
                  <td colspan="2">
                    <document-link
                      :document-id="auditReportIxbrlDocument.id"
                      :filename="filenameWithSize(auditReportIxbrlDocument)"
                    />
                  </td>

                  <td class="text-right">
                    <be-button
                      v-if="canRemoveDocument(auditReportIxbrlDocument)"
                      v-be-tooltip="$t('buttons.titles.remove')"
                      variant="danger"
                      size="sm"
                      icon="fa-times"
                      @click="confirmRemoveDocument(auditReportIxbrlDocument)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <signatures-table
            v-if="auditReportSignatures.length > 0"
            :signatures="auditReportSignatures"
            :document="auditReportDocument"
          />

          <transition-notice
            v-if="auditReportManuallySignedTransition"
            :transition="auditReportManuallySignedTransition"
            translation-key="sign_manually_audit_report"
          />
        </template>

        <p
          v-if="
            auditReportIxbrlDocument &&
            can('sign_manually', 'audit_report') &&
            can('request_signatures', 'audit_report')
          "
        >
          {{ $t("components.annual_reports.ixbrl_any_signature_info") }}
        </p>

        <p
          v-else-if="
            auditReportIxbrlDocument &&
            can('request_signatures', 'audit_report')
          "
        >
          {{
            $t("components.annual_reports.ixbrl_only_digital_signature_info")
          }}
        </p>

        <p
          v-else-if="
            auditReportIxbrlDocument && can('sign_manually', 'audit_report')
          "
        >
          {{ $t("components.annual_reports.ixbrl_only_manual_signature_info") }}
        </p>

        <p
          v-else-if="
            can('request_signatures', 'audit_report') ||
            can('sign_manually', 'audit_report')
          "
        >
          {{ $t("components.annual_reports.pdf_signature_info") }}
        </p>

        <div class="d-flex justify-content-end mt-3">
          <be-button
            v-if="can('sign_manually', 'audit_report')"
            variant="outline-primary"
            class="mb-2 mb-md-0 mr-1"
            :disabled="loading"
            @click="confirmTriggerEvent('sign_manually', 'audit_report')"
          >
            <template v-if="auditReportIxbrlDocument">
              {{ $t("components.annual_reports.ixbrl_signed") }}
            </template>

            <template v-else>
              {{ $t("components.annual_reports.manually_signed") }}
            </template>
          </be-button>

          <span :id="`esign-audit-${annualReport.id}`">
            <be-button
              v-if="showRequestAuditReportSignatures"
              :disabled="!auditorsAvailable"
              variant="primary"
              @click="signatureDocument = auditReportDocument"
            >
              {{ $t("documents.document_options.e_sign") }}
            </be-button>
          </span>

          <be-tooltip
            :disabled="auditorsAvailable"
            :target="`esign-audit-${annualReport.id}`"
          >
            {{ $t("components.annual_reports.audit_tab.no_auditor_signatory") }}
          </be-tooltip>

          <be-button
            v-if="showManageAnnualMeetingButton"
            :variant="auditReportDocument ? 'success' : 'outline-secondary'"
            :disabled="loading"
            @click="confirmTriggerEvent('skip_audit', 'phase')"
          >
            {{ $t("components.annual_reports.audit_tab.skip_audit_phase") }}
          </be-button>
        </div>

        <annual-report-signature-form
          v-if="showRequestAuditReportSignatures"
          :available-users="companyUsers || []"
          :annual-report="annualReport"
          :selected-user-ids="annualReport.auditor_ids"
          :default-titles="defaultTitles"
          :doc="signatureDocument"
          @closed="afterSignaturesRequested"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InformUserModal from "@/components/shared/InformUserModal.vue";
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import SignaturesTable from "./SignaturesTable.vue";
import BoardStatementUpload from "./BoardStatementUpload.vue";
import TransitionNotice from "./TransitionNotice.vue";
import DocumentModal from "@/components/documents/DocumentModal.vue";

export default {
  components: {
    SignaturesTable,
    InformUserModal,
    BoardStatementUpload,
    TransitionNotice,
    DocumentModal,
  },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      loading: false,
      timer: null,
      signatureDocument: {},
      informUserDocument: {},
    };
  },

  computed: {
    annualReportState() {
      return this.annualReport.annual_report_state;
    },

    auditReportState() {
      return this.annualReport.audit_report_state;
    },

    auditors() {
      return this.companyUsers.filter((user) =>
        this.annualReport.auditor_ids.includes(user.id)
      );
    },

    auditorsAvailable() {
      return this.auditors.length > 0;
    },

    auditorMemberships() {
      if (!this.auditorsAvailable) {
        return null;
      }

      return this.$currentCompany.memberships.filter((membership) =>
        this.annualReport.auditor_ids.includes(membership.user.id)
      );
    },

    auditorHasSignedAuditReport() {
      if (this.auditReportSignatures) {
        return this.auditReportSignatures.all(
          (signature) => !!signature.signed_at
        );
      }
      return false;
    },

    canSignAuditReport() {
      if (!this.auditReportDocument) {
        return false;
      }

      const userSignatures = this.auditReportDocument.signatures.filter(
        (signature) =>
          !signature.signed_at && signature.user_id == this.$currentUser.id
      );

      return (
        userSignatures &&
        userSignatures.length > 0 &&
        this.can("add_signature", "audit_report")
      );
    },

    showPdfGenerating() {
      return this.auditReportIxbrlDocument && this.isPdfGenerating;
    },

    informAuditorUrl() {
      return this.url(`/annual_reports/${this.annualReport.id}/audit_requests`);
    },

    markedAsManuallySignedTransition() {
      const transitions = this.findTransitions([
        { event: "mark_annual_report_as_manually_signed" },
        { event: "sign_manually", namespace: "annual_report" },
      ]);
      return transitions.length > 0 ? transitions[0] : null;
    },

    boardStatementMarkedAsManuallySignedTransition() {
      const transitions = this.findTransitions([
        { event: "manually_sign_board_statement" },
        { event: "manually_sign", namespace: "board_statement" },
      ]);
      return transitions.length > 0 ? transitions[0] : null;
    },

    auditReportManuallySignedTransition() {
      const transitions = this.findTransitions([
        { event: "audit_report_manually_signed" },
        { event: "sign_manually", namespace: "audit_report" },
      ]);
      return transitions.length > 0 ? transitions[0] : null;
    },

    showAnnualReportSignatoryUnavailableText() {
      const localUserIds = this.companyUsers.map((user) => user.id);
      const signatoriesAvailable = this.annualReport.signatory_ids.every((id) =>
        localUserIds.includes(id)
      );
      return (
        !signatoriesAvailable && this.can("add_signature", "annual_report")
      );
    },

    showAuditReportSignatoryUnavailableText() {
      return (
        this.auditorNeeded &&
        !this.auditorsAvailable &&
        this.can("add_signature", "audit_report")
      );
    },

    showAnnualReportNotSignedText() {
      return (
        this.auditorNeeded &&
        (this.annualReportState == "signatures_requested" ||
          this.annualReportState == "signed_by_organization")
      );
    },

    showAnnualReportNotSignedByOrganisationText() {
      return this.annualReportState == "signatures_requested";
    },

    showAuditReportSignaturesTable() {
      return this.auditReportDocument || this.auditReportIxbrlDocument;
    },

    showAuditReportUploadText() {
      return this.can("upload", "audit_report", "upload");
    },

    showInformAuditorOfAnnualReport() {
      const auditorSignatureNeeded =
        this.annualReportState == "signed_by_organization";

      return (
        this.can("send_to_auditor", "annual_report", "update") &&
        !this.currentUserIsAuditor &&
        !auditorSignatureNeeded
      );
    },

    showManageAnnualMeetingButton() {
      return this.can("skip_audit", "phase", "update");
    },

    showNoAuditReport() {
      return (
        this.auditorsAvailable &&
        !this.showUploadAuditReport &&
        !this.auditReportDocument &&
        !this.showSignatureTable &&
        !this.auditReportIxbrlDocument
      );
    },

    showRequestAuditReportSignatures() {
      return this.can("request_signatures", "audit_report", "update");
    },

    showSignatureTable() {
      return (
        this.annualReportSignatures && this.annualReportSignatures.length > 0
      );
    },

    showBoardStatementSignatures() {
      return (
        this.boardStatementDocument && this.boardStatementSignatures.length > 0
      );
    },

    showSendAuditSignatureReminder() {
      return (
        this.can("add_signature", "audit_report", "update") &&
        !this.currentUserIsAuditor &&
        this.auditorsAvailable
      );
    },

    showUploadAuditReport() {
      return (
        this.can("upload", "audit_report", "upload") &&
        !this.auditReportDocument &&
        !this.auditReportIxbrlDocument
      );
    },

    signByAuditorNowUrl() {
      return this.url(
        `/annual_reports/${this.annualReport.id}/signature_requests`
      );
    },

    lastStateTransition() {
      const transitionsSendToAuditor = this.findTransitions([
        { event: "send_to_auditor_annual_report" },
        { event: "send_to_auditor" },
      ]);

      if (transitionsSendToAuditor.length > 0) {
        return transitionsSendToAuditor[transitionsSendToAuditor.length - 1];
      }

      return null;
    },
  },
};
</script>
