<template>
  <be-modal :id="modalId" :title="modalTitle" size="xl" @show="fetchDecision">
    <single-decision
      v-if="decision"
      :initial-decision="decision"
      @updated="$emit('updated')"
    />

    <be-skeleton-table v-else rows="5" columns="2" />

    <template #footer>
      <be-button variant="light" :href="url(`decisions/${decisionId}`)">
        {{ $t("components.decisions.single_decision_modal.go_to_decision") }}
      </be-button>

      <be-button variant="primary" @click="onClose">
        {{ $t("buttons.titles.close") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import SingleDecision from "./SingleDecision.vue";

export default {
  components: {
    SingleDecision,
  },

  props: {
    decisionId: {
      type: Number,
      required: true,
    },

    modalId: {
      type: String,
      required: true,
    },
  },

  emits: ["updated"],

  data() {
    return {
      decision: null,
    };
  },

  computed: {
    modalTitle() {
      if (!this.decision) {
        return this.$t("activerecord.models.decision.one");
      } else {
        return `${this.$t("activerecord.models.decision.one")} ${this.decision?.human_reference}`;
      }
    },
  },

  methods: {
    async fetchDecision() {
      try {
        const { data } = await axios.get(
          this.url(`/decisions/${this.decisionId}`)
        );

        this.decision = data;
      } catch (error) {
        this.handleError(error);
      }
    },

    onClose() {
      this.decision = null;

      this.$beModal.hide(this.modalId);
    },
  },
};
</script>
