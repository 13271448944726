<template>
  <div>
    <header class="mb-4">
      <h1>
        {{ $t("activerecord.models.report_template.other") }}
      </h1>
    </header>

    <be-tabs
      v-if="allowedToCreate && mode === 'admin_panel'"
      v-model="activeTab"
      :options="tabsData"
    >
      <template #content-right>
        <be-button
          variant="primary"
          :href="url('/reports/templates')"
          data-method="POST"
        >
          {{ $t("models.report_template.create") }}
        </be-button>
      </template>
    </be-tabs>

    <div class="card">
      <div
        v-if="allowedToCreate && mode === 'board_room'"
        class="card-header d-md-flex justify-content-end"
      >
        <be-button
          variant="primary"
          :href="url('/reports/templates')"
          data-method="POST"
        >
          {{ $t("models.report_template.create") }}
        </be-button>
      </div>

      <div class="card-body">
        <be-table
          v-if="reportTemplates.length > 0"
          :items="reportTemplates"
          :fields="fields"
        >
          <template #title="{ item }">
            <be-link @click="previewTemplate(item)">
              {{ item.title }}
            </be-link>

            <div class="float-right text-muted">
              <i
                v-if="item.has_schedule_rules"
                v-be-tooltip="
                  $t('models.report_template.tooltips.has_schedule_rules')
                "
                class="fal fa-clock-rotate-left fa-fw fa-flip-horizontal"
              />
            </div>
          </template>

          <template #created_by="{ item }">
            <div v-if="item.creators">
              {{ item.creators }}
            </div>

            <div v-else-if="item.owner_type == 'AdminPanel' && item.owner">
              {{ item.owner.title }}
            </div>

            <div v-else>-</div>
          </template>

          <template #report_type="{ item }">
            {{
              item.report_type !== "general"
                ? $t(`models.report.types.${item.report_type}`)
                : ""
            }}
          </template>

          <template #updated_at="{ item }">
            <template v-if="item.updated_at">
              {{ $d(new Date(item.updated_at), { format: "year_month" }) }}
            </template>
          </template>

          <template #options="{ item }">
            <be-dropdown v-if="item.policy.update" size="sm" ellipsis>
              <be-dropdown-item
                v-be-tooltip="{
                  title: $t(
                    'views.companies.reports.templates.index.edit_in_admin_panel'
                  ),
                  disabled: !notEditable(item),
                }"
                :disabled="notEditable(item)"
                :href="url(`/reports/templates/${item.id}/edit`)"
              >
                {{ $t("buttons.titles.edit") }}
              </be-dropdown-item>

              <be-dropdown-item
                v-if="allowedToUse"
                :href="url(`/reports/template_applications/${item.id}`)"
                data-method="patch"
                :data-confirm="
                  $t('models.report_template.confirm_use_for_report_w_title', {
                    title: item.title,
                  })
                "
                :data-confirm-button-text="$t('buttons.titles.create')"
              >
                {{ $t("models.report_template.use_for_report") }}
              </be-dropdown-item>

              <be-dropdown-item
                :href="url(`/reports/templates/${item.id}/copy`)"
                :data-confirm="
                  $t('models.report_template.copy_confirm_w_title', {
                    title: item.title,
                  })
                "
                data-method="post"
                :data-confirm-button-text="$t('buttons.titles.create')"
              >
                {{ $t("components.reports.report_options.create_copy") }}
              </be-dropdown-item>

              <be-dropdown-item
                v-if="
                  mode === 'admin_panel' && item.owner_type === 'AdminPanel'
                "
                @click="showPublications(item)"
              >
                {{ clientManagerPublicationsButtonTitle(item) }}
              </be-dropdown-item>

              <be-dropdown-item
                v-if="flipperFlag('report_scheduling')"
                :href="item.paths.report_schedule_rules"
              >
                {{ $t("activerecord.models.report_schedule_rule.other") }}
              </be-dropdown-item>

              <template v-if="item.policy.destroy">
                <be-dropdown-divider />

                <be-dropdown-item
                  v-be-tooltip="{
                    title: $t(
                      'views.companies.reports.templates.index.delete_in_admin_panel'
                    ),
                    disabled: !notEditable(item),
                  }"
                  :disabled="notEditable(item)"
                  variant="danger"
                  @click="deleteTemplate(item)"
                >
                  {{ $t("buttons.titles.remove") }}
                </be-dropdown-item>
              </template>
            </be-dropdown>
          </template>
        </be-table>

        <be-alert v-else variant="info">
          {{ $t("views.companies.reports.templates.index.not_present") }}
        </be-alert>

        <report-template-modal
          v-if="templateForPreview"
          modal-id="template-modal"
          :template="templateForPreview"
          :allowed-to-use="allowedToUse"
          :allowed-to-edit="
            templateForPreview.policy.update && !notEditable(templateForPreview)
          "
        />

        <client-manager-report-publish-modal
          v-if="templateForPublish"
          :report="templateForPublish"
          @report-published="reportPublished"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReportTemplateModal from "./ReportTemplateModal.vue";
import ClientManagerReportPublishModal from "@/components/reports/ClientManagerReportPublishModal.vue";

export default {
  components: {
    ReportTemplateModal,
    ClientManagerReportPublishModal,
  },

  props: {
    templates: {
      type: Array,
      required: true,
    },

    allowedToCreate: {
      type: Boolean,
      require: true,
    },

    allowedToUse: {
      type: Boolean,
      required: false,
      default: true,
    },

    mode: {
      type: String,
      required: false,
      default: "board_room",
    },
  },

  data() {
    return {
      activeTab: "published",
      localTemplates: this.cloneDeep(this.templates),
      templateForPreview: null,
      templateForPublish: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          key: "title",
          label: this.$t("activerecord.attributes.report.title"),
          sortable: true,
          class: "col-shrink col-md-auto",
        },
        {
          key: "created_by",
          label: this.$t("activerecord.attributes.agenda_template.created_by"),
          sortable: true,
          class: "col-shrink",
        },
        {
          key: "report_type",
          label: this.$t("activerecord.attributes.report.report_type"),
          class: "col-shrink",
        },
        {
          key: "updated_at",
          label: this.$t("activerecord.attributes.report.updated_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ];
    },

    localPublished() {
      return this.localTemplates.filter((template) => template.published_at);
    },

    localDrafts() {
      return this.localTemplates.filter((template) => !template.published_at);
    },

    tabsData() {
      if (this.mode == "admin_panel") {
        return {
          published: {
            value: "published",
            label: this.$t("models.report.published"),
            count: this.localPublished.length,
          },

          draft: {
            value: "draft",
            label: this.$t("models.report.drafts"),
            count: this.localDrafts.length,
          },
        };
      } else {
        return {};
      }
    },

    reportTemplates() {
      if (this.activeTab === "published") {
        return this.localPublished;
      } else {
        return this.localDrafts;
      }
    },
  },

  methods: {
    clientManagerPublicationsButtonTitle(template) {
      if (template.published_at) {
        return this.$t(
          "components.reports.client_manager_publications.settings"
        );
      } else {
        return this.$t("buttons.titles.publish");
      }
    },

    showPublications(template) {
      this.templateForPublish = template;
      this.$nextTick(() => {
        this.$beModal.show("client-manager-report-publish-modal");
      });
    },

    reportPublished(template) {
      this.templateForPublish = null;

      const index = this.localTemplates.findIndex(
        (existing) => existing.id === template.id
      );

      if (index > -1) {
        this.localTemplates.splice(index, 1, template);
      }
    },

    notEditable(template) {
      return (
        this.mode !== "admin_panel" && template.owner_type === "AdminPanel"
      );
    },

    previewTemplate(template) {
      this.templateForPreview = template;
      this.$nextTick(() => {
        this.$beModal.show("template-modal");
      });
    },

    async deleteTemplate(template) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: template.title,
        })
      );

      if (!isConfirmed) {
        return;
      }

      try {
        await axios.delete(this.url(`/reports/templates/${template.id}`));

        const index = this.localTemplates.findIndex(
          (existing) => existing.id === template.id
        );

        if (index > -1) {
          this.localTemplates.splice(index, 1);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
