<template>
  <be-modal
    id="tasks-modal"
    :title="title"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    size="lg"
  >
    <div v-if="haveTasks">
      <div class="accordion">
        <task-row
          v-for="task in sortedTasks"
          :key="`task-${task.id}`"
          class="mt-2"
          :task="task"
        />
      </div>
    </div>

    <be-alert v-else variant="info" class="mb-0">
      {{ $t("components.metrics.tasks.empty") }}
    </be-alert>
  </be-modal>
</template>

<script>
import TaskRow from "./TaskRow.vue";

export default {
  components: {
    TaskRow,
  },

  computed: {
    tasks() {
      return this.$store.getters["tasks/getMyTasks"];
    },

    haveTasks() {
      return this.tasks.length > 0;
    },

    sortedTasks() {
      return [...this.tasks].sort((a, z) => (a.due_at > z.due_at ? 1 : -1));
    },

    title() {
      if (this.tasks.length > 1) {
        return this.$t("components.metrics.tasks.title_w_count", {
          count: this.tasks.length,
        });
      }

      return this.$t("components.metrics.tasks.title");
    },
  },
};
</script>
