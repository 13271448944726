<template>
  <form @submit.prevent="saveSettings">
    <div class="row mb-2">
      <div class="col">
        <div class="card mb-3 mb-md-2">
          <div class="card-header">
            {{ $t("admin_panels.admin.settings.edit.contact_information") }}
          </div>

          <div class="card-body">
            <!-- Title -->
            <be-form-group
              :label="$t('attributes.title')"
              label-for="title"
              :description="$t('simple_form.hints.admin_panel.title')"
              :error="getErrors(localAdminPanel, 'title')"
              required
            >
              <be-form-input
                id="title"
                v-model="localAdminPanel.title"
                :disabled="titleDisabled"
                @change="clearErrors(localAdminPanel, 'title')"
              />
            </be-form-group>

            <!-- Email -->
            <be-form-group
              :label="$t('attributes.email')"
              label-for="email"
              :error="getErrors(localAdminPanel, 'email')"
            >
              <be-form-input
                id="email"
                v-model="localAdminPanel.email"
                type="email"
                @change="clearErrors(localAdminPanel, 'email')"
              />
            </be-form-group>

            <!-- Phone -->
            <be-form-group
              :label="$t('simple_form.labels.admin_panel.phone')"
              label-for="phone"
              :error="getErrors(localAdminPanel, 'phone')"
            >
              <be-tel-input
                id="phone"
                v-model="localAdminPanel.phone"
                @change="clearErrors(localAdminPanel, 'phone')"
              />
            </be-form-group>

            <template v-if="allowCustomLogo">
              <be-form-group
                :label="$t('attributes.logo')"
                label-for="logo"
                :error="getErrors(localAdminPanel, 'logo')"
              >
                <single-file-uploader
                  v-model:remove="localAdminPanel.remove_logo"
                  :file="localAdminPanel.logo || {}"
                  :accepted-types="acceptedLogoFileTypes"
                  @file-updated="(file) => (localAdminPanel.logo = file)"
                  @uploading="(value) => (uploadingFiles = value)"
                />
              </be-form-group>
            </template>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card">
          <div class="card-header">
            {{ $t("admin_panels.admin.settings.edit.title") }}
          </div>

          <div class="card-body">
            <!-- SBS Manager Link -->
            <be-form-group
              v-if="inquiriesEnabled"
              :label="
                $t('activerecord.attributes.admin_panel.sbs_manager_link')
              "
              label-for="sbs-manager-link"
              :error="getErrors(localAdminPanel, 'sbs_manager_link')"
            >
              <be-form-input
                id="sbs-manager-link"
                v-model="localAdminPanel.sbs_manager_link"
                @change="clearErrors(localAdminPanel, 'sbs_manager_link')"
              />
            </be-form-group>

            <!-- Default type of organization -->
            <be-form-group
              :label="
                $t(
                  'activerecord.attributes.admin_panel.default_type_of_organization'
                )
              "
              label-for="default-type-of-organization"
              :description="
                $t('simple_form.hints.admin_panel.default_type_of_organization')
              "
              :error="
                getErrors(localAdminPanel, 'default_type_of_organization')
              "
            >
              <be-form-select
                id="default-type-of-organization"
                v-model="localAdminPanel.default_type_of_organization"
                :options="formattedTypeOfOrganizations"
                include-blank-option
                @change="
                  clearErrors(localAdminPanel, 'default_type_of_organization')
                "
              />
            </be-form-group>

            <!-- Annual Reports Email BCC -->
            <be-form-group
              v-if="annualReportsEmailBCCEnabled"
              :label="
                $t(
                  'activerecord.attributes.admin_panel.annual_reports_email_bcc'
                )
              "
              label-for="annual-reports-email-bcc"
              :description="
                $t('simple_form.hints.admin_panel.annual_reports_email_bcc')
              "
              :error="getErrors(localAdminPanel, 'annual_reports_email_bcc')"
            >
              <be-form-textarea
                id="annual-reports-email-bcc"
                v-model="localAdminPanel.annual_reports_email_bcc"
                rows="3"
                @change="
                  clearErrors(localAdminPanel, 'annual_reports_email_bcc')
                "
              />
            </be-form-group>

            <!-- Allowed video meeting domains -->
            <be-form-group
              v-if="allowedVideoMeetingDomainsEnabled"
              :label="
                $t(
                  'simple_form.labels.admin_panel.allowed_video_meeting_domains'
                )
              "
              label-for="allowed-video-meeting-domains"
              :description="
                $t(
                  'simple_form.hints.admin_panel.allowed_video_meeting_domains'
                )
              "
              :error="
                getErrors(localAdminPanel, 'allowed_video_meeting_domains')
              "
            >
              <be-form-textarea
                id="allowed-video-meeting-domains"
                v-model="localAdminPanel.allowed_video_meeting_domains"
                rows="3"
                @change="
                  clearErrors(localAdminPanel, 'allowed_video_meeting_domains')
                "
              />
            </be-form-group>
          </div>

          <div class="card-footer d-md-none">
            <be-button
              variant="primary"
              type="submit"
              :disabled="uploadingFiles"
              :loading="isSaving"
              block
            >
              {{ $t("buttons.titles.save") }}
            </be-button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-md-flex justify-content-end">
      <be-button
        variant="primary"
        type="submit"
        :disabled="uploadingFiles"
        :loading="isSaving"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>
    </div>
  </form>
</template>

<script>
import { IMAGE_TYPES } from "@/constants/file-types";

export default {
  props: {
    adminPanel: {
      type: Object,
      required: true,
    },

    typeOfOrganizations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      localAdminPanel: this.cloneDeep(this.adminPanel),
      uploadingFiles: false,
    };
  },

  computed: {
    inquiriesEnabled() {
      return this.localAdminPanel.manage_enabled_features?.includes(
        "inquiries"
      );
    },

    allowCustomLogo() {
      return this.$theme.allow_custom_admin_panel_logo;
    },

    allowedVideoMeetingDomainsEnabled() {
      return this.$theme.setting_allowed_video_meeting_domains_enabled;
    },

    annualReportsEmailBCCEnabled() {
      return this.$theme.setting_annual_reports_email_bcc_enabled;
    },

    acceptedLogoFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    formattedTypeOfOrganizations() {
      return this.typeOfOrganizations.map((item) => ({
        value: item[1],
        text: item[0],
      }));
    },

    titleDisabled() {
      return this.localAdminPanel.disabled_client_manager_features.includes(
        "client_manager_title"
      );
    },
  },

  methods: {
    async saveSettings() {
      this.isSaving = true;

      const adminPanel = this.cloneDeep(this.localAdminPanel);

      // Filter out the logo if it already exists or if it's pending removal
      if (adminPanel.logo?.upload_state !== "added" || adminPanel.remove_logo) {
        adminPanel.logo = null;
      }

      try {
        const { data } = await axios.patch(this.url("/admin/settings"), {
          admin_panel: adminPanel,
        });

        this.localAdminPanel = data;
      } catch (error) {
        if (error.response.status === 422) {
          this.localAdminPanel = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
