<template>
  <div class="card">
    <div class="card-header rounded">
      <div class="d-flex flex-row w-100">
        <div class="flex-grow-1">
          <div class="d-flex flex-column">
            <div class="row">
              <div v-be-tooltip="decision.description" class="col-auto">
                <h5
                  v-dompurify-html="truncateText(decision.description, 64)"
                  class="text-break text-pre-line mb-0"
                />
              </div>
            </div>

            <h6 class="text-muted" :class="{ 'mb-0': !decision.due_at }">
              {{ company.title }}
            </h6>

            <small v-if="decision.due_at">
              {{
                $t("materials.decision.due_at", {
                  due_at: formatDate(decision.due_at),
                })
              }}
            </small>
          </div>
        </div>

        <div class="ml-2 align-self-center">
          <be-button
            variant="outline-primary"
            size="sm"
            :href="
              url(`/decisions/${decision.id}`, {
                company: company,
              })
            "
          >
            {{ $t("views.home.company.show_decision") }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";

export default {
  mixins: [TextUtilities],

  props: {
    decision: {
      type: Object,
      required: true,
    },
  },

  computed: {
    company() {
      return this.$store.getters["company/getById"](this.decision.company_id);
    },
  },

  methods: {
    formatDate(dateString) {
      return this.$d(this.parseDate(dateString), "date");
    },
  },
};
</script>
