<template>
  <slot :open-modal="openModal" />

  <template v-if="boardPortalCompaniesPresent">
    <meetings-modal :meetings="meetings"></meetings-modal>

    <decisions-modal :decisions="decisions"></decisions-modal>
  </template>

  <tasks-modal></tasks-modal>

  <signatures-modal :pending-signatures="pendingSignatures"></signatures-modal>
</template>

<script>
// Modals
import MeetingsModal from "./modals/MeetingsModal.vue";
import TasksModal from "./modals/TasksModal.vue";
import DecisionsModal from "./modals/DecisionsModal.vue";
import SignaturesModal from "./modals/SignaturesModal.vue";

export default {
  components: {
    // Modals
    MeetingsModal,
    TasksModal,
    DecisionsModal,
    SignaturesModal,
  },

  props: {
    boardPortalCompaniesPresent: {
      type: Boolean,
      required: true,
    },

    meetings: {
      type: Array,
      required: false,
      default: () => [],
    },

    reports: {
      type: Array,
      required: false,
      default: () => [],
    },

    tasks: {
      type: Array,
      required: true,
    },

    decisions: {
      type: Array,
      required: true,
    },

    pendingSignatures: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.$store.commit("tasks/setTasks", this.tasks);
  },

  methods: {
    openModal(modal) {
      this.$beModal.show(modal);
    },
  },
};
</script>
