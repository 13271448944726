<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <!-- Progress container -->
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3>
                {{
                  $t("components.onboarding.introduction.welcome_with_name", {
                    name: localUser.name,
                  })
                }}
              </h3>

              <!-- Skip onboarding on medium and large devices -->
              <be-link
                v-if="confirmedAt"
                v-be-tooltip="$t('components.onboarding.skip_onboarding_info')"
                class="text-muted align-self-center d-none d-md-inline-block"
                :href="url('/users/onboarding?skip=true')"
                data-method="POST"
              >
                {{ $t("components.onboarding.skip_onboarding") }}
              </be-link>
            </div>

            <p class="mb-2">
              {{
                $t("components.onboarding.introduction.steps_completed", {
                  completedSteps: completedNumberOfSteps,
                  totalSteps: totalNumberOfSteps,
                })
              }}
            </p>

            <be-progress class="mt-2">
              <be-progress-bar
                :value="completedNumberOfSteps"
                :max="totalNumberOfSteps"
              />
            </be-progress>

            <!-- Skip onboarding on small devices -->
            <be-button
              v-if="confirmedAt"
              variant="outline-secondary"
              class="d-md-none"
              :href="url('/users/onboarding?skip=true')"
              data-method="POST"
              block
            >
              {{ $t("components.onboarding.skip_onboarding") }}
            </be-button>
          </div>
        </div>

        <!-- Email confirmation -->
        <div class="card mb-3">
          <div class="card-header">
            <div class="row no-gutters">
              <div class="col-auto d-flex align-items-center">
                <i
                  class="fas fa-circle-check mr-3"
                  :class="
                    sectionCompleted('email_confirmation')
                      ? 'text-success'
                      : 'text-light'
                  "
                />
              </div>

              <div class="col">
                <h3 class="mb-0">
                  {{
                    $t("components.onboarding.introduction.finish_registration")
                  }}
                  <span>*</span>
                </h3>
              </div>
            </div>
          </div>

          <be-list-group flush>
            <be-list-group-item class="p-4">
              <task-container
                v-if="confirmedAt"
                action-key="email_confirmed"
                section-type="email_confirmation"
                :onboarding-object="localUser"
                :hide-button-on-completion="true"
                :required="true"
                :title="
                  $t(
                    'components.onboarding.introduction.email_confirmation.confirm_email'
                  )
                "
              >
                <template #text>
                  {{ localUser.email }}
                </template>

                <template #link> </template>
              </task-container>

              <task-container
                v-else
                action-key="email_confirmed"
                section-type="email_confirmation"
                :onboarding-object="localUser"
                :hide-button-on-completion="true"
                :hide-action="true"
                :title="
                  $t(
                    'components.onboarding.introduction.email_confirmation.confirm_email'
                  )
                "
              >
                <template #text>
                  <div class="d-flex flex-column">
                    <div class="mb-3">
                      {{
                        $t(
                          "components.onboarding.introduction.email_confirmation.description"
                        )
                      }}
                    </div>

                    <be-alert v-if="emailAlertText" variant="info">
                      {{ emailAlertText }}
                    </be-alert>

                    <be-form-group
                      class="mb-3"
                      :label="$t('activerecord.attributes.user.email')"
                      label-for="email"
                      :error="getErrors(localUser, ['email'])"
                    >
                      <be-form-input
                        id="email"
                        v-model="email"
                        type="email"
                        autocomplete="username"
                        required
                        @change="clearErrors(localUser, ['email'])"
                      />
                    </be-form-group>

                    <div
                      class="d-md-flex justify-content-between align-items-center"
                    >
                      <div class="mb-3 mb-md-0">
                        <template v-if="confirmationEmailSent">
                          <i class="fa fa-check-circle fa-fw text-success" />

                          <span class="text-muted">
                            {{
                              $t(
                                "components.onboarding.introduction.email_confirmation.email_sent_w_time",
                                {
                                  time: $d(
                                    new Date(localUser.confirmation_sent_at),
                                    dateFormatIfSameDay(
                                      new Date(localUser.confirmation_sent_at)
                                    )
                                  ),
                                }
                              )
                            }}
                          </span>
                        </template>
                      </div>

                      <be-button
                        v-if="confirmationEmailSent"
                        variant="outline-secondary"
                        icon="fa-envelope"
                        @click="updateEmail"
                      >
                        {{
                          $t(
                            "components.onboarding.introduction.email_confirmation.request_confirmation_again"
                          )
                        }}
                      </be-button>

                      <be-button
                        v-else
                        class="btn-block btn-md-inline-block"
                        variant="primary"
                        icon="fa-envelope"
                        @click="updateEmail"
                      >
                        {{
                          $t(
                            "components.onboarding.introduction.email_confirmation.request_confirmation"
                          )
                        }}
                      </be-button>
                    </div>
                  </div>
                </template>

                <template #link>
                  <div class="px-4"></div>
                </template>
              </task-container>
            </be-list-group-item>
          </be-list-group>
        </div>

        <!-- About User -->
        <div class="card mb-3">
          <div class="card-header" :class="{ 'border-bottom-0': !confirmedAt }">
            <div class="row no-gutters">
              <div class="col-auto d-flex align-items-center">
                <i
                  class="fas fa-circle-check mr-3"
                  :class="
                    sectionCompleted('user_info')
                      ? 'text-success'
                      : 'text-light'
                  "
                />
              </div>

              <div class="col">
                <h3 class="mb-0">
                  {{
                    $t(
                      "components.onboarding.introduction.customize_your_account"
                    )
                  }}
                </h3>
              </div>
            </div>
          </div>

          <div v-show="confirmedAt" class="card-body p-0">
            <be-list-group flush>
              <be-list-group-item
                v-if="platformEnabled('onboarding_bankid')"
                class="p-4"
              >
                <task-container
                  action-key="bank_id_registered"
                  section-type="user_info"
                  :onboarding-object="localUser"
                  :hide-button-on-completion="true"
                  :skipped="isSkipped('user_info', 'bank_id_registered')"
                  :title="
                    $t('components.onboarding.introduction.activate_bankid')
                  "
                >
                  <template #text>
                    <div class="mb-2">
                      {{
                        $t(
                          "components.onboarding.introduction.activate_bankid_description"
                        )
                      }}
                    </div>

                    <ul>
                      <li>
                        {{
                          $t(
                            "components.onboarding.introduction.activate_bankid_list_item_one"
                          )
                        }}
                      </li>

                      <li>
                        {{
                          $t(
                            "components.onboarding.introduction.activate_bankid_list_item_two"
                          )
                        }}
                      </li>
                    </ul>
                  </template>

                  <template #link>
                    <div>
                      <div class="d-none d-md-inline-block">
                        <be-link v-be-modal.bankid>
                          {{ $t("buttons.titles.activate") }}
                        </be-link>

                        <p>
                          <be-link
                            v-if="
                              !localUser.identification_number_verified &&
                              !isSkipped('user_info', 'bank_id_registered')
                            "
                            @click="
                              updateProgress(
                                'user_info',
                                'bank_id_registered',
                                false,
                                true
                              )
                            "
                          >
                            <span class="text-muted small">
                              {{ $t("buttons.titles.skip") }}
                            </span>
                          </be-link>
                        </p>
                      </div>

                      <be-button
                        v-be-modal.bankid
                        class="mt-3 order-1 order-md-2 d-md-none"
                        variant="outline-secondary"
                        block
                      >
                        {{ $t("buttons.titles.activate") }}
                      </be-button>

                      <be-button
                        v-if="
                          !localUser.identification_number_verified &&
                          !isSkipped('user_info', 'bank_id_registered')
                        "
                        variant="outline-secondary"
                        class="d-md-none"
                        block
                        @click="
                          updateProgress(
                            'user_info',
                            'bank_id_registered',
                            false,
                            true
                          )
                        "
                      >
                        {{ $t("buttons.titles.skip") }}
                      </be-button>
                    </div>

                    <be-modal
                      id="bankid"
                      :title="$t('bankid.activate_bank_id')"
                      no-footer
                    >
                      <bank-id-authentication
                        begin-authentication-on-mount
                        :redirect-on-success="false"
                        collect-url="/bank_id/collect/activation"
                        @cancel="$beModal.hide('bankid')"
                        @bankid-collect-success="
                          activateBankID();
                          $beModal.hide('bankid-activation-modal');
                        "
                      />
                    </be-modal>
                  </template>
                </task-container>
              </be-list-group-item>

              <be-list-group-item class="p-4">
                <task-container
                  action-key="profile_picture_uploaded"
                  section-type="user_info"
                  :onboarding-object="localUser"
                  :title="
                    $t(
                      'components.onboarding.introduction.upload_profile_picture'
                    )
                  "
                >
                  <template #text>
                    <div class="mb-3">
                      {{
                        $t(
                          "components.onboarding.introduction.upload_profile_picture_description"
                        )
                      }}
                    </div>

                    <i18n-t
                      keypath="components.onboarding.introduction.update_settings_description"
                      tag="p"
                      class="text-muted"
                    >
                      <template #link>
                        <a :href="supportUserSettingsUrl" target="_blank">
                          {{
                            $t(
                              "components.onboarding.introduction.support_guide"
                            )
                          }}
                          <i class="fal fa-external-link small" />
                        </a>
                      </template>
                    </i18n-t>
                  </template>

                  <template #link>
                    <be-link
                      v-be-modal="'upload-profile-picture'"
                      class="d-none d-md-inline-block"
                    >
                      {{ uploadImageText }}
                    </be-link>

                    <be-button
                      v-be-modal="'upload-profile-picture'"
                      class="d-md-none mt-3"
                      variant="outline-secondary"
                      block
                    >
                      {{ uploadImageText }}
                    </be-button>

                    <be-modal
                      id="upload-profile-picture"
                      :title="uploadImageText"
                      :ok-disabled="uploadingFiles || !logoSelected"
                      :ok-title="fileUploadButtonTitle"
                      @ok="uploadProfilePicture"
                    >
                      <single-file-uploader
                        :key="`user-${localUser.id}-image-${localUser.updated_at}`"
                        v-model:remove="localUser.remove_image"
                        :aws-url="awsUrl"
                        :accepted-types="acceptedFileTypes"
                        :file="localUser.image_attachment || {}"
                        name="user[image]"
                        @file-removed="fileRemoved"
                        @file-updated="fileUpdated"
                        @uploading="(value) => (uploadingFiles = value)"
                      />
                    </be-modal>
                  </template>
                </task-container>
              </be-list-group-item>
            </be-list-group>
          </div>

          <div
            v-if="confirmedAt"
            class="card-footer d-flex flex-column flex-lg-row justify-content-md-end"
          >
            <be-button
              variant="success"
              class="order-1 order-lg-2"
              :href="url('/users/onboarding')"
              data-method="POST"
            >
              {{ $t("components.onboarding.introduction.get_started") }}!
            </be-button>
          </div>
        </div>

        <!-- Support contact -->
        <div v-if="$platform.theme.theme_name !== 'bank1'" class="card">
          <div class="card-header">
            <h3 class="mb-0">
              {{ $t("components.onboarding.we_are_here_for_you") }}
            </h3>
          </div>

          <be-list-group flush>
            <be-list-group-item class="py-4">
              <div class="d-md-flex justify-content-betweeen">
                <div>
                  <h6>
                    {{ $t("components.onboarding.contact.support_center") }}
                  </h6>

                  <div class="mb-2 mb-md-0">
                    {{
                      $t(
                        "components.onboarding.introduction.support_description"
                      )
                    }}
                  </div>
                </div>

                <be-link :href="supportUrl" class="text-nowrap ml-md-3">
                  {{ $t("components.onboarding.introduction.visit_support") }}
                </be-link>
              </div>
            </be-list-group-item>

            <be-list-group-item
              v-if="platformEnabled('onboarding_faq')"
              class="py-4"
            >
              <div class="d-md-flex justify-content-betweeen">
                <div>
                  <h6>{{ $t("components.onboarding.introduction.faq") }}</h6>

                  <div class="mb-2 mb-md-0">
                    {{
                      $t("components.onboarding.introduction.faq_description")
                    }}
                  </div>
                </div>

                <be-link
                  :href="faqURL"
                  class="text-nowrap ml-md-3 mt-2 mt-md-0"
                >
                  {{ $t("components.onboarding.introduction.read_faq") }}
                </be-link>
              </div>
            </be-list-group-item>
          </be-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TaskContainer from "@/components/onboarding/TaskContainer.vue";
import { IMAGE_TYPES } from "@/constants/file-types";
import omit from "lodash/omit";

export default {
  components: {
    TaskContainer,
  },

  props: {
    awsUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      slide: null,
      uploadingFiles: false,
      logoSelected: null,
      localUser: {},
      emailPending: "",
    };
  },

  computed: {
    ...mapGetters({
      onboardingProgress: "current_user/getOnboardingProgress",
    }),

    email: {
      get() {
        return this.localUser.email || this.localUser.unconfirmed_email;
      },

      set(value) {
        this.localUser.email = value;
        this.localUser.unconfirmed_email = value;
      },
    },

    confirmedAt() {
      return this.localUser.confirmed_at;
    },

    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    fileUploadButtonTitle() {
      return this.uploadingFiles
        ? this.$i18n.t("buttons.states.loading")
        : this.$i18n.t("buttons.titles.upload");
    },

    totalNumberOfSteps() {
      let onboardingProgress = this.cloneDeep(this.onboardingProgress);
      onboardingProgress = this.progressWithoutHelpSection(onboardingProgress);

      // Remove steps for BankId and FAQ since they are not visible for Fortnox users
      if (!this.platformEnabled("onboarding_bankid")) {
        onboardingProgress = this.progressWithoutBankID(onboardingProgress);
      }

      return Object.values(onboardingProgress).reduce((totalSteps, steps) => {
        return totalSteps + Object.keys(steps).length;
      }, 0);
    },

    completedNumberOfSteps() {
      let onboardingProgress = this.cloneDeep(this.onboardingProgress);
      onboardingProgress = this.progressWithoutHelpSection(onboardingProgress);

      return Object.values(onboardingProgress).reduce((totalSteps, steps) => {
        return (
          totalSteps +
          Object.values(steps).filter((stepCompleted) => {
            return stepCompleted == true;
          }).length
        );
      }, 0);
    },

    sectionCompleted() {
      let onboardingProgress = this.cloneDeep(this.onboardingProgress);

      // Remove steps for BankId and FAQ since they are not visible for Fortnox users
      if (!this.platformEnabled("onboarding_bankid")) {
        onboardingProgress = this.progressWithoutBankID(onboardingProgress);
      }

      return (sectionKey) => {
        return !Object.keys(onboardingProgress[sectionKey]).filter(
          (actionKey) => !onboardingProgress[sectionKey][actionKey]
        ).length;
      };
    },

    confirmationEmailSent() {
      return (
        this.localUser.confirmation_sent_at &&
        this.localUser.confirmed_at == null &&
        this.emailPending
      );
    },

    emailAlertText() {
      if (this.emailPending) {
        return this.$t(
          "components.onboarding.introduction.email_confirmation.email_pending"
        );
      } else {
        return this.$t(
          "components.onboarding.introduction.email_confirmation.no_unconfirmed_email"
        );
      }
    },

    uploadImageText() {
      if (this.localUser?.progress?.user_info?.profile_picture_uploaded) {
        return this.$t("components.onboarding.change_image");
      } else {
        return this.$t("buttons.titles.upload");
      }
    },

    supportUserSettingsUrl() {
      if (this.$currentUser.locale === "sv") {
        return "https://support.boardeaser.com/hc/sv/articles/360021233440-Hur-%C3%A4ndrar-jag-mina-personliga-inst%C3%A4llningar-";
      }

      return "https://support.boardeaser.com/hc/en-gb/articles/360021233440-How-do-I-change-my-personal-settings-";
    },

    supportUrl() {
      if (this.$currentUser.locale === "sv") {
        return "https://support.boardeaser.com/hc/sv";
      }

      return "https://support.boardeaser.com/hc/en-gb";
    },

    faqURL() {
      if (this.$currentUser.locale === "sv") {
        return "https://boardeaser.com/om-oss/faq/";
      }

      // Use swedish page for now, will be replaced later
      return "https://boardeaser.com/om-oss/faq/";
    },
  },

  mounted() {
    this.localUser = this.cloneDeep(this.$currentUser);
    this.emailPending = this.email;
    this.logoSelected = !!this.localUser.image_attachment;
  },

  methods: {
    activateBankID() {
      this.updateProgress("user_info", "bank_id_registered");

      this.$beModal.hide("bankid");
    },

    async updateEmail() {
      if (!this.email) {
        this.localUser.errors = {
          ...this.localUser.errors,
          email: this.$t("errors.messages.blank"),
        };
        return;
      }
      try {
        const { data } = await axios.patch("/users/onboarding/email", {
          user: {
            email: this.email,
          },
        });
        this.localUser = data;
        this.emailPending = this.email;
      } catch (error) {
        if (error.response?.status === 422) {
          this.localUser = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    fileUpdated(file) {
      this.localUser.image_attachment = file;
      this.localUser.image = file;

      this.logoSelected = true;
      this.uploadingFiles = false;
    },

    isSkipped(sectionKey, actionKey) {
      return this.localUser.onboarding_skipped_steps?.includes(
        `${sectionKey}.${actionKey}`
      );
    },

    async fileRemoved() {
      this.localUser.image = null;
      this.localUser.image_attachment = null;

      try {
        const response = await axios.patch("/users/profile", {
          user: this.localUser,
        });

        this.updateUser(response.data);

        this.logoSelected = false;
      } catch (error) {
        this.handleError(error);
      }
    },

    async uploadProfilePicture() {
      try {
        const response = await axios.patch("/users/profile", {
          user: this.localUser,
        });

        this.updateUser(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async updateProgress(
      sectionType,
      actionKey,
      completed = true,
      skipped = false
    ) {
      try {
        const response = await axios.patch("/users/onboarding/progress", {
          user: {
            section_type: sectionType,
            action_key: actionKey,
            completed: completed,
            skipped: skipped,
          },
        });

        this.updateUser(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async updateUser(updatedUser) {
      this.localUser = this.cloneDeep(updatedUser);
      this.$store.commit("current_user/setUser", this.localUser);
    },

    progressWithoutHelpSection(onboardingProgress) {
      onboardingProgress.support = omit(
        onboardingProgress.support,
        "faq_visited"
      );

      onboardingProgress.support = omit(
        onboardingProgress.support,
        "support_visited"
      );

      return onboardingProgress;
    },

    progressWithoutBankID(onboardingProgress) {
      onboardingProgress.user_info = omit(
        onboardingProgress.user_info,
        "bank_id_registered"
      );

      return onboardingProgress;
    },
  },
};
</script>
