<template>
  <div class="card border-left-0 mb-2">
    <!-- Header with actions -->
    <div class="d-flex align-items-center" @click.self="toggleExpanded">
      <div
        v-if="batchEnabled"
        :class="[
          `py-3`,
          {
            'pl-3 pr-1': !batchSaving,
            'px-3': !!batchSaving,
          },
        ]"
        @click="selectInquiry"
      >
        <be-form-checkbox
          v-if="!batchSaving"
          :id="`inquiry-${inquiry.id}-checkbox`"
          :checked="selected"
          class="mr-1"
          @change="selectInquiry"
        />

        <be-spinner v-else>
          {{ $t("buttons.states.loading") }}
        </be-spinner>
      </div>

      <h6 class="mb-1 pl-3">{{ inquiry.title }}</h6>

      <div
        class="d-flex align-items-center ml-auto p-3"
        @click="toggleExpanded"
      >
        <span v-if="inquiryItem.status === 'approved'" class="text-muted small">
          {{ $t("views.companies.inquiries.inquiry.granted_value") }}:
          <money-format
            :currency="inquiry.currency"
            :value="Number(inquiryItem.granted_value) || 0"
            class="mr-3"
          />
        </span>

        <inquiry-status-badge
          v-if="inquiryItem.status"
          class="mr-3"
          :inquiry="inquiryItem"
        />

        <i
          :class="[
            'far fa-chevron-down text-muted',
            { 'fa-flip-vertical': expanded },
          ]"
        />
      </div>
    </div>

    <!-- Accordion -->
    <be-collapse v-model="expanded">
      <div class="border-top p-3 text-body">
        <!-- Description -->
        <div v-if="inquiry.description" class="row mb-3">
          <div class="col">
            <p class="font-weight-bold mb-1">
              {{ $t("activerecord.attributes.inquiry.description") }}
            </p>

            <p>{{ inquiry.description }}</p>
          </div>
        </div>

        <div class="row mb-2 flex-column flex-md-row">
          <!-- External ID -->
          <div class="col">
            <p class="font-weight-bold mb-1">
              {{ $t("activerecord.attributes.inquiry.external_id") }}
            </p>

            <p>{{ inquiry.external_id }}</p>
          </div>

          <!-- Receiver -->
          <div class="col mt-3 mt-md-0">
            <p class="font-weight-bold mb-1">
              {{ $t("activerecord.attributes.inquiry.receiver") }}
            </p>

            <p>
              <template v-if="inquiry.inquiry_receiver?.name">
                {{ inquiry.inquiry_receiver.name }}
              </template>

              <template v-else> - </template>
            </p>
          </div>

          <!-- Link -->
          <div v-if="inquiry.link" class="col mt-3 mt-md-0">
            <p class="font-weight-bold mb-1">
              {{ $t("activerecord.attributes.inquiry.link") }}
            </p>

            <p>
              <be-link :href="inquiry.link">
                {{ $t("views.companies.inquiries.table.go_to_inquiry") }}
              </be-link>
            </p>
          </div>

          <!-- Granted Value -->
          <div class="col mt-3 mt-md-0">
            <p class="font-weight-bold mb-1">
              {{ valueTitle }}
            </p>

            <div v-if="!status && !batchSaving">
              <be-form-input
                v-model="inquiryItem.granted_value"
                type="number"
                size="sm"
              />

              <p class="text-muted small">
                {{
                  $t("activerecord.attributes.inquiry_item.requested_value")
                }}:
                <money-format
                  :currency="inquiry.currency"
                  :value="Number(inquiry.requested_value)"
                />
              </p>
            </div>

            <div v-else>
              <money-format
                :currency="inquiry.currency"
                :value="
                  Number(inquiryItem.granted_value) ||
                  Number(inquiry.requested_value)
                "
              />
            </div>
          </div>
        </div>

        <!-- Comments -->
        <div class="row mb-3 mt-3 mt-md-0">
          <div class="col">
            <p class="font-weight-bold mb-1">
              {{ $t("activerecord.attributes.inquiry_item.comment") }}
            </p>

            <be-form-textarea
              v-if="!status && !batchSaving"
              v-model="inquiryItem.comment"
            />

            <p
              v-else
              v-dompurify-html="inquiryItem.comment || '-'"
              class="ql-viewer"
            />
          </div>
        </div>

        <div v-if="!batchSaving" class="row border-top pt-3">
          <div v-if="!status" class="col-12 col-md-auto ml-auto">
            <be-button
              variant="outline-danger"
              size="sm"
              @click="handleUpdate('rejected')"
            >
              {{ $t("views.companies.inquiries.inquiry.reject") }}
            </be-button>

            <be-button
              variant="outline-secondary"
              size="sm"
              @click="handleUpdate('postponed')"
            >
              {{ $t("views.companies.inquiries.inquiry.postpone") }}
            </be-button>

            <be-button
              variant="outline-success"
              size="sm"
              @click="handleUpdate('approved')"
            >
              {{ $t("views.companies.inquiries.inquiry.approve") }}
            </be-button>
          </div>

          <div v-else class="col-12 col-md-auto ml-auto">
            <be-button
              variant="outline-secondary"
              size="sm"
              icon="fa-rotate-left"
              @click="handleDelete"
            >
              {{ $t("buttons.titles.undo") }}
            </be-button>
          </div>
        </div>
      </div>
    </be-collapse>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import InquiryStatusBadge from "@/components/inquiries/components/StatusBadge.vue";

export default {
  components: { InquiryStatusBadge },

  props: {
    inquiry: {
      type: Object,
      required: true,
    },

    selectedInquiriesIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    savingBatch: {
      type: Array,
      default: () => [],
    },

    itemId: {
      type: Number,
      required: true,
    },

    meetingId: {
      type: Number,
      required: true,
    },

    materialId: {
      type: Number,
      required: true,
    },

    batchEnabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["select"],

  data() {
    return {
      expanded: true,
    };
  },

  computed: {
    inquiryItem() {
      return this.inquiry.latest_inquiry_item || {};
    },

    selected() {
      return this.selectedInquiriesIds.includes(this.inquiry.id);
    },

    batchSaving() {
      return this.savingBatch.find((inquiry) => inquiry.id === this.inquiry.id);
    },

    valueTitle() {
      switch (this.status) {
        case "approved":
          return this.$t("activerecord.attributes.inquiry_item.granted_value");
        case "rejected":
          return this.$t("views.companies.inquiries.inquiry.rejected_value");
        case "postponed":
          return this.$t("views.companies.inquiries.inquiry.requested_value");
        default:
          return this.$t("activerecord.attributes.inquiry_item.granted_value");
      }
    },

    status() {
      return this.inquiryItem.status;
    },
  },

  mounted() {
    if (this.inquiryItem.status) {
      this.expanded = false;
    }
  },

  methods: {
    ...mapActions({
      updateInquiryItem: "inquiries/updateInquiryItem",
      deleteInquiryItem: "inquiries/removeInquiryItem",
    }),

    ...mapMutations({
      setInquiryItem: "inquiries/setInquiryItem",
    }),

    async handleUpdate(status) {
      const inquiryItem = {
        ...this.inquiryItem,

        granted_value:
          status === "approved" ? Number(this.inquiryItem.granted_value) : null,

        status: status,
      };

      await this.updateInquiryItem({
        url: this.url(
          `/meetings/${this.meetingId}/materials/${this.materialId}/inquiry_items`
        ),

        inquiryItem: inquiryItem,
      });

      this.expanded = false;
    },

    async handleDelete() {
      await this.deleteInquiryItem({
        url: this.url(
          `/meetings/${this.meetingId}/materials/${this.materialId}/inquiry_items`
        ),

        inquiryItem: this.inquiryItem,
      });
    },

    selectInquiry() {
      this.$emit("select", this.inquiry.id);
    },

    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
