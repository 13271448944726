<template>
  <div>
    <div :id="id" />
  </div>

  <visualby-annual-report-bridge v-show="initialized" />
</template>

<script>
import VisualbyAnnualReportBridge from "@/components/annual_reports/VisualbyAnnualReportBridge.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VisualbyAnnualReportBridge,
  },

  props: {
    identificationNumber: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      id: "editor",
      initialized: false,
      showNoGroupWarning: false,
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),
  },

  mounted() {
    this.waitForFinancials(this.initializeComponent);
  },

  methods: {
    initializeComponent() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;

      window
        .wzGetController()
        .addAnnualReport(
          `#${this.id}`,
          this.company.corporate_group_uuid,
          this.identificationNumber
        );
    },
  },
};
</script>
