<template>
  <div>
    <be-tabs
      v-if="tabsData.length > 1"
      v-model="activeTab"
      :options="tabsData"
      tab-id="minutes"
    />

    <div v-if="activeTab === 'pdf'" class="card">
      <div class="card-body px-lg-6 pt-lg-6">
        <be-file-viewer :file-url="pdfUrl" file-type="application/pdf" />
      </div>
    </div>

    <material-show
      v-if="activeTab === 'material'"
      :initial-meeting="meeting"
      :initial-material="minutes"
      :live-update="liveUpdate"
    >
      <template #after-material>
        <minutes-review-table
          :meeting="meeting"
          :reviews="reviewsWithAttendances"
        />

        <minutes-review-form
          v-if="currentUserAttendance"
          :meeting="meeting"
          :material="minutes"
          :reviews="reviewsWithAttendances"
          :initial-attendance="currentUserAttendance"
          @review-added="localReviews.push($event)"
        />
      </template>
    </material-show>

    <minutes-approval-form
      v-if="minutes.external_uploaded_at && canApprove"
      :attendances="attendances"
      :meeting="meeting"
      :minutes="minutes"
    />

    <div v-if="canApprove || canHandleReviews" class="card">
      <div class="card-body d-md-flex justify-content-end">
        <template v-if="!minutes.external_uploaded_at && canApprove">
          <be-button
            variant="danger"
            :icon="!isSecretary ? 'fa-envelope' : null"
            @click="decline"
          >
            {{ $t("models.material.minutes.decline") }}
          </be-button>

          <be-button
            variant="success"
            :icon="hasActiveFeature('e-signature') ? 'fa-envelope' : null"
            @click="approve"
          >
            {{ $t("models.material.minutes.approve") }}
          </be-button>
        </template>

        <be-button
          v-if="canHandleReviews"
          variant="outline-primary"
          :href="`${meeting.paths.base}/minutes/review`"
        >
          {{ $t("components.meetings.material.editor.review") }}
        </be-button>
      </div>
    </div>

    <material-pdf-modal
      :meeting="meeting"
      :material="minutes"
      :version="version"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import MinutesApprovalForm from "./MinutesApprovalForm.vue";
import MinutesReviewForm from "./MinutesReviewForm.vue";
import MinutesReviewTable from "./MinutesReviewTable.vue";

export default {
  components: {
    MinutesApprovalForm,
    MinutesReviewForm,
    MinutesReviewTable,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    minutes: {
      type: Object,
      required: true,
    },

    initialAttendances: {
      type: Array,
      required: true,
    },

    reviews: {
      type: Array,
      required: true,
    },

    liveUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },

    version: {
      type: String,
      required: false,
      default: "",
    },

    pdfUrl: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      activeTab:
        this.version || this.minutes.external_uploaded_at ? "pdf" : "material",

      localReviews: this.cloneDeep(this.reviews),
    };
  },

  computed: {
    ...mapGetters({
      attendances: "attendances/attendances",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    tabsData() {
      return [
        {
          value: "material",
          label: this.$t("activerecord.models.minutes.one"),
          enabled: !this.version && !this.minutes.external_uploaded_at,
        },

        {
          value: "pdf",
          label: "PDF",
          enabled: !!this.pdfUrl,
        },
      ].filter((tab) => tab.enabled);
    },

    reviewsWithAttendances() {
      return this.localReviews.map((review) => {
        return {
          ...review,
          attendance: this.attendanceById[review.attendance_id],
        };
      });
    },

    attendanceById() {
      const attendances = {};

      this.attendances.forEach((attendance) => {
        attendances[attendance.id] = attendance;
      });

      return attendances;
    },

    canApprove() {
      return (
        this.meeting.process.active_state === "approve" &&
        (this.platformAdmin || this.isSecretary)
      );
    },

    canHandleReviews() {
      return this.platformAdmin || this.isSecretary;
    },

    currentUserAttendance() {
      return this.attendances.find(
        (attendance) => attendance.user_id === this.$currentUser.id
      );
    },

    isSecretary() {
      return this.currentUserAttendance?.secretary;
    },

    showPdfTab() {
      return this.pdfUrl && !this.minutes.external_uploaded_at;
    },
  },

  async created() {
    this.loadAttendances(this.initialAttendances);
  },

  methods: {
    ...mapMutations("attendances", ["loadAttendances"]),

    async approve() {
      let title = this.$t("models.material.minutes.confirm_approve");
      let text = "";

      if (this.hasActiveFeature("e-signature")) {
        title = this.$t("models.material.minutes.confirm_approve_esign");
        text = this.$t("models.material.minutes.confirm_approve_description");
      }

      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("models.material.minutes.approve"),
        title: title,
        description: text,
      });

      if (!isConfirmed) {
        return;
      }

      try {
        this.loading = true;
        await axios.post(`${this.meeting.paths.base}/minutes/approval`);
        window.location = `${this.meeting.paths.base}`;
      } catch (error) {
        this.handleError(error);
        this.loading = false;
      }
    },

    async decline() {
      let text = this.$t("models.material.minutes.confirm_decline_description");

      if (!this.isSecretary) {
        text = this.$t(
          "models.material.minutes.confirm_decline_sends_email_description"
        );
      }

      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("models.material.minutes.decline"),
        title: this.$t("models.material.minutes.confirm_decline"),
        description: text,
      });

      if (!isConfirmed) {
        return;
      }

      try {
        this.loading = true;
        await axios.post(`${this.meeting.paths.base}/minutes/decline`);
        window.location.href = `${this.meeting.paths.base}/minutes`;
      } catch (error) {
        this.handleError(error);
        this.loading = false;
      }
    },
  },
};
</script>
