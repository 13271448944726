<template>
  <form>
    <template v-if="bankIdState === 'successful'">
      <be-alert variant="success" loading>
        {{ bankIdMessage }}
      </be-alert>
    </template>

    <template
      v-else-if="
        [
          'failed',
          'complete_no_user_found',
          'complete_already_activated',
        ].includes(bankIdState)
      "
    >
      <be-alert variant="danger">
        <span v-if="bankIdHintCode === 'startFailed' && mode === 'qr'">
          {{ $t("bankid.user_messages.rfa17_b") }}
        </span>

        <span
          v-else-if="bankIdHintCode === 'startFailed' && mode === 'autostart'"
        >
          {{ $t("bankid.user_messages.rfa17_a") }}
        </span>

        <div v-else v-dompurify-html="bankIdMessage"></div>
      </be-alert>

      <be-button variant="primary" block @click="retry">
        {{ $t("buttons.titles.try_again") }}
      </be-button>
    </template>

    <template v-else-if="bankIdPending">
      <template v-if="mode == 'qr'">
        <div class="d-flex flex-row justify-content-center my-4">
          <div class="d-flex flex-column align-items-center">
            <template
              v-if="
                !bankIdHintCode || bankIdHintCode === 'outstandingTransaction'
              "
            >
              <div class="text-center">
                {{ $t("login.qr_code_instructions") }}
              </div>

              <div class="mt-4">
                <qrcode-vue
                  v-if="qrData"
                  :value="qrData"
                  :size="200"
                  :data-value="qrData"
                ></qrcode-vue>

                <be-skeleton v-else height="200px" width="200px" />
              </div>

              <be-link
                class="mt-4 text-center"
                :href="autoStartUrl"
                @click.prevent="startOnThisDevice"
              >
                {{ $t("login.use_bank_id_on_this_device_instead") }}
              </be-link>
            </template>

            <be-alert v-else-if="bankIdMessage" variant="info" class="mb-4">
              <div v-dompurify-html="bankIdMessage"></div>
            </be-alert>

            <template v-else>
              <div class="d-flex flex-row justify-content-center my-4">
                <div class="d-flex flex-column align-items-center text-center">
                  <be-spinner />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <template v-else-if="mode == 'autostart'">
        <div class="d-flex flex-row justify-content-center my-4">
          <div class="d-flex flex-column align-items-center text-center">
            <be-button variant="primary" :href="autoStartUrl">
              {{ $t("login.open_bank_id") }}
            </be-button>

            <be-link class="mt-4" @click.stop="startOnOtherDevice">
              {{ $t("login.use_bank_id_on_another_device_instead") }}
            </be-link>
          </div>
        </div>
      </template>

      <be-button
        v-if="bankIdPending"
        variant="danger"
        size="lg"
        block
        :disabled="creatingOrder"
        @click="
          $emit('cancel');
          cancelBankId();
          creatingOrder = false;
        "
      >
        {{ $t("buttons.titles.cancel") }}
      </be-button>
    </template>

    <div
      v-else-if="!creatingOrder && !bankIdMessage"
      class="d-flex flex-column gap-2"
    >
      <be-button
        variant="primary"
        class="d-flex align-items-center justify-content-center"
        block
        @click="primaryLogin"
      >
        <be-img
          :src="imageSrc('icons/bank_id/BankID_logo_white.svg')"
          class="my-n2 mr-1 align-self-start"
          width="48"
          height="48"
        />

        {{ primaryLoginButtonText }}
      </be-button>

      <be-button
        variant="primary"
        class="d-flex align-items-center justify-content-center"
        block
        @click="secondaryLogin"
      >
        <be-img
          :src="imageSrc('icons/bank_id/BankID_logo_white.svg')"
          class="my-n2 mr-1"
          width="48"
          height="48"
        />

        {{ secondaryLoginButtonText }}
      </be-button>
    </div>
  </form>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import isMobile from "is-mobile";
import BankIdMixin from "@/mixins/BankId";

export default {
  components: {
    QrcodeVue,
  },

  mixins: [BankIdMixin],

  props: {
    collectUrl: {
      type: String,
      required: true,
    },

    redirectOnSuccess: {
      type: Boolean,
      required: false,
      default: true,
    },

    beginAuthenticationOnMount: {
      type: Boolean,
      required: false,
      default: false,
    },

    returningToOauth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["cancel"],

  data() {
    return {
      identificationNumber: "",
      displayQrCode: false,
      mode: null,
      creatingOrder: false,
    };
  },

  computed: {
    primaryLoginButtonText() {
      if (isMobile()) {
        return this.$t("login.open_bank_id");
      } else {
        return this.$t("login.mobile_bank_id");
      }
    },

    secondaryLoginButtonText() {
      if (isMobile()) {
        return this.$t("login.bank_id_on_another_device");
      } else {
        return this.$t("login.bank_id_on_this_device");
      }
    },
  },

  async mounted() {
    this.bankIdRedirectOnSuccess = this.redirectOnSuccess;
    this.bankIdCollectUrl = this.collectUrl;
    this.initializeMode();
    if (this.beginAuthenticationOnMount) {
      this.creatingOrder = true;
      await this.createOrder({ qr: this.mode === "qr" });
      this.creatingOrder = false;

      if (this.mode === "autostart") {
        window.location.href = this.autoStartUrl;
      }
    }
  },

  beforeUnmount() {
    if (this.bankIdState === "pending") this.cancelBankId();
    this.creatingOrder = false;
  },

  methods: {
    isMobile,

    async primaryLogin() {
      await this.createOrder();
      if (isMobile()) {
        this.startOnThisDevice();
      } else {
        this.startOnOtherDevice();
      }
    },

    async secondaryLogin() {
      await this.createOrder();
      if (isMobile()) {
        this.startOnOtherDevice();
      } else {
        this.startOnThisDevice();
      }
    },

    async retry() {
      this.initializeMode();
      await this.createOrder();
    },

    initializeMode() {
      if (isMobile()) {
        this.mode = "autostart";
      } else {
        this.mode = "qr";
      }
    },

    startOnThisDevice() {
      this.mode = "autostart";
      window.location.href = this.autoStartUrl;
    },

    async startOnOtherDevice() {
      this.mode = "qr";
    },
  },
};
</script>
