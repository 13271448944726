<template>
  <div>
    <div class="row">
      <div class="col-12" :class="{ 'col-lg-6': showSuggestions }">
        <div class="card mb-2">
          <div
            v-dompurify-html="
              $t(
                'components.admin_panel.users.invite_to_companies.description_html'
              )
            "
            class="card-body"
          />
        </div>
      </div>

      <div
        v-if="showSuggestions && suggestionOptions.length > 0"
        class="col-12 col-lg-6"
      >
        <div class="card mb-2">
          <div class="card-header">
            <h5 class="card-title" label-for="suggestion">
              {{ $t("components.memberships.form.fetch_suggestion") }}
            </h5>
          </div>

          <div class="card-body">
            <p>
              {{
                $t(
                  "components.admin_panel.users.invite_to_companies.description_syna"
                )
              }}
            </p>

            <be-form-select
              id="suggestion"
              v-model="selectedSuggestion"
              :options="suggestionOptions"
              option-label="title"
              option-value="title"
            >
              <template #option="{ option }">
                <div class="d-flex align-items-center gap-1">
                  <span class="text-nowrap">{{ option.title }}</span>

                  <small class="text-muted">({{ option.subtitle }})</small>
                </div>
              </template>
            </be-form-select>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header">
        <h5 class="card-title">
          {{ $t("components.admin_panel.users.invite_to_companies.add_user") }}
        </h5>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12 col-xl-6 mb-3 mb-md-0">
            <h4 class="mb-3">
              {{ $t("activerecord.models.company.other") }}
            </h4>

            <be-form-group>
              <multi-select
                :items="companies"
                :preselected-ids="selectedCompanyIds"
                key-selector="id"
                value-selector="title"
                :state="validationState(localMembership, 'organizations')"
                :invalid-feedback="getErrors(localMembership, 'organizations')"
                @change="onSelectedCompanyChange"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-xl-6">
            <h4 class="mb-3">
              {{
                $t(
                  "components.admin_panel.users.invite_to_companies.user_information"
                )
              }}
            </h4>

            <be-form-group
              label-for="user-name"
              :label="$t('activerecord.attributes.user.name')"
              :error="getErrors(localMembership, 'name')"
            >
              <be-form-input
                id="user-name"
                v-model="localMembership.user.name"
                required
                @change="
                  clearSuggestion() && clearErrors(localMembership, 'name')
                "
              />
            </be-form-group>

            <be-form-group
              label-for="user-email"
              :label="$t('activerecord.attributes.user.email')"
              :error="getErrors(localMembership, 'email')"
            >
              <be-form-input
                id="user-email"
                v-model="localMembership.user.email"
                type="email"
                required
                @change="clearErrors(localMembership, 'email')"
              />
            </be-form-group>

            <be-form-group
              v-if="showIdentificationNumber"
              label-for="membership-user-identification_number"
              :label="translateAttribute('user', 'identification_number')"
              :error="getErrors(localMembership, 'identification_number')"
              :description="$t('models.user.hints.identification_number')"
              required
            >
              <be-form-input
                id="membership-user-identification_number"
                v-model="localMembership.user.identification_number"
                :formatter="formatIdentificationNumber"
                required
                inputmode="numeric"
                trim
                @change="
                  clearErrors(localMembership.user, 'identification_number')
                "
              />
            </be-form-group>

            <locale-input
              id="user-locale"
              :value="localMembership.user.locale"
              :label="`${translateAttribute('user', 'locale')} *`"
              :state="validationState(localMembership, 'locale')"
              :error="getErrors(localMembership, 'locale')"
              @input="localMembership.user.locale = $event"
              @change="clearErrors(localMembership, 'locale')"
            ></locale-input>

            <be-form-group
              label-for="membership-role"
              :label="translateAttribute('membership', 'role')"
              :error="getErrors(localMembership, 'role')"
            >
              <be-form-select
                id="membership-role"
                v-model="localMembership.role"
                :state="validationState(localMembership, 'role')"
                :options="roleOptions"
                include-blank-option
                @change="clearErrors(localMembership, 'role')"
              />
            </be-form-group>

            <be-form-group
              label-for="membership-function"
              :label="translateAttribute('membership', 'function')"
              :error="getErrors(localMembership, 'function')"
              class="mb-4"
              required
            >
              <be-form-select
                id="membership-function"
                v-model="localMembership.function"
                :state="validationState(localMembership, 'function')"
                :options="functionOptions"
                required
                @change="clearErrors(localMembership, 'function')"
              />
            </be-form-group>

            <be-form-group
              label-for="membership-policy-level"
              :label="translateAttribute('membership', 'policy_level')"
              :error="getErrors(localMembership, 'policy_level')"
              required
            >
              <be-form-radio-group
                id="membership-policy-level"
                v-model="localMembership.policy_level"
                class="d-inline-block mb-2"
                :state="validationState(localMembership, 'policy_level')"
                :options="policyLevelOptions"
                @change="clearErrors(localMembership, 'policy_level')"
              />
            </be-form-group>

            <be-form-checkbox
              id="membership-primary-contact"
              v-model="localMembership.primary_contact"
              name="membership-primary-contact"
              :description="$t('models.membership.hints.primary_contact')"
              class="mb-2"
            >
              {{ translateAttribute("membership", "primary_contact") }}
            </be-form-checkbox>

            <be-form-checkbox
              id="send-invitation-email"
              v-model="sendInvitationEmail"
              name="send-invitation-email"
              :description="
                $t(
                  'components.admin_panel.users.invite_to_companies.send_email_hint'
                )
              "
            >
              {{
                $t(
                  "components.admin_panel.users.invite_to_companies.send_email"
                )
              }}
            </be-form-checkbox>
          </div>
        </div>
      </div>

      <div class="card-footer d-flex justify-content-end">
        <be-button
          variant="primary"
          :icon="sendInvitationEmail ? 'fa-envelope' : ''"
          :loading="loading"
          @click="createMembership"
        >
          {{ $t("buttons.titles.invite") }}
        </be-button>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{
            $t(
              "components.admin_panel.users.invite_to_companies.new_memberships"
            )
          }}
        </h5>
      </div>

      <div class="card-body">
        <be-table-simple v-if="addedUsers.length > 0">
          <thead>
            <tr>
              <th>
                {{ $t("activerecord.attributes.user.name") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.user.email") }}
              </th>

              <th>
                {{ $t("activerecord.models.company.one") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.membership.role") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.membership.function") }}
              </th>

              <th>
                {{ $t("activerecord.attributes.membership.policy_level") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="user in addedUsers">
              <tr
                v-for="membership in addedMembershipForUser(user)"
                :key="`added-membership-${user.id}-${membership.id}`"
              >
                <td>
                  <i
                    v-if="membership.primary_contact"
                    v-be-tooltip="
                      $t('models.membership.tooltips.primary_contact')
                    "
                    class="fas fa-star text-warning mr-2"
                  >
                    <span class="sr-only">
                      {{ $t("models.membership.tooltips.primary_contact") }}
                    </span>
                  </i>
                  {{ user.name }}
                </td>

                <td>
                  {{ user.email }}
                </td>

                <td>
                  <be-link :href="companyUrl(membership.company_id)">
                    {{ companyTitle(membership.company_id) }}
                  </be-link>
                </td>

                <td>
                  {{
                    membership.role
                      ? $t(`models.membership.roles.${membership.role}`)
                      : ""
                  }}
                </td>

                <td>
                  {{ $t(`models.membership.functions.${membership.function}`) }}
                </td>

                <td>
                  {{
                    $t(
                      `models.membership.policy_levels.${membership.policy_level}`
                    )
                  }}
                </td>
              </tr>
            </template>
          </tbody>
        </be-table-simple>

        <be-alert v-else variant="info">
          {{
            $t(
              "components.admin_panel.users.invite_to_companies.no_memberships"
            )
          }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleInput from "@/components/shared/LocaleInput.vue";
import MembershipMixin from "@/mixins/memberships";
import { createNamespacedHelpers } from "vuex";
import formatIdentificationNumber from "@/utils/format-identification-number";

const { mapGetters: mapSynaGetters, mapActions: mapSynaActions } =
  createNamespacedHelpers("syna");

export default {
  components: {
    LocaleInput,
  },

  mixins: [MembershipMixin],

  props: {
    adminPanel: {
      type: Object,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },

    existingMemberships: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      localMembership: this.newMembership(),
      selectedCompanies: [],
      addedMemberships: [],
      sendInvitationEmail: true,
      loading: false,
      selectedSuggestion: null,
    };
  },

  computed: {
    ...mapSynaGetters({ userSuggestions: "getGroupedUserSuggestions" }),

    showIdentificationNumber() {
      return !this.platformEnabled("email_authentication");
    },

    showSuggestions() {
      return this.platformEnabled("syna_suggestions");
    },

    suggestionOptions() {
      return this.userSuggestions
        .map((item) => {
          const companies = this.companies.filter(
            ({ id }) =>
              item.companies.includes(id) &&
              !this.membershipExists(id, item.suggestion)
          );

          return {
            ...item,
            companies,
          };
        })
        .filter(({ companies }) => companies.length > 0)
        .map((item) => {
          const { suggestion, companies } = item;
          const companyNames = companies.map(({ title }) => title).join(", ");
          return {
            value: item,
            searchString: `${suggestion.name} ${companyNames}`,
            title: suggestion.name,
            subtitle: companyNames,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title));
    },

    selectedCompanyIds() {
      return this.selectedCompanies.map((company) => company.id);
    },

    addedUsers() {
      const users = {};
      this.addedMemberships.forEach(({ user }) => {
        users[user.id] = user;
      });

      return Object.values(users);
    },

    memberships() {
      return this.existingMemberships.concat(this.addedMemberships);
    },
  },

  watch: {
    selectedSuggestion(name) {
      if (!name) {
        return;
      }

      const option = this.suggestionOptions.find(
        (option) => option.title === name
      );

      const { companies, suggestion } = option.value;
      const { role, policy, func } = suggestion;
      this.localMembership = {
        ...this.localMembership,
        role,
        policy,
        function: func,

        user: {
          ...this.localMembership.user,
          name: suggestion.name,
        },
      };
      this.selectedCompanies = companies;
    },
  },

  mounted() {
    if (this.platformEnabled("syna_suggestions")) {
      this.companies.forEach(({ id }) => {
        this.loadUserSuggestions(id);
      });
    }
  },

  methods: {
    formatIdentificationNumber,

    ...mapSynaActions(["loadUserSuggestions"]),

    clearSuggestion() {
      this.selectedSuggestion = { value: false };
    },

    newMembership() {
      return {
        policy_level: "limited",
        role: "",
        function: "external",
        primary_contact: false,
        errors: {},

        user: {
          name: "",
          email: "",
          locale: "sv",
        },
      };
    },

    async createMembership() {
      try {
        this.loading = true;
        const response = await axios.post(
          `/admin_panel/${this.adminPanel.id}/companies/users`,
          {
            membership: {
              company_ids: this.selectedCompanies.map((company) => company.id),
              send_email: this.sendInvitationEmail,
              ...this.localMembership,
            },
          }
        );

        this.localMembership = this.newMembership();
        response.data.forEach((membership) => {
          const index = this.addedMemberships.findIndex(
            (addedMembership) => addedMembership.id === membership.id
          );
          if (index > -1) {
            this.addedMemberships[index] = membership;
          } else {
            this.addedMemberships.push(membership);
          }
        });
      } catch (error) {
        if (error.response.status === 422 && error.response.data) {
          this.localMembership.errors = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    onSelectedCompanyChange(ids) {
      this.clearSuggestion();
      this.selectedCompanies = ids
        .map((id) => {
          return this.companies.find((company) => company.id === id);
        })
        .filter((company) => company);
      this.clearErrors(this.localMembership, "organizations");
    },

    addedMembershipForUser(user) {
      return this.addedMemberships.filter(
        (membership) => membership.user.id === user.id
      );
    },

    companyTitle(companyId) {
      const company = this.companies.find(
        (company) => company.id === companyId
      );
      if (company) {
        return company.title;
      } else {
        return companyId;
      }
    },

    membershipExists(companyId, suggestion) {
      return this.memberships.some(
        ({ company_id, user }) =>
          company_id === companyId &&
          (user.name === suggestion.name ||
            user.identification_number === suggestion.identification_number)
      );
    },

    companyUrl(companyId) {
      const company = this.companies.find(
        (company) => company.id === companyId
      );
      if (company) {
        return `/admin_panel/${this.adminPanel.id}/companies/${company.nanoid}`;
      } else {
        return "";
      }
    },
  },
};
</script>
