<template>
  <be-modal
    id="decisions-modal"
    :title="title"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    size="lg"
  >
    <div v-if="decisions.length > 0">
      <div class="accordion">
        <decision-row
          v-for="decision in decisions"
          :key="`decision-${decision.id}`"
          class="mt-2"
          :decision="decision"
        />
      </div>
    </div>

    <be-alert v-else variant="info" class="mb-0">
      {{ $t("components.metrics.decisions.empty") }}
    </be-alert>
  </be-modal>
</template>

<script>
import DecisionRow from "./DecisionRow.vue";

export default {
  components: {
    DecisionRow,
  },

  props: {
    decisions: {
      type: Array,
      required: true,
    },
  },

  computed: {
    title() {
      if (this.decisions.length > 1) {
        return this.$t("components.metrics.decisions.title_w_count", {
          count: this.decisions.length,
        });
      }

      return this.$t("components.metrics.decisions.title");
    },
  },
};
</script>
