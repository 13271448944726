<template>
  <div class="card rounded">
    <div class="card-header rounded">
      <div class="d-flex flex-row w-100">
        <div class="flex-grow-1">
          <div class="d-flex flex-column">
            <div class="row">
              <div v-be-tooltip="task.description" class="col-auto">
                <h5
                  v-dompurify-html="truncateText(task.description, 64)"
                  class="text-break text-pre-line mb-0"
                />
              </div>
            </div>

            <h6 class="text-muted" :class="{ 'mb-0': !task.due_at }">
              {{ company.title }}
            </h6>

            <small v-if="task.due_at">
              {{ $t("activerecord.attributes.task.due_at") }}
              {{ formatDate(task.due_at) }}
            </small>
          </div>
        </div>

        <div class="ml-2 align-self-center">
          <be-button variant="outline-primary" size="sm" :href="taskPath(task)">
            {{ $t("views.home.company.show_task") }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";

export default {
  mixins: [TextUtilities],

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  computed: {
    company() {
      return this.$store.getters["company/getById"](this.task.company_id);
    },
  },

  methods: {
    formatDate(dateString) {
      return this.$d(this.parseDate(dateString), "date");
    },

    taskPath(task) {
      return this.url(`/tasks/${task.id}/link`, { company: this.company });
    },
  },
};
</script>
