<template>
  <div class="row">
    <div class="col-12 col-lg-3">
      <div class="sticky-md-top pt-sticky-top-4">
        <be-form-group
          label-for="report-type"
          :label="$t('activerecord.attributes.report.report_type')"
        >
          <be-form-select
            id="report-type"
            :options="reportTypeOptions"
            :include-blank-option="$t('components.shared.be_table.show_all')"
            @change="filterReports"
          />
        </be-form-group>

        <be-list-group id="report-list" class="mb-3 mb-lg-5">
          <be-list-group-item
            v-for="report in reports"
            :key="report.id"
            class="px-3 border-left-3 align-items-center justify-content-between"
            :class="{
              'border-left-primary': activeReportId === report.id,
            }"
            button
            @click="switchItem(report.id)"
          >
            {{ report.title }}

            <div class="small text-muted">
              {{ subtitle(report) }}
            </div>
          </be-list-group-item>
        </be-list-group>

        <be-pagination
          v-if="pagination && pagination.totalPages > 1"
          class="mt-3"
          :model-value="pagination.currentPage"
          :total-rows="pagination.totalCount"
          :per-page="pagination.pageItems"
          aria-controls="report-list"
          @change="paginationChanged"
        />
      </div>
    </div>

    <div class="col-12 col-lg-8">
      <template v-if="activeReport">
        <h2 class="h3">{{ activeReport.title }}</h2>

        <h3 class="h6 d-flex align-items-center text-muted">
          <template v-if="activeReport.period_end">
            <i class="fal fa-calendar text-black-50 mr-2" />
            {{ activeReport.period_end }}
          </template>

          <span
            v-if="activeReport.period_end && activeReport.confidential"
            class="mx-2 text-black-50"
          >
            -
          </span>

          <template v-if="activeReport.confidential">
            <i class="far fa-shield-keyhole text-black-50 mr-1"></i>
            {{ $t("activerecord.attributes.report.confidential") }}
          </template>
        </h3>

        <be-tabs
          v-model="activeTab"
          :options="reportTabsDataShow(activeReport)"
        >
          <template #content-right>
            <be-dropdown ellipsis>
              <be-dropdown-item :href="activeReport.paths.base">
                {{ $t("views.companies.reports.show.title") }}
              </be-dropdown-item>
            </be-dropdown>
          </template>
        </be-tabs>

        <report-tabs
          v-for="report in showReports"
          v-show="report.id === activeReport.id"
          :key="`report-tabs-${report.id}`"
          :report="report"
          :force-active-tab="activeTab"
          mode="reports-overview"
          controller-action="show"
        />
      </template>

      <div v-else-if="activeReportType" class="card">
        <div class="card-body">
          <be-alert variant="info">
            {{
              $t("components.reports.overview.no_reports_w_type", {
                type: $t(`models.report.types.${activeReportType}`),
              })
            }}
          </be-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ReportMixin from "@/mixins/reports";

import RequestQueryMixin from "@/mixins/RequestQuery";
import ReportTabs from "@/components/reports/ReportTabs.vue";
import { reportTabsDataShow } from "@/utils/reports";

const companyHelpers = createNamespacedHelpers("company");

export default {
  components: {
    ReportTabs,
  },

  mixins: [RequestQueryMixin, ReportMixin],

  data() {
    return {
      activeTab: "report",
      reports: [],
      showReports: [],
      activeReportId: null,
      activeReportType: null,
      pagination: this.parsePagination({}),
    };
  },

  computed: {
    ...companyHelpers.mapGetters({
      hasCorporateGroup: "hasCorporateGroup",
    }),

    activeReport() {
      return this.showReports.find(
        (report) => report.id === this.activeReportId
      );
    },
  },

  created() {
    this.activeReportId = Number(this.getQueryParam("report"));
    this.fetchReports();
  },

  methods: {
    async fetchReports(resetPage) {
      const page = resetPage ? 1 : this.pagination.currentPage;
      const { data, headers } = await axios.get(
        this.url("/reports/published"),
        {
          params: {
            page: page,
            report_type: this.activeReportType,
          },
        }
      );

      this.pagination = this.parsePagination(headers);
      this.reports = data;

      if (this.reports.some((report) => report.id === this.activeReportId)) {
        this.switchItem(this.activeReportId);
      } else if (data.length > 0) {
        this.switchItem(data[0].id);
      } else {
        this.activeReportId = null;
      }
    },

    async fetchReportForShow(reportId) {
      if (!reportId) {
        return;
      }

      const report = this.showReports.find((r) => r.id === reportId);

      if (report) {
        return report;
      }

      const { data } = await axios.get(this.url(`/reports/${reportId}`));

      this.showReports.push(data);
    },

    async switchItem(report_id) {
      this.loading = true;
      await this.fetchReportForShow(report_id);
      this.activeReportId = report_id;

      if (this.activeReport) {
        this.visualbyReportSetFeature(
          "drill-down",
          this.activeReport.allow_drill_down
        );

        this.setFinancials({ drillDown: this.activeReport.allow_drill_down });
      }

      this.setQueryParam("report", this.activeReportId);
    },

    filterReports(value) {
      this.activeReportType = value;
      this.fetchReports(true);
    },

    subtitle(report) {
      const subtitle = [];

      if (report.period_end) {
        subtitle.push(report.period_end);
      }

      if (report.report_type !== "general") {
        subtitle.push(this.$t(`models.report.types.${report.report_type}`));
      }

      return subtitle.join(" • ");
    },

    parsePagination(headers) {
      return {
        currentPage: parseInt(headers["current-page"]) || 1,
        pageItems: parseInt(headers["page-items"]) || 20,
        totalCount: parseInt(headers["total-count"]) || 0,
        totalPages: parseInt(headers["total-pages"]) || 0,
      };
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.fetchReports();
    },

    reportTabsDataShow,
  },
};
</script>
