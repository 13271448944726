<template>
  <signature-table
    :signatures="attendancesAsSignatures"
    :allowed-to-remind="allowedToRemind"
    :initial-loading="initialLoading"
    :loading="loading"
    :compact="compact"
    @remind="sendReminder"
  />
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";

import SignatureTable from "@/components/shared/SignatureTable.vue";

export default {
  components: {
    SignatureTable,
  },

  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    attendances: {
      type: Array,
      required: true,
    },

    initialLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      localAttendances: this.cloneDeep(this.attendances),
      loading: false,
    };
  },

  computed: {
    allowedToRemind() {
      return this.meeting?.minutes?.policy?.update;
    },

    // Transform to more generic signature format to match SignatureTable component
    attendancesAsSignatures() {
      return this.localAttendances.map((attendance) => {
        return {
          id: attendance.id,
          user_id: attendance.user_id,
          name: attendance.name,
          signed_at: attendance.signed_at,
          requested_at: this.meeting.minutes.approved_at,
          rejected_at: attendance.rejected_at,
          rejection_message: null,
          remindable: this.allowedToRemind && attendance.remindable,
          path: attendance.paths.sign,
          sub_name: this.attendanceRole(attendance),

          reminded_at: this.attendanceRemindedAt(
            attendance,
            this.meeting.minutes.approved_at
          ),
        };
      });
    },
  },

  watch: {
    attendances: {
      handler() {
        this.localAttendances = this.cloneDeep(this.attendances);
      },

      deep: true,
    },
  },

  methods: {
    async sendReminder(signature) {
      try {
        this.loading = true;
        const { data } = await axios.patch(
          `${this.meeting.paths.base}/minutes/attendance_reminders/${signature.id}`
        );

        const index = this.localAttendances.findIndex(
          (attendance) => attendance.id === signature.id
        );
        if (index !== -1) {
          this.localAttendances[index] = data;
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    attendanceRemindedAt(attendance, approvedAt) {
      if (!attendance.reminded_signatory_at) {
        return null;
      }

      const remindedAt = new Date(attendance.reminded_signatory_at);
      const createdAt = new Date(approvedAt);

      // If remindedAt is within 30 s och approved_at we skip it
      const thirtySeconds = 30 * 1000;
      if (Math.abs(remindedAt - createdAt) < thirtySeconds) {
        return null;
      }

      return attendance.reminded_signatory_at;
    },
  },
};
</script>
