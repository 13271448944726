import intersectionBy from "lodash/intersectionBy";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("tasks", ["markAsDone", "markAsNotDone", "restore"]),

    canMarkTaskAsDone(task) {
      return (
        task.user_completable &&
        task?.policy?.update &&
        !task.done_at &&
        !task.archived_at
      );
    },

    isTaskRevertable(task) {
      return (
        task?.policy?.update &&
        task.user_completable &&
        task.done_at &&
        !task.archived_at
      );
    },

    isTaskEditable(task) {
      return (
        task?.policy?.edit &&
        intersectionBy(task.policy.permitted_attributes_for_update, [
          "description",
          "due_at",
          "user_id",
          "send_message",
        ]).length > 0
      );
    },

    isTaskDestroyable(task) {
      return task?.policy?.destroy && task.destroyable;
    },

    isTaskRestorable(task) {
      return task?.policy?.edit && !!task.archived_at;
    },

    isTaskArchivable(task) {
      return task?.policy?.edit && !task.archived_at;
    },

    markTaskAsDone(task) {
      task.loading = true;
      this.markAsDone(task);
    },

    markTaskAsNotDone(task) {
      task.loading = true;
      this.markAsNotDone(task);
    },

    restoreTask(task) {
      task.loading = true;
      this.restore(task);
    },

    taskOwnerTitle(task) {
      switch (task.owner_type) {
        case "Meeting":
          return this.$t("activerecord.models.meeting.one").toLowerCase();
        case "EvaluationForm":
          return this.$t(
            "activerecord.models.evaluation_form.one"
          ).toLowerCase();
        case "Decision":
          return this.$t("activerecord.models.decision.one").toLowerCase();
        case "AnnualReport":
          return this.$t("activerecord.models.annual_report.one").toLowerCase();
        case "Report":
          return this.$t("activerecord.models.report.one").toLowerCase();
        default:
          return this.$t("activerecord.models.task.one").toLowerCase();
      }
    },

    taskRequiredFeature(task) {
      switch (task.owner_type) {
        case "AnnualReport":
          return "annual_report";
        case "Meeting":
        case "Decision":
          return "board_portal";
        case "EvaluationForm":
          return "evaluation";
        case "Report":
          return "reports";
        default:
          return false;
      }
    },

    featureRequiredTitle(feature) {
      return this.$t("components.tasks.feature_required", {
        feature: this.$t(`models.feature.features.${feature}.title`),
      });
    },
  },
};
