<template>
  <div class="card-body">
    <label> {{ $t("activerecord.models.task.other") }} </label>

    <be-table
      v-if="taskReferences.length > 0"
      :items="taskReferences"
      :fields="referenceFields"
    >
      <template #description="{ item }">
        <a
          v-dompurify-html="truncateText(item.task.description)"
          :href="url(`tasks/${item.reference_id}`)"
        />
      </template>

      <template #due_at="{ item }">
        <div v-if="item.task.due_at">
          {{ $d(new Date(item.task.due_at), "date") }}
        </div>
      </template>

      <template #done_at="{ item }">
        <div v-if="item.task.done_at">
          {{ $d(new Date(item.task.done_at), "date") }}
        </div>
      </template>

      <template #user_id="{ item }">
        <user-avatar v-if="item.task.user_id" :user="item.task.user_id" />
      </template>

      <template #options="{ item }">
        <be-button
          variant="danger"
          size="sm"
          class="ml-2"
          :loading="loadingIds.includes(item.reference_id)"
          icon="fa-times"
          inline
          @click="removeTaskReference(item)"
        />
      </template>
    </be-table>

    <be-alert v-else variant="info" class="mt-2">
      {{ $t("components.meetings.material.item_tasks.no_tasks") }}
    </be-alert>

    <div class="mt-2">
      <be-button v-be-modal="`add-tasks-${itemId}`" variant="primary">
        {{ $t("components.meetings.material.item_tasks.add_tasks") }}
      </be-button>
    </div>

    <be-modal
      :id="`add-tasks-${itemId}`"
      :title="$t('components.meetings.material.item_tasks.add_tasks')"
      :ok-title="$t('buttons.titles.close')"
      ok-only
      ok-variant="light"
      size="xl"
    >
      <tasks-search
        :references="taskReferences"
        :loading-ids="loadingIds"
        @add-reference="addTaskReference"
        @remove-reference="removeTaskReference"
      />
    </be-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const materialHelpers = createNamespacedHelpers("material");

import TextUtilities from "@/mixins/textUtilities";
import UserAvatar from "@/components/user/UserAvatar.vue";
import TasksSearch from "@/components/shared/TasksSearch.vue";

export default {
  components: {
    UserAvatar,
    TasksSearch,
  },

  mixins: [TextUtilities],

  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      tasks: [],
      loadingIds: [],
      activeTab: "active",
    };
  },

  computed: {
    ...materialHelpers.mapGetters(["followUpReferences", "meeting"]),

    referenceFields() {
      return [
        {
          key: "description",
          label: this.$t("activerecord.models.task.one"),
          sortable: false,
        },
        {
          key: "done_at",
          label: this.$t("activerecord.attributes.task.done_at"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "due_at",
          label: this.$t("activerecord.attributes.task.due_at"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "user_id",
          label: this.$t("activerecord.attributes.task.assigned_to"),
          sortable: false,
          class: "col-shrink text-center",
        },
        {
          key: "options",
          label: "",
          sortable: false,
          class: "col-shrink text-center",
        },
      ];
    },

    taskReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Task"
      );
    },
  },

  methods: {
    ...materialHelpers.mapActions([
      "addFollowUpReference",
      "removeFollowUpReference",
    ]),

    async addTaskReference(taskId) {
      this.loadingIds.push(taskId);
      await this.addFollowUpReference({
        itemId: this.itemId,
        reference_type: "Task",
        reference_id: taskId,
      });
      this.loadingIds = this.loadingIds.filter((id) => id != taskId);
    },

    async removeTaskReference(reference) {
      this.loadingIds.push(reference.reference_id);
      await this.removeFollowUpReference(reference);
      this.loadingIds = this.loadingIds.filter(
        (id) => id != reference.reference_id
      );
    },
  },
};
</script>
