<template>
  <div v-if="!signatureCases.length" class="card mb-4">
    <div class="card-body">
      <be-alert variant="info">
        {{ $t("views.companies.documents.signatures.sign_list.empty") }}
      </be-alert>
    </div>
  </div>

  <div v-else>
    <template v-for="signatureCase in signatureCases">
      <document-signature-case
        v-if="signatureCase.type === 'document'"
        :key="`e-signature-${signatureCase.id}`"
        :document="signatureCase"
      />

      <meeting-signature-case
        v-else-if="signatureCase.type === 'meeting'"
        :key="`e-signature-${signatureCase.id}`"
        :meeting="signatureCase"
      />

      <annual-report-signature-case
        v-else-if="signatureCase.type === 'annual_report'"
        :key="`annual-report-signature-${signatureCase.id}`"
        :document="signatureCase"
      />
    </template>
  </div>
</template>

<script>
import DocumentSignatureCase from "./DocumentSignatureCase.vue";
import AnnualReportSignatureCase from "./AnnualReportSignatureCase.vue";

import MeetingSignatureCase from "@/components/meetings/MeetingSignatureCase.vue";

export default {
  components: {
    AnnualReportSignatureCase,
    DocumentSignatureCase,
    MeetingSignatureCase,
  },

  props: {
    signatureCases: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isDocumentSignature(signatureCase) {
      return (
        signatureCase.type === "document" ||
        (signatureCase.type === "annual_report" &&
          !["annual_report", "audit_report"].includes(signatureCase.key))
      );
    },
  },
};
</script>
