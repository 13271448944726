<template>
  <be-modal
    id="meetings-modal"
    :title="title"
    size="lg"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
  >
    <div v-if="meetings.length" class="accordion">
      <meeting-container
        v-for="meeting in meetings"
        :key="`modal-meeting-${meeting.id}`"
        :meeting="meeting"
        :expanded="currentMeetingId === meeting.id"
        :with-documents="false"
        :with-suggestions="false"
        key-prefix="meetings-modal"
        @toggle-expanded="toggleExpanded(meeting.id)"
      />
    </div>

    <be-alert v-else variant="info" class="mb-0">
      {{ $t("components.metrics.meetings.empty") }}
    </be-alert>
  </be-modal>
</template>

<script>
import { isBefore, startOfYear } from "date-fns";
import MeetingContainer from "@/components/home/MeetingContainer.vue";

export default {
  components: {
    MeetingContainer,
  },

  props: {
    meetings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentMeetingId: null,
    };
  },

  computed: {
    title() {
      if (this.meetings.length > 1) {
        return this.$t("components.metrics.meetings.title_w_count", {
          count: this.meetings.length,
        });
      }

      return this.$t("components.metrics.meetings.title");
    },
  },

  methods: {
    dateFormat(date) {
      return isBefore(new Date(date), startOfYear(new Date()))
        ? "short"
        : "localShort";
    },

    toggleExpanded(meetingId) {
      if (meetingId != this.currentMeetingId) {
        this.currentMeetingId = meetingId;
      } else {
        this.currentMeetingId = null;
      }
    },
  },
};
</script>
