<template>
  <be-tabs v-model="activeTab" :options="tabsData" />

  <div class="card">
    <div class="card-body">
      <be-table :items="currentDashboards" :fields="fields">
        <template #title="{ item }">
          <be-link :href="url(`/financials/dashboards/${item.id}/edit`)">
            {{ item.title }}
          </be-link>
        </template>

        <template #report_type="{ item }">
          {{ $t(`models.report.types.${item.report_type}`) }}
        </template>

        <template #last_updated="{ item }">
          {{ $d(parseDate(item.updated_at)) }}
        </template>

        <template #options="{ item }">
          <be-dropdown size="sm" ellipsis>
            <be-dropdown-item @click="copyDashboard(item)">
              {{ $t("components.reports.report_options.create_copy") }}
            </be-dropdown-item>

            <be-dropdown-item
              v-if="item.owner_type === 'AdminPanel'"
              @click="showPublications(item)"
            >
              {{ clientManagerPublicationsButtonTitle(item) }}
            </be-dropdown-item>

            <be-dropdown-divider />

            <be-dropdown-item variant="danger" @click="removeDashboard(item)">
              {{ $t("buttons.titles.remove") }}
            </be-dropdown-item>
          </be-dropdown>
        </template>
      </be-table>

      <client-manager-report-publish-modal
        v-if="dashboardForPublish"
        :report="dashboardForPublish"
        @report-published="reportPublished"
      />
    </div>
  </div>
</template>
<script>
import RequestHandler from "@/mixins/RequestHandler";
import ClientManagerReportPublishModal from "@/components/reports/ClientManagerReportPublishModal.vue";

export default {
  components: {
    ClientManagerReportPublishModal,
  },

  mixins: [RequestHandler],

  props: {
    dashboards: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "published",
      local_dashboards: this.dashboards,
      dashboardForPublish: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          key: "title",
          label: this.translateAttribute("report", "title"),
          sortable: true,
        },
        {
          key: "report_type",
          label: this.translateAttribute("report", "dashboard_type"),
          sortable: true,
        },
        {
          key: "last_updated",
          label: this.translateAttribute("report", "updated_at"),
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink text-center",
        },
      ];
    },

    tabsData() {
      return [
        {
          value: "published",
          label: this.$t("models.report.published"),
          count: this.localPublished.length,
        },
        {
          value: "drafts",
          label: this.$t("models.report.drafts"),
          count: this.localDrafts.length,
        },
      ];
    },

    localDrafts() {
      return this.local_dashboards.filter(
        (dashboard) => !dashboard.published_at
      );
    },

    localPublished() {
      return this.local_dashboards.filter(
        (dashboard) => dashboard.published_at
      );
    },

    currentDashboards() {
      if (this.activeTab === "drafts") {
        return this.localDrafts;
      } else {
        return this.localPublished;
      }
    },
  },

  methods: {
    clientManagerPublicationsButtonTitle(dashboard) {
      if (dashboard.published_at) {
        return this.$t(
          "components.reports.client_manager_publications.settings"
        );
      } else {
        return this.$t("buttons.titles.publish");
      }
    },

    showPublications(dashboard) {
      this.dashboardForPublish = dashboard;
      this.$nextTick(() => {
        this.$beModal.show("client-manager-report-publish-modal");
      });
    },

    reportPublished(dashboard) {
      this.dashboardForPublish = null;

      const index = this.local_dashboards.findIndex(
        (existing) => existing.id === dashboard.id
      );

      if (index > -1) {
        this.local_dashboards.splice(index, 1, dashboard);
      }
    },

    async removeDashboard(dashboard) {
      try {
        await this.makeRequest(
          {
            method: "delete",

            url: this.url(`/financials/dashboards/${dashboard.id}`),
          },
          {
            confirmRemoval: true,

            confirmMessage: this.$t(
              "views.admin_panels.financials.dashboards.remove_confirm"
            ),
          }
        );

        this.local_dashboards = this.local_dashboards.filter(
          (obj) => obj.id !== dashboard.id
        );
      } catch (error) {
        this.handleError(error);
      }
    },

    async copyDashboard(dashboard) {
      const isConfirmed = await this.promptConfirm(
        this.$t(
          "components.financials.dashboards.primary_dashboard.copy_confirm_w_title",
          {
            title: dashboard.title,
          }
        )
      );

      if (!isConfirmed) {
        return;
      }

      try {
        const { data } = await axios.post(
          this.url(`/financials/dashboards/${dashboard.id}/copy`)
        );

        window.location.assign(
          this.url(`/financials/dashboards/${data.id}/edit`)
        );
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
