<template>
  <div class="upcoming-meetings">
    <template v-for="type in ['current', 'upcoming']">
      <div
        v-if="meetings[type].length > 0"
        :key="type"
        :class="{ 'mb-3': type === 'current' && meetings.upcoming.length > 0 }"
      >
        <div :class="['navbar-header', headerCss]">
          <h6>
            {{
              $tc(
                `components.meetings.upcoming_meetings.${type}`,
                meetings[type].length
              )
            }}
          </h6>
        </div>

        <be-list-group :class="[type, listGroupCss]">
          <be-list-group-item
            v-for="meeting in meetings[type]"
            :key="`meeting-${meeting.id}`"
            :class="`px-2 py-2 border-left border-left-3 border-left-${
              type === 'current' ? 'success' : 'warning'
            }`"
          >
            <div class="mb-1">
              <be-link :href="url(`/meetings/${meeting.id}`)">
                {{ meeting.display_title }}
              </be-link>
            </div>

            <div class="small">
              <i class="fal fa-clock fa-fw" />
              {{ meeting.start_at_human_readable }}
            </div>

            <div v-if="meeting.video_conference_active_now" class="small">
              <i class="fal fa-camera-home fa-fw" />

              <be-link :href="meeting.paths.video_meeting">
                {{ $t("companies.meetings.video_meeting_link_title") }}
              </be-link>
            </div>

            <div v-if="showMeetingOverview(meeting)" class="small">
              <i class="fal fa-clipboard-list-check fa-fw" />

              <be-link
                :href="
                  url(`/meetings/${meeting.id}/overview`, {
                    company: company,
                  })
                "
              >
                {{ $t("views.companies.meetings.overviews.show.title") }}
              </be-link>
            </div>
          </be-list-group-item>
        </be-list-group>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    headerCss: {
      type: String,
      required: false,
      default: "px-4",
    },

    listGroupCss: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters("upcoming_meetings", [
      "currentMeetings",
      "upcomingMeetings",
      "hasLoadedMeetings",
      "loadingMeetings",
    ]),

    meetings() {
      return {
        current: this.currentMeetings || [],
        upcoming: this.upcomingMeetings || [],
      };
    },
  },

  async mounted() {
    if (!this.hasLoadedMeetings && !this.loadingMeetings) {
      await this.fetchMeetings();
    }
  },

  methods: {
    ...mapActions("upcoming_meetings", ["fetchMeetings"]),

    showMeetingOverview(meeting) {
      return meeting.policy?.show_overview && meeting.has_agenda;
    },
  },
};
</script>
