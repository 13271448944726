<template>
  <!-- Adds border-bottom-1 to work with .accordion.card which removes bottom border -->
  <div
    class="card bg-light border-bottom-1"
    :class="{
      'mt-2': index !== 0,
    }"
  >
    <div
      class="card-header bg-light d-flex justify-content-between align-items-center"
    >
      <div>
        <template v-if="isAgenda">
          {{ $t("components.meetings.material.item.decision_suggestion") }}
        </template>

        <template v-else>
          {{ $t("activerecord.models.decision.one") }}
        </template>

        <span v-if="decisionNumber" class="text-muted">
          ({{ localDecision.human_reference_prefix_part
          }}{{ meeting.number }}-{{ decisionNumber }})
        </span>
      </div>

      <div>
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click="handleRemove"
        />
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('description')"
            :label="translateAttribute('decision', 'description')"
            :error="getErrors(decision, 'description')"
            required
          >
            <be-form-textarea
              :id="inputId('description')"
              v-model="localDecision.description"
              :rows="9"
              :max-rows="20"
              :autofocus="localDecision.fresh"
              :state="validationState(localDecision, ['description'])"
              @blur="setAutosave(true)"
              @input="clearErrors(localDecision, 'description')"
            />
          </be-form-group>

          <be-form-checkbox
            v-if="isAgenda"
            :id="`${inputId('include_in_agenda')}-${localDecision.id}`"
            v-model="localDecision.include_in_agenda"
            :state="validationState(localDecision, 'include_in_agenda')"
            @change="clearErrors(localDecision, 'include_in_agenda')"
          >
            {{ translateAttribute("decision", "include_in_agenda") }}
          </be-form-checkbox>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('user_id')"
            :label="
              $t('components.meetings.material.decisions.assign_decision')
            "
            :error="getErrors(decision, 'user_id')"
          >
            <be-form-select
              :id="inputId('user_id')"
              v-model="localDecision.user_id"
              :state="validationState(localDecision, ['user_id'])"
              :options="userOptions"
              @change="clearErrors(localDecision, ['user_id'])"
            />
          </be-form-group>

          <be-form-group
            :label-for="inputId('due_at')"
            :label="translateAttribute('decision', 'due_at')"
            :error="getErrors(localDecision, 'due_at')"
          >
            <datepicker-future-buttons
              :value="localDecision.due_at"
              :state="validationState(localDecision, ['due_at'])"
              display-inline
              @change="clearErrors(localDecision, ['due_at'])"
              @input="setDate"
            />
          </be-form-group>

          <be-form-group
            v-if="platformEnabledAndSubscribed('projects')"
            :label-for="inputId('project_id')"
            :label="translateAttribute('decision', 'project_id')"
            :error="getErrors(localDecision, 'project_id')"
          >
            <be-form-select
              :id="inputId('project_id')"
              v-model="localDecision.project_id"
              :state="validationState(localDecision, ['project_id'])"
              :options="projectOptions"
              @change="clearErrors(localDecision, ['project_id'])"
            />
          </be-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

import { EventBus } from "@/event-bus";

import materialType from "./materialState";
import autosave from "@/mixins/autosave";
import TextUtilities from "@/mixins/textUtilities";

const materialHelpers = createNamespacedHelpers("material");

export default {
  mixins: [materialType, autosave, TextUtilities],

  props: {
    decision: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localDecision: this.cloneDeep(this.decision),
    };
  },

  computed: {
    ...mapGetters({
      users: "company/users",
      projects: "company/projects",
      meeting: "material/meeting",
      decisionNumbers: "material/decisionNumbers",
    }),

    ...materialHelpers.mapGetters({
      meeting: "meeting",
      hasErrors: "hasErrors",
    }),

    projectOptions() {
      return [
        {
          value: null,
          text: this.$t("beta_shared.please_select_an_option"),
        },
        ...this.projects.map((project) => ({
          text: project.display_title,
          value: project.id,
        })),
      ];
    },

    userOptions() {
      return [
        {
          value: null,
          text: this.$t("components.meetings.material.decisions.unassigned"),
        },
        ...this.users.map((user) => {
          return { value: user.id, text: user.name };
        }),
      ];
    },

    invalid() {
      return this.hasErrors(this.localDecision);
    },

    descriptionErrors() {
      const value = this.getErrors(this.localDecision, "description");

      if (Array.isArray(value)) {
        return value;
      } else if (value) {
        return [value];
      } else {
        return [];
      }
    },

    decisionNumber() {
      return this.decisionNumbers[this.localDecision.id];
    },
  },

  watch: {
    decision: {
      handler(newDecision) {
        // Set updateDisabled = true to allow to skip one watch of localDecision
        this.updateDisabled = true;
        this.localDecision = this.cloneDeep(newDecision);
      },

      deep: true,
    },

    localDecision: {
      handler(updatedDecision) {
        if (!this.updateDisabled) {
          this.setDirty(`decision-${updatedDecision.id}`);
          this.tryAutoSave({
            item: this.item,
            decision: updatedDecision,
          });
        }

        this.updateDisabled = false;
      },

      deep: true,
    },
  },

  methods: {
    ...materialHelpers.mapActions(["updateDecision", "removeDecision"]),
    ...materialHelpers.mapMutations(["setDirty"]),

    inputId(field) {
      return `decision-${this.decision.id}-${field}`;
    },

    async handleRemove() {
      let title = "";
      let itemType = this.$t("models.item.decision_suggestion");

      if (this.isMinutes) {
        itemType = this.$t("activerecord.models.decision.one");
      }

      if (this.decision.description) {
        title = this.$i18n.t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType.toLowerCase(),
          title: this.truncateText(this.decision.description, 64),
        });
      } else {
        if (this.isAgenda) {
          title = this.$t(
            "models.material.minutes.confirm_remove_decision_suggestion"
          );
        } else {
          title = this.$i18n.t("nav.confirm_delete_w_item_type", {
            item_type: this.$t(
              "activerecord.models.decision.one"
            ).toLowerCase(),
          });
        }
      }

      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        this.removeDecision({
          decision: this.localDecision,
          item: this.item,
        });
      }
    },

    setDate(date) {
      this.localDecision.due_at =
        date.length > 0 ? new Date(date).toISOString() : "";
    },

    notifyAutosaveFailure() {
      EventBus.emit("new-notification", {
        type: "danger",
        message: this.$t("models.decision.update.failure"),
      });
    },

    saveRequest(data) {
      return this.updateDecision({
        decision: data.decision,
        item: data.item,
      });
    },
  },
};
</script>
