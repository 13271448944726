<template>
  <div class="card mb-2">
    <div class="card-header sticky-top bg-white px-2 px-md-4">
      <div class="row align-items-end">
        <div
          class="col-12 d-block d-md-flex align-items-center justify-content-between"
        >
          <save-status
            class="d-none d-md-inline-block small"
            :loading="saveDisabled"
            :failed-to-save="failedToSave"
            :validation-failed="validationFailed"
            :unsaved-changes="unsavedChanges"
            :last-saved-at="agendaTemplateLastSavedAt"
          />

          <div class="d-flex flex-column flex-md-row gap-1">
            <be-button variant="primary" :disabled="saveDisabled" @click="save">
              {{ $t("buttons.titles.save") }}
            </be-button>

            <be-button
              v-if="items.length > 0"
              variant="outline-secondary"
              @click="toggleAllItems"
            >
              {{ toggleButtonText }}
            </be-button>

            <be-dropdown v-if="agendaTemplate.policy.destroy" ellipsis>
              <be-dropdown-item
                :href="url(`/meetings/agenda_templates/${agendaTemplate.id}`)"
                variant="danger"
                data-method="delete"
                :data-confirm="
                  $t('nav.confirm_delete_w_title', {
                    title: agendaTemplate.title,
                  })
                "
              >
                {{ $t("buttons.titles.remove") }}
              </be-dropdown-item>
            </be-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body p-2 p-md-4">
      <div class="row mb-3">
        <div class="col-12 col-md-7 col-xl-5">
          <be-form-group
            :label="$t('components.meetings.material.editor.template_title')"
            label-for="agenda-template-title"
            :error="getErrors(agendaTemplate, 'title')"
            class="mb-0"
          >
            <be-form-input
              id="agenda-template-title"
              v-model="agendaTemplate.title"
              required
              @change="clearErrors(agendaTemplate, 'title')"
            />
          </be-form-group>
        </div>
      </div>

      <div class="accordion mb-3">
        <drop-list
          v-if="items && items.length > 0"
          :items="items"
          no-animations
          class="list-group list-group-flush"
          @reorder="handleReorderItemsEvent"
        >
          <template #item="data">
            <drag :key="`item-${data.item.id}`" handle=".handle">
              <agenda-template-editor-item
                :key="`item-${data.item.id}`"
                v-model:title="data.item.title"
                v-model:description="data.item.description"
                v-model:notes="data.item.notes"
                v-model:duration-in-seconds="data.item.duration_in_seconds"
                v-model:recess-in-seconds="data.item.recess_in_seconds"
                v-model:subtitle="data.item.subtitle"
                :number="data.item.number"
                :expanded="openItems.includes(data.item.id)"
                :errors="data.item.errors"
                @remove-item="removeItem(data.item.id)"
                @update:expanded="
                  (expanded) =>
                    expanded ? openItem(data.item.id) : closeItem(data.item.id)
                "
              />
            </drag>
          </template>

          <template #feedback></template>
        </drop-list>

        <be-alert v-else variant="info">
          <i18n-t
            keypath="components.meetings.agenda_templates.editor.no_items_w_add_an_item"
            tag="span"
          >
            <template #add_an_item>
              <be-link v-be-modal.add-item>
                {{ $t("components.meetings.material.editor.add_an_item") }}
              </be-link>
            </template>
          </i18n-t>
        </be-alert>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <be-button v-be-modal.add-item variant="light" icon="fa-plus">
          {{ $t("components.meetings.material.editor.add_item") }}
        </be-button>
      </div>
    </div>

    <div class="card-footer px-2 px-md-4">
      <div class="row">
        <div class="col-12 col-md-auto">
          <save-status
            class="d-none d-md-inline-block mt-2"
            :loading="saveDisabled"
            :failed-to-save="failedToSave"
            :validation-failed="validationFailed"
            :unsaved-changes="unsavedChanges"
            :last-saved-at="agendaTemplateLastSavedAt"
          />
        </div>

        <div class="col-12 col-md-auto ml-auto">
          <be-button variant="primary" :disabled="saveDisabled" @click="save">
            {{ $t("buttons.titles.save") }}
          </be-button>
        </div>
      </div>
    </div>
    <!-- Add new Item modal -->
    <be-modal
      id="add-item"
      :title="$t('components.meetings.material.editor.add_item')"
      :ok-title="$t('buttons.titles.add_more')"
      @ok="addNewItem(newItemTitle)"
    >
      <be-form-group
        :label="$t('components.meetings.material.editor.item_title')"
        label-for="title"
      >
        <be-form-input
          id="title"
          v-model="newItemTitle"
          autocomplete="off"
          @keyup.enter="addNewItem(newItemTitle)"
        />
      </be-form-group>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Drag, DropList } from "vue-easy-dnd";
import cloneDeep from "lodash/cloneDeep";
import requests from "./requests";
import map from "lodash/map";
import debounce from "lodash/debounce";

import AgendaTemplateEditorItem from "./AgendaTemplateEditorItem.vue";
import SaveStatus from "@/components/shared/SaveStatus.vue";

const AUTOSAVE_INTERVAL = 2000;

export default {
  components: {
    AgendaTemplateEditorItem,
    Drag,
    DropList,
    SaveStatus,
  },

  props: {
    initialAgendaTemplate: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      openItems: [],
      saveDisabled: false,
      newItemTitle: "",
      agendaTemplateLastSavedAt: null,
      agendaTemplate: cloneDeep(this.initialAgendaTemplate),
      unsavedChanges: false,
      ignoreNextWatch: false,
      failedToSave: false,
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),

    items: {
      get() {
        return this.agendaTemplate.items;
      },

      set(value) {
        this.agendaTemplate.items = value;
      },
    },

    toggleButtonText() {
      const openItemsCount = this.openItems.length;
      if (openItemsCount > 0) {
        return this.$i18n.t("buttons.toggle_all_selection.minimize_all");
      }
      return this.$i18n.t("buttons.toggle_all_selection.expand_all");
    },

    validationFailed() {
      return (
        Object.keys(this.agendaTemplate.errors || {}).length > 0 ||
        this.items.some((item) => Object.keys(item.errors).length > 0)
      );
    },
  },

  watch: {
    agendaTemplate: {
      handler() {
        if (!this.ignoreNextWatch) {
          this.failedToSave = false;
          this.unsavedChanges = true;
          this.debouncedSave();
        }

        this.ignoreNextWatch = false;
      },

      deep: true,
    },
  },

  created() {
    this.fetchPhrases({ company: this.$currentCompany });
  },

  methods: {
    ...mapActions("phrases", ["fetchPhrases"]),

    closeItem(itemId) {
      const index = this.openItems.findIndex(
        (existingId) => existingId == itemId
      );
      if (index > -1) {
        this.openItems.splice(index, 1);
      }
    },

    openItem(itemId) {
      const index = this.openItems.findIndex(
        (existingId) => existingId == itemId
      );
      if (index == -1) {
        this.openItems.push(itemId);
      }
    },

    toggleAllItems() {
      if (this.openItems.length > 0) {
        this.openItems = [];
      } else {
        this.openItems = map(this.items, (item) => item.id);
      }
    },

    async removeItem(itemId) {
      await requests.removeItem({
        company: this.company,
        itemId: itemId,
        agendaTemplateId: this.initialAgendaTemplate.id,
      });

      this.agendaTemplate.items = this.agendaTemplate.items.filter(
        (item) => item.id !== itemId
      );

      this.compactItemNumbers();

      this.agendaTemplateLastSavedAt = new Date();
    },

    compactItemNumbers() {
      this.items.forEach((item, index) => {
        item.number = index + 1;
      });
    },

    async handleReorderItemsEvent(event) {
      const reorderedItemIds = map(this.items, (item) => item.id); // Note: The item ids are not properly ordered yet
      event.apply(reorderedItemIds); // Mutates the itemIds array and orders the itemIds according to the result of the drag and drop

      // itemPositions is an array of objects where each object contains the id of the item and the new position of the item
      const itemPositions = reorderedItemIds.map((id, index) => {
        return {
          id: id,
          number: index + 1,
        };
      });

      try {
        this.saveDisabled = true;
        const { items } = await requests.saveAgendaTemplate({
          company: this.company,
          agendaTemplateId: this.agendaTemplate.id,

          agendaTemplate: {
            items_attributes: itemPositions,
          },
        });

        this.items = items;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.saveDisabled = false;
      }
    },

    async addNewItem(title) {
      try {
        const item = await requests.addNewItem({
          company: this.company,
          title,
          agendaTemplateId: this.initialAgendaTemplate.id,
        });
        this.agendaTemplate.items.push(item);
        this.agendaTemplateLastSavedAt = new Date();
        this.$beModal.hide("add-item");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.newItemTitle = "";
      }
    },

    async save() {
      this.saveDisabled = true;
      try {
        const agendaTemplate = await requests.saveAgendaTemplate({
          company: this.company,
          agendaTemplateId: this.agendaTemplate.id,

          agendaTemplate: {
            title: this.agendaTemplate.title,
            items_attributes: this.items,
          },
        });

        this.agendaTemplate = agendaTemplate;
        this.agendaTemplateLastSavedAt = new Date();
        this.unsavedChanges = false;
      } catch (error) {
        if (error?.response?.status === 422) {
          this.agendaTemplate = error.response.data;
        } else {
          this.handleError(error);
          this.failedToSave = true;
        }
      } finally {
        this.ignoreNextWatch = true;
        this.saveDisabled = false;
      }
    },

    debouncedSave: debounce(function () {
      this.save();
    }, AUTOSAVE_INTERVAL),
  },
};
</script>
